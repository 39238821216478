import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, InputNumber, Modal, notification, Select, Space, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { EquipmentReturnPayload } from 'models/EquipmentReturn';
import { ColorCard } from 'pages/Create/ColorCard';
import { Fragment, useState } from 'react';
import { colors, toRgba } from 'styles/colors';

export const AddPackageButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { setFieldValue, values } = useFormikContext<EquipmentReturnPayload>();
  const [formState, setFormState] = useState({
    packageSize: '',
    packageWeight: undefined,
    numberOfPackages: undefined
  });

  const handleAdd = (): void => {
    if (formState.packageWeight && formState.packageWeight > 150) {
      notification.error({
        message: 'Error',
        description: 'Package Weight must be less than 150 lbs',
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 5
      });

      return;
    }
    setFieldValue('packages', [
      ...values.packages,
      {
        key: values.packages.length ?? 0 + 1,
        packageSize: formState.packageSize,
        packageWeight: formState.packageWeight,
        numberOfPackages: formState.numberOfPackages
      }
    ]);
    setFormState({
      packageSize: '',
      packageWeight: undefined,
      numberOfPackages: undefined
    });

    setIsOpen(false);
  };

  return (
    <Fragment>
      <Button icon={<PlusCircleOutlined />} onClick={(): void => setIsOpen(true)}>
        Add Package
      </Button>
      <Modal
        okButtonProps={{ disabled: Object.values(formState).some((val) => !val) }}
        closable={false}
        onOk={handleAdd}
        open={isOpen}
        onCancel={(): void => {
          setFormState({
            packageSize: '',
            packageWeight: undefined,
            numberOfPackages: undefined
          });
          setIsOpen(false);
        }}>
        <ColorCard title="Add Package" color={toRgba(colors.illuminatingEmerald, 0.6)}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Text>Package Size</Typography.Text>
              <Select
                value={formState.packageSize}
                onChange={(e): void =>
                  setFormState({
                    ...formState,
                    packageSize: e
                  })
                }
                style={{ width: '100%' }}
                options={['XL', 'L', 'M', 'S'].map((opt) => ({ label: opt, value: opt }))}
              />
            </Space>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Text>Package Weight (lbs)</Typography.Text>
              <InputNumber
                value={formState.packageWeight}
                onKeyDown={(e): void => {
                  if (!e.code.includes('Digit') && !e.code.includes('Backspace') && !e.code.includes('Period')) e.preventDefault();
                }}
                onChange={(e): void => {
                  if (!e) {
                    setFormState({
                      ...formState,
                      packageWeight: undefined
                    });

                    return;
                  }

                  setFormState({
                    ...formState,
                    packageWeight: e as any
                  });
                }}
                controls={false}
                style={{ width: '100%' }}
              />
            </Space>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Text>Number of Packages</Typography.Text>
              <InputNumber
                value={formState.numberOfPackages}
                onKeyDown={(e): void => {
                  if (!e.code.includes('Digit') && !e.code.includes('Backspace')) e.preventDefault();
                }}
                onChange={(e): void => {
                  setFormState({
                    ...formState,
                    numberOfPackages: e as any
                  });
                }}
                controls={false}
                style={{ width: '100%' }}
              />
            </Space>
          </Space>
        </ColorCard>
      </Modal>
    </Fragment>
  );
};

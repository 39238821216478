import { Menu, Space } from 'antd';
import { LottieIcon } from 'components/common/LottieIcon';
import { useFormikContext } from 'formik';
import { EquipmentReturnPayload } from 'models/EquipmentReturn';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetEquipmentReturnConfigurationTypesQuery } from 'redux/services/maurice/sparingRequestConfigTypes';
import { setProductsView } from 'redux/slices/formSlice';
import { useAppSelector } from 'redux/store';
import { customerLottie, deliveryLottie, headerInfoLottie, otherLottie, outageLottie, reviewOrderLottie } from '../../../assets/customer-lottie';

export enum CreateRequestMenu {
  RequestDetails = 'request-details',
  PickupLocation = 'pickup-location',
  Products = 'products',
  ShippingLocation = 'shipping-location',
  RequestInfo = 'request-info',
  Contact = 'contact-info',
  ReviewOrder = 'review-order',
  ServiceLevel = 'service-level',
  PackageInformation = 'package-information'
}

export const StepControls = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { values } = useFormikContext<EquipmentReturnPayload>();

  const { pathname } = useLocation();
  const { id: deploymentRequestId } = useParams();
  const { acuityContext, isInternalUser, selectedDivisionId } = useAppSelector((state) => state.app);

  const isCreate = pathname.includes('create');

  const divisionId = isInternalUser ? selectedDivisionId : acuityContext?.selectedCustomer.id;

  const isInternalCreate = isInternalUser && isCreate;

  const divId = isInternalCreate ? values.divisionId : divisionId;

  const { data } = useGetEquipmentReturnConfigurationTypesQuery({ divisionId: divId as string, params: {} }, { skip: !divId });
  const equipmentReturnType = data?.data.find((type) => values.equipmentReturnTypeId === type.id);

  const isGeneralComplete = Boolean(
    values.equipmentReturnTypeId && values.equipmentReturnName && (values.equipmentReturnTypeName !== 'ReturnShipment' ? values.requestedPickupDate && values.requestedPickupTime : true)
  );

  const isReturnShipment = equipmentReturnType?.equipmentReturnRuleTypeName === 'ReturnShipment' ?? false;

  const isNextStep = isReturnShipment
    ? Boolean(values.equipmentReturnTypeId && values.referenceNumber)
    : Boolean(values.equipmentReturnTypeId && values.referenceNumber && values.requestedPickupDate && values.requestedPickupTime);

  const isPickupLocationId = values.pickupAddress?.id;
  const validContactInfoStep = isReturnShipment
    ? values.shipperBusinessName !== '' && values.shipperContactName !== '' && values.shipperContactPhone !== ''
    : values.siteContactEmail !== '' && values.siteContactName !== '' && values.siteContactPhone !== '';

  // console.log(values.requestedPickupDateTime, 'lol');

  const currentLocation = useMemo(() => {
    if (pathname.includes(CreateRequestMenu.PickupLocation)) {
      return CreateRequestMenu.PickupLocation;
    } else if (pathname.includes(CreateRequestMenu.Products)) {
      return CreateRequestMenu.Products;
    } else if (pathname.includes(CreateRequestMenu.ShippingLocation)) {
      return CreateRequestMenu.ShippingLocation;
    } else if (pathname.includes(CreateRequestMenu.RequestInfo)) {
      return CreateRequestMenu.RequestInfo;
    } else if (pathname.includes(CreateRequestMenu.ServiceLevel)) {
      return CreateRequestMenu.ServiceLevel;
    } else if (pathname.includes(CreateRequestMenu.PackageInformation)) {
      return CreateRequestMenu.PackageInformation;
    } else if (pathname.includes(CreateRequestMenu.Contact)) {
      return CreateRequestMenu.Contact;
    } else if (pathname.includes(CreateRequestMenu.ReviewOrder)) {
      return CreateRequestMenu.ReviewOrder;
    } else {
      return CreateRequestMenu.RequestDetails;
    }
  }, [pathname]);

  const handleNavigationCreate = (info: { key: string }): void => {
    if (info.key === CreateRequestMenu.RequestDetails) {
      navigate('/create');
    } else if (info.key === CreateRequestMenu.PickupLocation) {
      navigate('/create/pickup-location');
    } else if (info.key === CreateRequestMenu.Products) {
      dispatch(setProductsView('grid'));
      navigate('/create/products');
    } else if (info.key === CreateRequestMenu.ShippingLocation) {
      navigate('/create/shipping-location');
    } else if (info.key === CreateRequestMenu.RequestInfo) {
      navigate('/create/request-info');
    } else if (info.key === CreateRequestMenu.ServiceLevel) {
      navigate('/create/service-level');
    } else if (info.key === CreateRequestMenu.PackageInformation) {
      navigate('/create/package-information');
    } else if (info.key === CreateRequestMenu.Contact) {
      navigate('/create/contact-info');
    } else if (info.key === CreateRequestMenu.ReviewOrder) {
      navigate('/create/review-order');
    }
  };
  const handleNavigationEdit = (info: { key: string }): void => {
    if (info.key === CreateRequestMenu.RequestDetails) {
      navigate(`/edit/${deploymentRequestId}`);
    } else if (info.key === CreateRequestMenu.PickupLocation) {
      navigate(`/edit/${deploymentRequestId}/pickup-location`);
    } else if (info.key === CreateRequestMenu.Products) {
      dispatch(setProductsView('grid'));
      navigate(`/edit/${deploymentRequestId}/products`);
    } else if (info.key === CreateRequestMenu.ShippingLocation) {
      navigate(`/edit/${deploymentRequestId}/shipping-location`);
    } else if (info.key === CreateRequestMenu.PackageInformation) {
      navigate(`/edit/${deploymentRequestId}/package-information`);
    } else if (info.key === CreateRequestMenu.ServiceLevel) {
      navigate(`/edit/${deploymentRequestId}/service-level`);
    } else if (info.key === CreateRequestMenu.RequestInfo) {
      navigate(`/edit/${deploymentRequestId}/request-info`);
    } else if (info.key === CreateRequestMenu.Contact) {
      navigate(`/edit/${deploymentRequestId}/contact-info`);
    } else if (info.key === CreateRequestMenu.ReviewOrder) {
      navigate(`/edit/${deploymentRequestId}/review-order`);
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Menu onClick={deploymentRequestId ? handleNavigationEdit : handleNavigationCreate} mode="horizontal" style={{ justifyContent: 'center' }} selectedKeys={[currentLocation]}>
        <Menu.Item style={{ paddingLeft: 10, paddingRight: 10 }} key={CreateRequestMenu.RequestDetails}>
          <LottieIcon required complete={isGeneralComplete} lottieFile={headerInfoLottie} title="Reference Identification" />
        </Menu.Item>
        <Menu.Item disabled={!isNextStep} style={{ paddingLeft: 10, paddingRight: 10 }} key={CreateRequestMenu.PickupLocation}>
          <LottieIcon title="Pickup Address" lottieFile={outageLottie} complete={Boolean(isPickupLocationId)} />
        </Menu.Item>
        {isReturnShipment && (
          <Menu.Item disabled={!isNextStep} style={{ paddingLeft: 10, paddingRight: 10 }} key={CreateRequestMenu.PackageInformation}>
            <LottieIcon title="Package Information" lottieFile={deliveryLottie} complete={!!values.packages.length} />
          </Menu.Item>
        )}
        <Menu.Item disabled={!isNextStep} style={{ paddingLeft: 10, paddingRight: 10 }} key={CreateRequestMenu.Contact}>
          <LottieIcon required title="Logistics & Contacts" lottieFile={customerLottie} complete={Boolean(validContactInfoStep)} />
        </Menu.Item>
        <Menu.Item disabled={!isNextStep} style={{ paddingLeft: 10, paddingRight: 10 }} key={CreateRequestMenu.RequestInfo}>
          <LottieIcon title="Other" complete={false} lottieFile={otherLottie} />
        </Menu.Item>
        <Menu.Item disabled={!isNextStep} style={{ paddingLeft: 10, paddingRight: 10 }} key={CreateRequestMenu.ReviewOrder}>
          <LottieIcon complete={false} title="Review Order" lottieFile={reviewOrderLottie} />
        </Menu.Item>
      </Menu>
    </Space>
  );
};

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, message, notification, Row, Space, Spin, Typography } from 'antd';
import { MenuProps } from 'antd/lib';
import { GenerateWaybillButton } from 'components/atoms/GenerateWaybillButton';
import { ResendNotificationsButton } from 'components/atoms/ResendNotificationsButton';
import { StatusHistoryButton } from 'components/atoms/StatusHistoryButton';
import { Loader } from 'components/common/Loader';
import WimsicalError from 'components/common/WimsicalError/WimsicalError';
import { EquipmentReturnMetaInfoOpen } from 'components/molecules/EquipmentReturnMetaInfoOpen';
import { DispatcherDashboard } from 'components/organisms/DispatcherDashboard';
import { FormikProvider, useFormik } from 'formik';
import { formatErrorMessage } from 'helpers/formatErrorMessage';
import { equipmentReturnPayloadSchema, OpenEquipmentReturn, OpenEquipmentReturnPayload } from 'models/EquipmentReturn';
import { QueryErrorModel } from 'models/ErrorModel';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGenerateWaybillMutation, useGetEquipmentReturnQuery, useUpdateEquipmentReturnDispatcherMutation } from 'redux/services/barkley/barkleyApi';
import { setIsWaybill } from 'redux/slices/appSlice';
import { setIsExit } from 'redux/slices/formSlice';
import { useAppSelector } from 'redux/store';

export const DispatcherPage = (): JSX.Element => {
  const { isExit } = useAppSelector((state) => state.form);
  const { selectedDivisionId, isWaybill } = useAppSelector((state) => state.app);
  const [loadingMessage, setLoadingMessage] = useState<string>('');

  const { id } = useParams();
  const { data, isLoading, isFetching, isError, error } = useGetEquipmentReturnQuery(
    { divisionId: selectedDivisionId as string, equipmentReturnId: id as string },
    { skip: !id || !selectedDivisionId }
  );

  const dispatcherData = data as OpenEquipmentReturn;
  const [updateReturn, { isLoading: isUpdating }] = useUpdateEquipmentReturnDispatcherMutation({ fixedCacheKey: 'waybill' });

  const [createWaybill, { isLoading: isWaybillLoading }] = useGenerateWaybillMutation();
  const dispatch = useDispatch();

  const nav = useNavigate();
  const formik = useFormik<OpenEquipmentReturnPayload>({
    validationSchema: equipmentReturnPayloadSchema,
    initialValues: {
      referenceNumber: dispatcherData?.referenceNumber ?? '',
      dockDoors: dispatcherData?.dockDoors ?? false,
      emailNotificationList: dispatcherData?.emailNotificationList ?? [],
      equipmentReturnName: dispatcherData?.equipmentReturnName ?? '',
      freightElevator: dispatcherData?.freightElevator ?? false,
      packages: dispatcherData?.packages.map((item, index) => ({ ...item, key: index })) ?? [],
      equipmentReturnTypeName: dispatcherData?.equipmentReturnType.name.replace(/\s+/g, '') ?? '',
      isEquipmentPackaged: dispatcherData?.isEquipmentPackaged ?? false,
      isEquipmentValueOver100K: dispatcherData?.isEquipmentValueOver100K ?? false,
      notes: dispatcherData?.notes ?? '',
      pickupAddress: dispatcherData?.pickupAddress ?? undefined,
      requestedPickupDate: dispatcherData?.requestedPickupDate ?? '',
      requestedPickupTime: dispatcherData?.requestedPickupTime ?? '',
      requestorName: dispatcherData?.requestorName ?? '',
      requestorPhone: dispatcherData?.requestorPhone ?? '',
      requestorEmail: dispatcherData?.requestorEmail ?? '',
      siteContactEmail: dispatcherData?.siteContactEmail ?? '',
      siteContactName: dispatcherData?.siteContactName ?? '',
      siteContactPhone: dispatcherData?.siteContactPhone ?? '',
      siteSpecificInstructions: dispatcherData?.siteSpecificInstructions ?? '',
      waybillNumber: dispatcherData?.waybillNumber ?? '',
      isParkingPassRequiredToParkCarrierTruck: dispatcherData?.isParkingPassRequiredToParkCarrierTruck ?? false,
      isThereAPalletJacketOnsite: dispatcherData?.isThereAPalletJacketOnsite ?? false,
      isThereAForkliftThere: dispatcherData?.isThereAForkliftThere ?? false,
      isFloorESDSafe: dispatcherData?.isFloorESDSafe ?? false,
      isThereALoadingDock: dispatcherData?.isThereALoadingDock ?? false,
      canCabinetFitInElevator: dispatcherData?.canCabinetFitInElevator ?? false,
      isAStaircaseNeededToTransportEquipment: dispatcherData?.isAStaircaseNeededToTransportEquipment ?? false,
      isDisassemblyRequired: dispatcherData?.isDisassemblyRequired ?? false,
      arePalletsNeededPriorToShipping: dispatcherData?.arePalletsNeededPriorToShipping ?? false,
      isLiftGateRequired: dispatcherData?.isLiftGateRequired ?? false,
      isFreightElevatorPermitRequired: dispatcherData?.isFreightElevatorPermitRequired ?? false,
      isSpecialPermitToAccessLoadingDockRequired: dispatcherData?.isSpecialPermitToAccessLoadingDockRequired ?? false,
      isThereAFreightElevator: dispatcherData?.isThereAFreightElevator ?? false,
      equipmentReturnTypeId: dispatcherData?.equipmentReturnType.id ?? '',
      qtyOfEquipment: dispatcherData?.qtyOfEquipment ?? 0,
      numberOfPallets: dispatcherData?.numberOfPallets ?? 0,
      numberOfBoxes: dispatcherData?.numberOfBoxes ?? 0,
      totalWeight: dispatcherData?.totalWeight ?? 0,
      returnStatus: dispatcherData?.returnStatus ?? '',
      disposalOrderNumber: dispatcherData?.disposalOrderNumber ?? '',
      freightCompany: dispatcherData?.freightCompany ?? '',
      mdSiProjectNumber: dispatcherData?.mdSiProjectNumber ?? '',
      scheduledPickupDate: dispatcherData?.scheduledPickupDate ?? '',
      scheduledPickupTime: dispatcherData?.scheduledPickupTime ?? '',
      shipToWarehouse: dispatcherData?.warehouseRoutingFulfillment?.warehouseId ?? '',
      fulfillmentStatus: dispatcherData?.fulfillmentStatus ?? '',
      shipperBusinessName: dispatcherData?.shipperBusinessName || 'TEST',
      shipperContactName: dispatcherData?.shipperContactName || 'John Smith',
      shipperContactPhone: dispatcherData?.shipperContactPhone || '5555555555',
      warehouseContactName: dispatcherData?.warehouseContactName || 'Warehouse Contact Name',
      warehouseContactPhone: dispatcherData?.warehouseContactPhone || '1234567',
      vendorQuotes: dispatcherData?.vendorQuotes ?? [],
      notificationNotes: dispatcherData?.notificationNotes ?? '',
      internalNotes: dispatcherData?.internalNotes ?? '',
      requestSource: dispatcherData?.requestSource ?? '',
      requestPriority: dispatcherData?.requestPriority ?? 'Medium',
      invoiceNumber: dispatcherData?.invoiceNumber ?? '',
      isInvoiced: dispatcherData?.isInvoiced ?? false
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!id) return message.error('An error occurred, the team has been notified.');
      const digits = values.shipperContactPhone?.replace(/\D/g, '');

      if (values.equipmentReturnTypeName === 'ReturnShipment' && digits?.length !== 10) {
        notification.error({
          message: 'Error',
          description: 'Shipper Contact Phone must be 10 digits.',
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 10
        });

        return;
      }

      try {
        if (isWaybill) {
          await updateReturn({ divisionId: selectedDivisionId, equipmentReturnId: id, body: values }).unwrap();
          await createWaybill({ divisionId: selectedDivisionId, equipmentReturnId: id, payload: {} }).unwrap();
          dispatch(setIsWaybill(false));

          notification.success({
            message: 'Success',
            description: `Waybills created successfully.`,
            className: 'custom-class',
            style: {
              width: 600
            },
            duration: 10
          });

          return;
        }

        const response = await updateReturn({ divisionId: selectedDivisionId, equipmentReturnId: id, body: values }).unwrap();

        notification.success({
          message: 'Success',
          description: `Equipment return: ${response.equipmentReturnName} updated successfully.`,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 10
        });
        if (isExit) {
          nav('/');
          dispatch(setIsExit(false));
        }
      } catch (e) {
        console.error(e, 'error');

        const error = e as QueryErrorModel;

        dispatch(setIsExit(false));
        dispatch(setIsWaybill(false));
        const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occurred, the team has been notified.';

        notification.error({
          message: 'Error',
          description: errorMessage,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 10
        });
      }
    }
  });

  const isCancelled = data?.returnStatus === 'Cancelled';

  const handleCancel = async (): Promise<void> => {
    setLoadingMessage('Cancelling equipment return...');
    const payload: OpenEquipmentReturnPayload = {
      ...(dispatcherData as OpenEquipmentReturnPayload),
      returnStatus: 'Cancelled',
      equipmentReturnTypeId: data?.equipmentReturnType.id ?? ''
    };

    try {
      if (!id) {
        message.error('An error occurred, the team has been notified.');

        return;
      }
      const response = await updateReturn({ divisionId: selectedDivisionId, equipmentReturnId: id, body: payload }).unwrap();

      notification.success({
        message: 'Success',
        description: `Equipment return: ${response.referenceNumber} has been cancelled successfully.`,
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 10
      });
      nav('/');
    } catch (e) {
      console.log(e);
      console.error(e, 'error');

      const error = e as QueryErrorModel;
      const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occurred, the team has been notified.';

      notification.error({
        message: 'Error',
        description: errorMessage,
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 5
      });
    }
  };
  const handleReopen = async (): Promise<void> => {
    setLoadingMessage('Reopening equipment return...');
    const payload: OpenEquipmentReturnPayload = {
      ...(dispatcherData as OpenEquipmentReturnPayload),
      returnStatus: 'Open',
      equipmentReturnTypeId: data?.equipmentReturnType.id ?? ''
    };

    try {
      if (!id) {
        message.error('An error occurred, the team has been notified.');

        return;
      }
      const response = await updateReturn({ divisionId: selectedDivisionId, equipmentReturnId: id, body: payload }).unwrap();

      notification.success({
        message: 'Success',
        description: `Equipment return: ${response.referenceNumber} has been reopened successfully.`,
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 10
      });
    } catch (e) {
      console.log(e);
      console.error(e, 'error');

      const error = e as QueryErrorModel;
      const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occurred, the team has been notified.';

      notification.error({
        message: 'Error',
        description: errorMessage,
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 5
      });
    }
  };

  const handleSaveAndExit = (): void => {
    dispatch(setIsExit(true));
    formik.submitForm();
  };

  const items: MenuProps['items'] = [
    {
      label: 'Save',
      key: '1',
      onClick: formik.submitForm
    }
  ];

  if (isLoading) return <Loader loadingMessage="Loading equipment return..." />;

  if (isError) {
    const err = error as QueryErrorModel;

    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row justify={'space-between'} style={{ width: '100%' }}>
          <Col>
            <Typography.Title level={4} style={{ margin: 0, padding: 0 }}>
              Equipment Return Information
            </Typography.Title>
          </Col>
          <Col>
            <Space>
              <Button onClick={(): void => nav('/')} icon={<ArrowLeftOutlined />}>
                Back to Search
              </Button>
            </Space>
          </Col>
        </Row>
        <WimsicalError title={formatErrorMessage(typeof err.status === 'number' ? err.status : 400)} subTitle={err?.data?.errorMessage ? err.data.errorMessage : ''} statusCode={err.status} />
      </Space>
    );
  }

  return (
    <Spin
      indicator={<Loader loadingMessage={isWaybillLoading ? 'Creating Waybills...' : formik.isSubmitting ? 'Updating equipment return...' : loadingMessage} />}
      spinning={formik.isSubmitting || isUpdating || isWaybillLoading}>
      <FormikProvider value={formik}>
        <Row style={{ width: '100%' }}>
          <Space direction="vertical" style={{ width: '100%' }} size={10}>
            <Row justify={'space-between'} style={{ width: '100%' }}>
              <Col>
                <Typography.Title level={4} style={{ margin: 0, padding: 0 }}>
                  Equipment Return Information
                </Typography.Title>
              </Col>
              <Col>
                <Space>
                  <Button onClick={(): void => nav('/')} icon={<ArrowLeftOutlined />}>
                    Back to Search
                  </Button>
                  {!isCancelled && (
                    <Button onClick={handleCancel} danger>
                      Cancel
                    </Button>
                  )}
                  <StatusHistoryButton statusHistory={data?.fulfillmentStatusHistory ?? []} />
                  {formik.values.equipmentReturnTypeName === 'ReturnShipment' && <GenerateWaybillButton />}
                  <ResendNotificationsButton />
                  {!isCancelled && (
                    <Dropdown.Button type="primary" onClick={handleSaveAndExit} loading={formik.isSubmitting} menu={{ items }}>
                      Save &amp; Close
                    </Dropdown.Button>
                  )}
                  {isCancelled && (
                    <Button onClick={handleReopen} type="primary">
                      Reopen Order
                    </Button>
                  )}
                </Space>
              </Col>
            </Row>
            <EquipmentReturnMetaInfoOpen />
            <DispatcherDashboard />
          </Space>
        </Row>
      </FormikProvider>
    </Spin>
  );
};

import { Button, Col, Form, Input, notification, Row } from 'antd';
import { useFormik, useFormikContext } from 'formik';
import { rest } from 'lodash';
import { EquipmentReturnPayload } from 'models/EquipmentReturn';
import { SparingRequestPayload } from 'models/SparingRequests';
import * as yup from 'yup';
import { FormLabel } from '../FormItems/FormLabel';

const subscribeSchema = yup.object({
  email: yup.string().label('Subscriber Email').email()
});

export const SubscribeInput = (): JSX.Element => {
  const { setFieldValue, values: contextValues } = useFormikContext<EquipmentReturnPayload>();

  const { touched, values, handleBlur, handleChange, errors, handleSubmit, setFieldTouched } = useFormik({
    validationSchema: subscribeSchema,
    initialValues: {
      email: ''
    },
    onSubmit: async ({ email }, { resetForm }) => {
      if (!email) return;

      if (contextValues.emailNotificationList.includes(email))
        return notification.error({
          message: 'Error',
          description: 'Email already exists in the list',
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 5
        });
      setFieldValue('emailNotificationList' as keyof SparingRequestPayload, [...contextValues.emailNotificationList, email]);
      resetForm();
    }
  });

  const handleEnterPressed = (): void => {
    if (!errors.email) {
      handleSubmit();
    }
  };

  return (
    <Form.Item validateStatus={touched.email ? 'error' : 'success'} help={touched.email ? errors.email : undefined} style={{ marginBottom: 0 }}>
      <Row>
        <Col span={24}>
          <FormLabel label="Subscriber Email" />
        </Col>
        <Input.Group style={{ display: 'flex' }} compact>
          <Input
            {...rest}
            onPressEnter={handleEnterPressed}
            value={values.email || undefined}
            onFocus={() => setFieldTouched('email', false)}
            onChange={handleChange('email')}
            onBlur={() => setFieldTouched('email', true)}
            style={{ flex: 1 }}
          />
          <Button type="primary" disabled={Boolean(errors.email || !values.email)} onClick={(): void => handleSubmit()}>
            Add
          </Button>
        </Input.Group>
      </Row>
    </Form.Item>
  );
};

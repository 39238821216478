import { SelectProps, Spin } from 'antd';
import { Loader } from 'components/common/Loader';
import { FieldInputSelect } from 'components/Create/FormItems/FieldInputSelect';
import { FC } from 'react';
import { useGetWarehousesQuery } from 'redux/services/kingstonLivingston/kingstonLivingstonApi';

type Props = SelectProps & {
  isReturnShipment: boolean;
};

export const WarehouseSelect: FC<Props> = ({ isReturnShipment, ...rest }): JSX.Element => {
  const { data, isLoading } = useGetWarehousesQuery({ offset: 0, dataAreaId: 'mdsi', overrideSkipTake: true });

  const options = data?.data.map((warehouse) => ({ label: warehouse.warehouseId, value: warehouse.warehouseId })) ?? [];

  return (
    <Spin spinning={!isReturnShipment && isLoading} indicator={<Loader small />}>
      <FieldInputSelect disabled={isReturnShipment} label="Ship To Warehouse" fieldName="shipToWarehouse" options={options} {...rest} />
    </Spin>
  );
};

import { Col, Row, Space } from 'antd';
import { CityFilter } from 'components/atoms/Filters/CityFilter';
import { CustomerFilter } from 'components/atoms/Filters/CustomerFilter';
import { ReferenceIdFilter } from 'components/atoms/Filters/ReferenceIdFilter';
import { ReferenceNumberFilter } from 'components/atoms/Filters/ReferenceNumberFilter';
import { RequestorNameFilter } from 'components/atoms/Filters/RequestorNameFilter';
import { CountryStateInputs } from 'components/ui/Filters/CountryStateFilter';
import { useAppSelector } from 'redux/store';
import { StatusFilter } from './components';
import { EquipmentReturnTypeFilter } from './components/EquipmentReturnTypeFilter';
import { FancyDateRangePicker } from './components/FancyDateRangePicker';

export const ControlFilters = (): JSX.Element => {
  const { isInternalUser } = useAppSelector((state) => state.app);

  return (
    <Space style={{ width: '100%' }} size={18} direction="vertical">
      <Row gutter={[16, 16]}>
        {isInternalUser && (
          <Col xs={24} sm={24} md={8} lg={4} xl={4} xxl={4}>
            <CustomerFilter />
          </Col>
        )}
        <Col xs={24} sm={24} md={8} lg={4} xl={4} xxl={4}>
          <ReferenceIdFilter />
        </Col>
        <Col xs={24} sm={24} md={8} lg={4} xl={4} xxl={4}>
          <ReferenceNumberFilter />
        </Col>
        <Col xs={24} sm={24} md={8} lg={4} xl={4} xxl={4}>
          <RequestorNameFilter />
        </Col>
        <Col xs={24} sm={24} md={8} lg={4} xl={4} xxl={4}>
          <FancyDateRangePicker />
        </Col>
        <Col xs={24} sm={24} md={8} lg={4} xl={4} xxl={4}>
          <StatusFilter />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={8} lg={4} xl={4} xxl={4}>
          <EquipmentReturnTypeFilter />
        </Col>
        <CountryStateInputs />
        <Col xs={24} sm={24} md={8} lg={4} xl={4} xxl={4}>
          <CityFilter />
        </Col>
      </Row>
    </Space>
  );
};

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const googleMapsApi = createApi({
  reducerPath: 'googleMapsApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      headers.set('Accept', 'application/json');
      headers.set('Access-Control-Allow-Origin', '*');

      return headers;
    }
  }),
  tagTypes: ['Application', 'Applications'],
  endpoints: (builder) => ({
    getLocalitiesByCountryQuery: builder.query<any, string>({
      async queryFn(arg) {
        const resp = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${arg}&key=AIzaSyBOKUNY3VI9yXSpL0LvGtNf5ym8K1C0uwY`);

        if (!resp.ok) {
          throw new Error(`HTTP error! status: ${resp.status}`);
        }
        const { results } = await resp.json();

        return { data: results };
      }
    })
  })
});

export const { useGetLocalitiesByCountryQueryQuery } = googleMapsApi;

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, message, notification, Row, Space, Spin, Typography } from 'antd';
import { Loader } from 'components/common/Loader';
import WimsicalError from 'components/common/WimsicalError/WimsicalError';
import { ActionControls, StepControls } from 'components/Create/common';
import { EquipmentReturnMetaInfo } from 'components/Create/common/EquipmentReturnMetaInfo';
import { FormikProvider, useFormik } from 'formik';
import { formatErrorMessage } from 'helpers/formatErrorMessage';
import { EquipmentReturnPayload, equipmentReturnPayloadSchema } from 'models/EquipmentReturn';
import { QueryErrorModel } from 'models/ErrorModel';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useGetEquipmentReturnQuery, useUpdateEquipmentReturnMutation } from 'redux/services/barkley/barkleyApi';
import { resetAttachments } from 'redux/slices/attachmentsSlice';
import { setIsExit, setIsPost } from 'redux/slices/formSlice';
import { useAppSelector } from 'redux/store';

export const EditDeploymentRequestLayout = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { selectedDivisionId, isInternalUser } = useAppSelector((state) => state.app);
  const { id } = useParams();
  const { data, isLoading, isFetching, isError, error } = useGetEquipmentReturnQuery(
    { divisionId: selectedDivisionId as string, equipmentReturnId: id as string },
    { skip: !id || !selectedDivisionId }
  );
  const nav = useNavigate();
  const { postRequestLoadingMessage } = useAppSelector((state) => state.app);
  const { isExit, isPost } = useAppSelector((state) => state.form);
  const dispatch = useDispatch();
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined);

  const [editReturn, { data: updateResponse, isSuccess: isUpdateSuccess }] = useUpdateEquipmentReturnMutation({ fixedCacheKey: 'attachments' });

  const formik = useFormik<EquipmentReturnPayload>({
    validationSchema: equipmentReturnPayloadSchema,
    initialValues: {
      referenceNumber: data?.referenceNumber ?? '',
      dockDoors: data?.dockDoors ?? false,
      emailNotificationList: data?.emailNotificationList ?? [],
      equipmentReturnName: data?.equipmentReturnName ?? '',
      freightElevator: data?.freightElevator ?? false,
      packages: data?.packages.map((item, index) => ({ ...item, key: index })) ?? [],
      equipmentReturnTypeName: data?.equipmentReturnType.name.replace(/\s+/g, '') ?? '',
      isEquipmentPackaged: data?.isEquipmentPackaged ?? false,
      isEquipmentValueOver100K: data?.isEquipmentValueOver100K ?? false,
      notes: data?.notes ?? '',
      pickupAddress: data?.pickupAddress ?? undefined,
      requestedPickupDate: data?.requestedPickupDate ?? null,
      requestedPickupTime: data?.requestedPickupTime ?? null,
      requestorName: data?.requestorName ?? '',
      requestorPhone: data?.requestorPhone ?? '',
      requestorEmail: data?.requestorEmail ?? '',
      siteContactEmail: data?.siteContactEmail ?? '',
      siteContactName: data?.siteContactName ?? '',
      siteContactPhone: data?.siteContactPhone ?? '',
      siteSpecificInstructions: data?.siteSpecificInstructions ?? '',
      waybillNumber: data?.waybillNumber ?? '',
      isParkingPassRequiredToParkCarrierTruck: data?.isParkingPassRequiredToParkCarrierTruck ?? false,
      isThereAPalletJacketOnsite: data?.isThereAPalletJacketOnsite ?? false,
      isThereAForkliftThere: data?.isThereAForkliftThere ?? false,
      isFloorESDSafe: data?.isFloorESDSafe ?? false,
      isThereALoadingDock: data?.isThereALoadingDock ?? false,
      canCabinetFitInElevator: data?.canCabinetFitInElevator ?? false,
      isAStaircaseNeededToTransportEquipment: data?.isAStaircaseNeededToTransportEquipment ?? false,
      isDisassemblyRequired: data?.isDisassemblyRequired ?? false,
      arePalletsNeededPriorToShipping: data?.arePalletsNeededPriorToShipping ?? false,
      isLiftGateRequired: data?.isLiftGateRequired ?? false,
      isFreightElevatorPermitRequired: data?.isFreightElevatorPermitRequired ?? false,
      isSpecialPermitToAccessLoadingDockRequired: data?.isSpecialPermitToAccessLoadingDockRequired ?? false,
      isThereAFreightElevator: data?.isThereAFreightElevator ?? false,
      equipmentReturnTypeId: data?.equipmentReturnType.id ?? '',
      qtyOfEquipment: data?.qtyOfEquipment ?? 0,
      numberOfPallets: data?.numberOfPallets ?? 0,
      numberOfBoxes: data?.numberOfBoxes ?? 0,
      totalWeight: data?.totalWeight ?? 0,
      returnStatus: data?.returnStatus ?? '',
      shipperBusinessName: data?.shipperBusinessName || '',
      shipperContactName: data?.shipperContactName || '',
      shipperContactPhone: data?.shipperContactPhone || '',
      warehouseContactName: data?.warehouseContactName || 'John Doe',
      warehouseContactPhone: data?.warehouseContactPhone || '5555555555'
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoadingMessage(isPost ? 'Submitting equipment return...' : 'Updating equipment return...');

      const digits = values.shipperContactPhone?.replace(/\D/g, '');

      if (isPost && values.equipmentReturnTypeName === 'ReturnShipment' && digits?.length !== 10) {
        notification.error({
          message: 'Error',
          description: 'Shipper Contact Phone must be 10 digits',
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 5
        });

        return;
      }

      const payload: EquipmentReturnPayload = {
        ...values,
        returnStatus: isPost ? 'Open' : 'Draft'
      };

      if (!id) return message.error('An error occurred, the team has been notified.');

      try {
        const response = await editReturn({ divisionId: selectedDivisionId, equipmentReturnId: id, body: payload }).unwrap();

        notification.success({
          message: 'Success',
          description: `Equipment return: ${response.equipmentReturnName} ${isPost ? 'submitted' : 'updated'} successfully.`,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 10
        });
        if (isExit) {
          nav('/');
          dispatch(setIsExit(false));
        }

        if (isPost) {
          nav(isInternalUser ? `/manage/${response.id}` : `/details/${response.id}`);
          dispatch(setIsPost(false));
        }

        dispatch(resetAttachments());
      } catch (e) {
        setLoadingMessage(undefined);
        console.error(e, 'error');

        const error = e as QueryErrorModel;

        dispatch(setIsPost(false));
        dispatch(setIsExit(false));
        const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occured, the team has been notified.';

        notification.error({
          message: 'Error',
          description: errorMessage,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 10
        });
      }
    }
  });

  useEffect(() => {
    return () => {
      dispatch(resetAttachments());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <Loader loadingMessage="Loading equipment return..." />;

  if (isError) {
    const err = error as QueryErrorModel;

    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row justify={'space-between'} style={{ width: '100%' }}>
          <Col>
            <Typography.Title level={4}>Edit Sparing Request</Typography.Title>
          </Col>
          <Col>
            <Space>
              <Button onClick={(): void => nav('/search')} icon={<ArrowLeftOutlined />}>
                Back to Search
              </Button>
            </Space>
          </Col>
        </Row>
        <WimsicalError title={formatErrorMessage(typeof err.status === 'number' ? err.status : 400)} subTitle={err?.data?.errorMessage ? err.data.errorMessage : ''} statusCode={err.status} />
      </Space>
    );
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Spin spinning={formik.isSubmitting || !!postRequestLoadingMessage || isFetching} indicator={<Loader loadingMessage={postRequestLoadingMessage ?? loadingMessage} />}>
          <Space direction="vertical" size={10} style={{ width: '100%' }}>
            <ActionControls />
            <EquipmentReturnMetaInfo returnOrderNumber={data?.returnOrderNumber} />
          </Space>
          <StepControls />
          <Outlet />
        </Spin>
      </FormikProvider>
    </>
  );
};

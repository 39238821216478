import { Col, Empty, Row, Spin } from 'antd';
import { LoadingMore } from 'components/common/LoadMore';
import { Loader } from 'components/common/Loader';
import WimsicalError from 'components/common/WimsicalError/WimsicalError';
import { formatErrorMessage } from 'helpers/formatErrorMessage';
import { QueryErrorModel } from 'models/ErrorModel';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetEquipmentReturnsDispatcherQuery, useGetEquipmentReturnsQuery } from 'redux/services/barkley/barkleyApi';
import { incrementOffset } from 'redux/services/barkley/barkleyParams';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { RecoverDeploymentCard } from './RecoverDeploymentCard';

export const DeploymentsGrid = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { barkleyParams } = useAppSelector((state) => state);
  const { isInternalUser, acuityContext } = useAppSelector((state) => state.app);
  const divisionId = acuityContext?.selectedCustomer?.id;
  const { data, isError, isLoading, isFetching, error } = useGetEquipmentReturnsQuery(
    { divisionId: divisionId as string, params: { ...barkleyParams, returnDeletedDataOnly: true } },
    { skip: isInternalUser || !divisionId }
  );
  const {
    data: dispatcherData,
    isError: isDispatchError,
    isLoading: isDispatchLoading,
    isFetching: isDispatchFetching,
    error: dispatchError
  } = useGetEquipmentReturnsDispatcherQuery({ ...barkleyParams, returnDeletedDataOnly: true }, { skip: !isInternalUser });
  const dispatch = useAppDispatch();

  const gridData = isInternalUser ? dispatcherData : data;
  const isGridLoading = isInternalUser ? isDispatchLoading : isLoading;
  const isGridFetching = isInternalUser ? isDispatchFetching : isFetching;
  const isGridError = isInternalUser ? isDispatchError : isError;
  const gridError = isInternalUser ? dispatchError : error;

  /* ******************** Functions / Variables ******************** */
  const handleGetMore = (): void => {
    dispatch(incrementOffset());
  };

  /* ******************** Error Render ******************** */
  if (isGridError) {
    const err = gridError as QueryErrorModel;

    return <WimsicalError title={formatErrorMessage(typeof err.status === 'number' ? err.status : 400)} subTitle={err?.data?.errorMessage ? err.data.errorMessage : ''} statusCode={err.status} />;
  }

  /* ******************** Loading Render ******************** */
  if (isGridLoading) {
    return (
      <Row style={{ marginTop: 8 }} gutter={[16, 16]}>
        <Loader />
      </Row>
    );
  }

  /* ******************** Data Render ******************** */
  const hasMore = (gridData?.totalCount ?? 0) > barkleyParams.offset;

  return (
    <Spin spinning={isGridFetching} style={{ width: '100%' }} indicator={<Loader />}>
      <InfiniteScroll style={{ overflowX: 'clip' }} dataLength={data?.data.length ?? 0} next={handleGetMore} hasMore={hasMore} loader={<LoadingMore />} scrollableTarget="scrollableDiv">
        <Row style={{ marginTop: 8 }} gutter={[16, 16]}>
          {gridData?.data.map((sparingRequest) => (
            <Col xs={24} md={12} lg={6} key={sparingRequest.id}>
              <RecoverDeploymentCard sparingRequest={sparingRequest} />
            </Col>
          ))}
        </Row>
        {!gridData?.totalCount && <Empty />}
      </InfiniteScroll>
    </Spin>
  );
};

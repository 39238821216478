import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EquipmentReturn } from 'models/EquipmentReturn';

interface InitialState {
  selectedEquipmentReturns: EquipmentReturn[];
}

const initialState: InitialState = {
  selectedEquipmentReturns: []
};

export const selectedSparingRequests = createSlice({
  name: 'selectedSparingRequests',
  initialState,
  reducers: {
    setSelectedEquipmentREturns: (state, { payload }: PayloadAction<EquipmentReturn[]>) => {
      state.selectedEquipmentReturns = payload;
    },
    addSelectedEquipmentREturns: (state, { payload }: PayloadAction<EquipmentReturn>) => {
      state.selectedEquipmentReturns = [...state.selectedEquipmentReturns, payload];
    },
    removeSelectedEquipmentREturns: (state, { payload }: PayloadAction<EquipmentReturn>) => {
      state.selectedEquipmentReturns = state.selectedEquipmentReturns.filter((item) => item.id !== payload.id);
    }
  }
});

export const { setSelectedEquipmentREturns, addSelectedEquipmentREturns, removeSelectedEquipmentREturns } = selectedSparingRequests.actions;

import { User } from '@auth0/auth0-react';
import { Menu, message } from 'antd';
import { useFormik } from 'formik';
import { EditProfile } from 'models/Users';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useUpdateUserMutation } from 'redux/services/cookieMonster/cookieMonsterApi';
import { setUser } from 'redux/slices/appSlice';
import { ReduxState } from 'redux/store';
import { UserProfile } from 'user-profile-mdsi';

export const EditProfilePage: React.FC = () => {
  /* ******************** Hooks ******************** */
  const navigate = useNavigate();
  const [updateUser] = useUpdateUserMutation();
  const dispatch = useDispatch();

  const { user: reduxUser } = useSelector((state: ReduxState) => state.app);

  const profileId = reduxUser?.sub as string;

  // /* ******************** API Hooks ******************** */

  const formik = useFormik<EditProfile>({
    enableReinitialize: true,
    initialValues: {
      email: reduxUser ? reduxUser.email : '',
      givenName: reduxUser ? reduxUser.given_name : '',
      familyName: reduxUser ? reduxUser.family_name : '',
      phoneNumber: reduxUser ? reduxUser['https://acuity.mdsiinc.com/user/user_metadata'].phone_number : ''
    },
    onSubmit: async (values) => {
      if (!reduxUser) {
        message.error("User doesn't exist");

        return;
      }
      try {
        const {
          email,
          family_name,
          given_name,
          name,
          user_metadata: { phone_number }
        } = await updateUser({ userId: profileId, payload: { ...reduxUser, ...values } }).unwrap();

        dispatch(setUser({ ...reduxUser, ...{ email, family_name, given_name, name, phone_number: phone_number ? phone_number : '' } }));

        message.success(`User ${name} was successfully updated!`);
      } catch (error) {
        console.log(error);
        message.error('User failed to be updated!');
      }
    }
  });

  // /* ******************** Functions ******************** */

  const handleClose = (): void => {
    navigate('/');
  };

  const handleSave = async (close?: boolean): Promise<void> => {
    await formik.submitForm();
    if (close) {
      handleClose();
    }
  };
  const menuJSX = (
    <Menu>
      <Menu.Item key="1" onClick={(): Promise<void> => handleSave(false)}>
        Save
      </Menu.Item>
    </Menu>
  );

  return <UserProfile extra user={reduxUser as User} key={'1'} />;
};

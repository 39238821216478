import { Col, Row } from 'antd';
import { PackageInformationTable } from 'pages/Defective/PackageInformationTable';

export const PackageInformationStep = (): JSX.Element => {
  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <PackageInformationTable />
      </Col>
    </Row>
  );
};

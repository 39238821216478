import { InputNumber, Modal, Select, Space, Typography } from 'antd';
import { LottieListButton } from 'components/common/LottieListButton';
import { useFormikContext } from 'formik';
import { EquipmentReturnPayload } from 'models/EquipmentReturn';
import { Package } from 'models/SparingRequests';
import { ColorCard } from 'pages/Create/ColorCard';
import { FC, Fragment, useState } from 'react';
import { colors, toRgba } from 'styles/colors';
import editLottie from '../../assets/edit.json';

type Props = {
  item: Package;
};

export const EditPackageButton: FC<Props> = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { values, setFieldValue } = useFormikContext<EquipmentReturnPayload>();
  const [formState, setFormState] = useState({
    packageSize: item.packageSize,
    packageWeight: item.packageWeight,
    numberOfPackages: item.numberOfPackages
  });

  const handleEdit = (): void => {
    const updatedVals = values.packages.map((val) => {
      if (val.packageSize === item.packageSize) {
        return formState;
      }

      return val;
    });

    setFieldValue('packages', updatedVals);
    setIsOpen(false);
  };

  return (
    <Fragment>
      <LottieListButton onClick={() => setIsOpen(true)} backgroundColor="#FFF5E5" title="Edit" lottieFile={editLottie} />
      <Modal closable={false} open={isOpen} onCancel={(): void => setIsOpen(false)} onOk={handleEdit}>
        <ColorCard title="Edit Line" color={toRgba(colors.illuminatingEmerald, 0.6)}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Text>Package Size</Typography.Text>
              <Select
                value={formState.packageSize}
                onChange={(e): void =>
                  setFormState({
                    ...formState,
                    packageSize: e
                  })
                }
                style={{ width: '100%' }}
                options={['XL', 'L', 'M', 'S'].map((opt) => ({ label: opt, value: opt }))}
              />
            </Space>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Text>Package Weight (lbs)</Typography.Text>
              <InputNumber
                value={formState.packageWeight}
                onKeyDown={(e): void => {
                  if (!e.code.includes('Digit') && !e.code.includes('Backspace')) e.preventDefault();
                }}
                onChange={(e): void => {
                  setFormState({
                    ...formState,
                    packageWeight: e as number
                  });
                }}
                controls={false}
                style={{ width: '100%' }}
              />
            </Space>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Text>Number of Packages</Typography.Text>
              <InputNumber
                value={formState.numberOfPackages}
                onKeyDown={(e): void => {
                  if (!e.code.includes('Digit') && !e.code.includes('Backspace')) e.preventDefault();
                }}
                onChange={(e): void => {
                  setFormState({
                    ...formState,
                    numberOfPackages: e as number
                  });
                }}
                controls={false}
                style={{ width: '100%' }}
              />
            </Space>
          </Space>
        </ColorCard>
      </Modal>
    </Fragment>
  );
};

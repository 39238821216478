import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RcFile } from 'antd/lib/upload';

interface InitialState {
  attachments: RcFile[];
}

const initialState: InitialState = {
  attachments: []
};

export const attachmentSlice = createSlice({
  name: 'attachments',
  initialState,
  reducers: {
    addAttachment: (state, { payload }: PayloadAction<RcFile>) => {
      state.attachments = [...state.attachments, payload];
    },
    removeAttachment: (state, { payload }: PayloadAction<RcFile>) => {
      state.attachments = state.attachments.filter((attachment) => attachment.uid !== payload.uid);
    },
    resetAttachments: (state) => {
      state.attachments = [];
    }
  }
});

export const { addAttachment, removeAttachment, resetAttachments } = attachmentSlice.actions;

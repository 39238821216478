import { SendOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { QueryErrorModel } from 'models/ErrorModel';
import { useParams } from 'react-router-dom';
import { useResendBOLAttachmentsMutation } from 'redux/services/barkley/barkleyApi';
import { useAppSelector } from 'redux/store';

export const SendBolNotificationsButtons = (): JSX.Element => {
  const { selectedDivisionId } = useAppSelector((state) => state.app);
  const { id } = useParams();
  const [sendNotifications, { isLoading }] = useResendBOLAttachmentsMutation();

  const handleClick = async (): Promise<void> => {
    if (!selectedDivisionId || !id) {
      notification.error({ message: 'Error', description: 'Division ID or Equipment Return ID is missing' });

      return;
    }

    try {
      await sendNotifications({ divisionId: selectedDivisionId, equipmentReturnId: id }).unwrap();
      notification.success({ message: 'Success', description: 'BOL attachments sent successfully' });
    } catch (e) {
      console.error(e, 'error');

      const error = e as QueryErrorModel;

      const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occurred, the team has been notified.';

      notification.error({
        message: 'Error',
        description: errorMessage,
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 10
      });
    }
  };

  return (
    <Button loading={isLoading} icon={<SendOutlined />} onClick={handleClick}>
      Send BOL Notifications
    </Button>
  );
};

import { PrinterOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { useFormikContext } from 'formik';
import { OpenEquipmentReturnPayload } from 'models/EquipmentReturn';
import { useDispatch } from 'react-redux';
import { setIsWaybill } from 'redux/slices/appSlice';
import { colors } from 'styles/colors';

export const GenerateWaybillButton = (): JSX.Element => {
  const dispatch = useDispatch();
  const { values, submitForm } = useFormikContext<OpenEquipmentReturnPayload>();

  const handleGenerateWaybill = async (): Promise<void> => {
    const digits = values.shipperContactPhone?.replace(/\D/g, '');

    if (digits?.length !== 10) {
      notification.error({
        message: 'Error',
        description: 'Shipper Contact Phone must be 10 digits',
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 5
      });

      return;
    }
    dispatch(setIsWaybill(true));
    await submitForm();
  };

  return (
    <>
      <Button style={{ border: `1px solid ${colors.success[600]}`, color: colors.success[600] }} onClick={handleGenerateWaybill} icon={<PrinterOutlined />}>
        Generate Waybills
      </Button>
    </>
  );
};

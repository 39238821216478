import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Spin } from 'antd';
import { Loader } from 'components/common/Loader';
import { VendorQuoteForm } from 'components/molecules/VendorQuoteForm';
import { format } from 'date-fns';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { OpenEquipmentReturnPayload, VendorQuotePayload, vendorQuotePayloadSchema } from 'models/EquipmentReturn';
import { QueryErrorModel } from 'models/ErrorModel';
import { ColorCard } from 'pages/Create/ColorCard';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateVendorQuoteMutation, useUpdateEquipmentReturnDispatcherMutation } from 'redux/services/barkley/barkleyApi';
import { useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';

const today = format(new Date(), 'MM-dd-yyyy');

export const AddVendorQuoteButton: FC = () => {
  const [open, setIsOpen] = useState(false);
  const { values: erVals } = useFormikContext<OpenEquipmentReturnPayload>();

  const { selectedDivisionId } = useAppSelector((state) => state.app);
  const { id } = useParams();
  const [createVendorQuote, { isLoading }] = useCreateVendorQuoteMutation();
  const [updateReturn, { isLoading: isUpdating }] = useUpdateEquipmentReturnDispatcherMutation({ fixedCacheKey: 'waybill' });

  const formik = useFormik<VendorQuotePayload>({
    enableReinitialize: true,
    validationSchema: vendorQuotePayloadSchema,
    initialValues: {
      fileBase64: '',
      fileName: '',
      vendor: '',
      quoteDate: today,
      quoteAmount: 0,
      markupPercentage: 40,
      freightPrice: 0.0,
      isAwarded: false
    },
    onSubmit: async (values) => {
      if (!selectedDivisionId || !id) {
        notification.error({ message: 'Error', description: 'Division ID or Equipment Return ID is missing' });

        return;
      }
      try {
        await createVendorQuote({ divisionId: selectedDivisionId, equipmentReturnId: id, payload: values }).unwrap();
        await updateReturn({ divisionId: selectedDivisionId, equipmentReturnId: id, body: erVals }).unwrap();

        if (values.isAwarded) {
          const payload: OpenEquipmentReturnPayload = {
            ...erVals,
            freightCompany: values.vendor
          };

          await updateReturn({ divisionId: selectedDivisionId, equipmentReturnId: id, body: payload }).unwrap();
        }
        setIsOpen(false);
        formik.resetForm();
        notification.success({ message: 'Success', description: 'Vendor Quote created successfully' });
      } catch (e) {
        console.error(e, 'error');

        const error = e as QueryErrorModel;

        const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occurred, the team has been notified.';

        notification.error({
          message: 'Error',
          description: errorMessage,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 10
        });
      }
    }
  });

  return (
    <FormikProvider value={formik}>
      <Button
        icon={<PlusCircleOutlined />}
        type="primary"
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}>
        Add Quote
      </Button>
      <Modal
        destroyOnClose
        okText={'Submit'}
        open={open}
        closable={false}
        onCancel={(): void => {
          setIsOpen(false);
          formik.resetForm();
        }}
        onOk={(): void => {
          formik.submitForm();
        }}
        width={450}
        closeIcon={undefined}>
        <ColorCard color={toRgba(colors.metallicGold, 0.4)} title={'Add Vendor Quote'}>
          <Spin spinning={isLoading} indicator={<Loader medium loadingMessage="Creating vendor quote..." />}>
            <VendorQuoteForm isEdit={false} />
          </Spin>
        </ColorCard>
      </Modal>
    </FormikProvider>
  );
};

/* eslint-disable prefer-const */
import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/store';

export const useAboutPageDataTest = (envs: { baseUrl: string; key: string }[]): { isLoading?: boolean; apiName?: string; version?: string }[] | undefined => {
  const { accessToken } = useAppSelector((state) => state.app);
  const [loadingStates, setLoadingStates] = useState(new Array(envs.length).fill({ apiName: '', version: '', isLoading: true }));

  useEffect(() => {
    ((): void => {
      envs.forEach(async (env, idx) => {
        const name = await fetch(`${env.baseUrl}/diagnostics/apiName`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-functions-key': `${env.key}`,
            authorization: `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin': '*'
          }
        });

        const version = await fetch(`${env.baseUrl}/diagnostics/version`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-functions-key': `${env.key}`,
            authorization: `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin': '*'
          }
        });

        const apiName = await name.text();

        const apiVersion = await version.text();

        setLoadingStates((prev) => {
          const newData = [...prev];

          newData[idx] = { apiName, version: apiVersion, isLoading: false };

          return newData;
        });
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loadingStates;
};

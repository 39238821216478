import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib';
import { Field, FieldProps, useFormikContext } from 'formik';
import { VendorQuote } from 'models/EquipmentReturn';
import { FC } from 'react';
import { FormLabel } from './FormLabel';

interface Props extends InputNumberProps {
  fieldName: string;
  placeholder?: string;
  label: string;
}

export const FieldInputNumber: FC<Props> = ({ fieldName, placeholder, label, ...rest }) => {
  const { values } = useFormikContext<VendorQuote>();

  const handleKeyPress = (key: React.KeyboardEvent<HTMLInputElement>): void => {
    if (!key.code.includes('Digit') && !key.code.includes('Numpad') && key.code !== 'Backspace' && key.code !== 'Tab') {
      key.preventDefault();
    }
  };

  return (
    <Field name={fieldName} placeholder={placeholder}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched, handleChange, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        return (
          <div>
            <FormLabel label={label} />
            <InputNumber
              controls={false}
              onFocus={() => setFieldTouched(fieldName, false)}
              style={{ borderColor: meta.error && meta.touched ? '#dc3545' : undefined, width: '100%' }}
              onKeyDown={handleKeyPress}
              {...rest}
              {...field}
              onBlur={(e): void => {
                if (!e.target.value) setFieldValue(fieldName, 0);
              }}
              onChange={(e) => {
                if (fieldName === 'markup' && !e) {
                  setFieldValue('freightPrice', 0);

                  return;
                }
                setFieldValue(fieldName, e);
                if (values.quoteAmount) {
                  console.log(values.quoteAmount);
                  console.log(e);
                  setFieldValue('freightPrice', ((values.quoteAmount as number) / (1 - (e as number) / 100)).toFixed(2));
                }
              }}
            />
            <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
          </div>
        );
      }}
    </Field>
  );
};

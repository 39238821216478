/* eslint-disable no-debugger */
import { List } from 'antd';
import { Loader } from 'components/common/Loader';
import WimsicalError from 'components/common/WimsicalError/WimsicalError';
import { formatErrorMessage } from 'helpers/formatErrorMessage';
import { OpenEquipmentReturn } from 'models/EquipmentReturn';
import { QueryErrorModel } from 'models/ErrorModel';
import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetEquipmentReturnsDispatcherQuery, useGetEquipmentReturnsQuery } from 'redux/services/barkley/barkleyApi';
import { incrementOffset, resetParams } from 'redux/services/barkley/barkleyParams';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { ERCardOpen } from './ERCardOpen';

export const CardGridInquiry = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { barkleyParams } = useAppSelector((state) => state);
  const { isInternalUser, acuityContext } = useAppSelector((state) => state.app);
  const divisionId = acuityContext?.selectedCustomer?.id;
  const { data, isError, isLoading, isFetching, error } = useGetEquipmentReturnsQuery({ divisionId: divisionId as string, params: barkleyParams }, { skip: isInternalUser || !divisionId });
  const {
    data: dispatcherData,
    isError: isDispatchError,
    isLoading: isDispatchLoading,
    isFetching: isDispatchFetching,
    error: dispatchError
  } = useGetEquipmentReturnsDispatcherQuery(barkleyParams, { skip: !isInternalUser });
  const dispatch = useAppDispatch();

  const gridData = isInternalUser ? dispatcherData : data;
  const isGridLoading = isInternalUser ? isDispatchLoading : isLoading;
  const isGridFetching = isInternalUser ? isDispatchFetching : isFetching;
  const isGridError = isInternalUser ? isDispatchError : isError;
  const gridError = isInternalUser ? dispatchError : error;

  const handleGetMore = (): void => {
    dispatch(incrementOffset());
  };

  const hasMore = (gridData?.totalCount ?? 0) > (gridData?.data?.length ?? 0);

  useEffect(() => {
    return () => {
      dispatch(resetParams());
    };
  }, []);

  if (isGridLoading || (isGridFetching && barkleyParams.offset === 0)) return <Loader loadingMessage="Loading Equipment Returns" />;

  if (isGridError) {
    const err = gridError as QueryErrorModel;

    return <WimsicalError title={formatErrorMessage(typeof err.status === 'number' ? err.status : 400)} subTitle={err?.data?.errorMessage ? err.data.errorMessage : ''} statusCode={err.status} />;
  }

  return (
    <InfiniteScroll next={handleGetMore} hasMore={hasMore} dataLength={gridData?.data.length ?? 0} loader={<Loader />}>
      <List
        dataSource={gridData?.data}
        grid={{ gutter: 16, column: 4 }}
        renderItem={(item): JSX.Element => (
          <List.Item>
            <ERCardOpen equipmentReturn={item as OpenEquipmentReturn} />
          </List.Item>
        )}
      />
    </InfiniteScroll>
  );
};

import { Spin } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { FancySelect } from 'components/ui/Inputs';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useGetDivisionsQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { setDivisionId } from 'redux/services/barkley/barkleyParams';
import { useAppSelector } from 'redux/store';

export const CustomerFilter: React.FC = () => {
  const { divisionId } = useAppSelector((state) => state.barkleyParams);
  const { data, isLoading, isFetching } = useGetDivisionsQuery();
  const dispatch = useDispatch();

  const options: DefaultOptionType[] | undefined = data?.data.map((division) => ({ label: `${division.erpId} - ${division.name}`, value: division.id }));

  const handleChange = (value: string): void => {
    dispatch(setDivisionId(value));
  };

  return (
    <Spin spinning={isLoading || isFetching}>
      <FancySelect placeholder="Customer" style={{ margin: 0 }} onChange={handleChange} value={divisionId} showSearch options={options} />
    </Spin>
  );
};

import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import { InventoryOnHandPayload, InvOnHandPayload, InvOnHandResp } from 'models/DeploymentRequestConfig';
import { ReduxState } from 'redux/store';

/* ******************** Base Query ******************** */
const baseUrl = process.env.REACT_APP_MR_JOHNSON_BASE_URL;
const functionsKey = process.env.REACT_APP_API_HOST_KEY_MR_JOHNSON;

export const mrJohnsonBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const divisionId = (api.getState() as ReduxState).app.acuityContext?.selectedCustomer.id;
  const isDiagnostic = typeof args !== 'string' && (args.url === '/diagnostics/version' || args.url === '/diagnostics/apiName');

  if (!divisionId && !isDiagnostic) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No division ID received'
      }
    };
  }
  const urlEnd = typeof args === 'string' ? args : args.url;
  const adjustedUrl = isDiagnostic ? args.url : `divisions/${divisionId}/${urlEnd}`;
  const adjustedArgs = typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };

  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as ReduxState).app.accessToken;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('x-functions-key', functionsKey);

        if (api.endpoint === 'createAttachment') {
          //headers.set('Content-Type', 'multipart/form-data');
        } else {
          headers.set('Content-Type', 'application/json');
        }
      }

      return headers;
    }
  })(adjustedArgs, api, extraOptions);
};

export const mrJohnsonApi = createApi({
  reducerPath: 'mrJohnsonApi',
  baseQuery: mrJohnsonBaseQuery,
  endpoints: (builder) => ({
    getInventoryOnHand: builder.query<InvOnHandResp[], InventoryOnHandPayload>({
      query: ({ id, product }) => ({
        url: `sparingRequestTypes/${id}/availableInventory/${encodeURIComponent(encodeURIComponent(product))}`
      })
    }),
    getInventory: builder.query<InvOnHandResp[], { typeId: string; product: string; payload: InvOnHandPayload }>({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const { typeId, product, payload } = arg;

        const updatedPayload: InvOnHandPayload = {
          ...payload,
          shippingAddress: {
            city: payload.shippingAddress.city,
            country: payload.shippingAddress.country ?? '',
            countryAlpha2Code: payload.shippingAddress.countryAlpha2Code,
            postalCode: payload.shippingAddress.postalCode,
            street1: payload.shippingAddress.street1,
            latitude: payload.shippingAddress.latitude ? payload.shippingAddress.latitude : undefined,
            longitude: payload.shippingAddress.longitude ? payload.shippingAddress.longitude : undefined
          }
        };

        const resp = (await baseQuery({
          url: `sparingRequestTypes/${typeId}/availableInventory/${encodeURIComponent(encodeURIComponent(product))}`,
          method: 'POST',
          body: updatedPayload
        })) as QueryReturnValue<InvOnHandResp[], FetchBaseQueryError, FetchBaseQueryMeta>;

        if (resp.error) {
          return { error: resp.error };
        }

        return { data: resp.data };
      }
    }),
    getVersion: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/version',
        responseHandler: (response): Promise<string> => response.text()
      })
    }),
    getApiName: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/apiName',
        responseHandler: (response): Promise<string> => response.text()
      })
    })
  })
});

export const { useGetInventoryOnHandQuery, useGetVersionQuery, useGetApiNameQuery, useGetInventoryQuery } = mrJohnsonApi;

import { Col, Row } from 'antd';
import { PickupContent, ProductsContent, RequestInfoContent } from 'components/Create/ReviewOrder';
import { AttachmentsContent } from 'components/Create/ReviewOrder/AttachmentsContent';
import { OrderInfoContent } from 'components/Create/ReviewOrder/OrderInfoContent';
import { useFormikContext } from 'formik';
import { EquipmentReturnPayload } from 'models/EquipmentReturn';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useParams } from 'react-router-dom';
import { useGetEquipmentReturnQuery } from 'redux/services/barkley/barkleyApi';
import { useAppSelector } from 'redux/store';

const styles: InlineStylesModel = {
  headerContainer: {
    backgroundColor: 'white',
    padding: 24
  },
  headerTitle: {
    margin: 0,
    fontWeight: 'normal'
  },
  container: {
    flex: 1
  }
};

export const ReviewOrder = (): JSX.Element => {
  const { selectedDivisionId } = useAppSelector((state) => state.app);
  const { id } = useParams();
  const { data } = useGetEquipmentReturnQuery({ divisionId: selectedDivisionId as string, equipmentReturnId: id as string }, { skip: !id || !selectedDivisionId });
  const { values, setFieldValue } = useFormikContext<EquipmentReturnPayload>();

  const isReturnShipment = values.equipmentReturnTypeName === 'ReturnShipment';

  return (
    <Row gutter={[8, 8]} style={styles.container}>
      <Col sm={24} md={12}>
        <RequestInfoContent />
      </Col>
      <Col sm={24} md={12}>
        <PickupContent />
      </Col>
      <Col sm={24} md={12}>
        <OrderInfoContent />
      </Col>
      {!isReturnShipment && (
        <Col sm={24} md={12}>
          <ProductsContent />
        </Col>
      )}
      <Col sm={24} md={12}>
        {data && data?.attachments.length >= 1 ? <AttachmentsContent /> : null}
      </Col>
    </Row>
  );
};

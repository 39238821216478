import { DatePicker, InputProps } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Field, FieldProps } from 'formik';
import { FC } from 'react';
import { FormLabel } from './FormLabel';

dayjs.extend(utc);

interface Props extends InputProps {
  fieldNameDate: string;
  placeholder?: string;
  label: string;
}

export const FieldInputDateOnlyPicker: FC<Props> = ({ fieldNameDate, placeholder, label }) => {
  return (
    <Field name={fieldNameDate} placeholder={placeholder}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { handleBlur, setFieldValue, handleChange }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        return (
          <div>
            <FormLabel label={label} />
            <DatePicker
              format={'MM/DD/YYYY'}
              value={field.value ? dayjs(field.value) : undefined}
              onChange={(date, dateString) => {
                console.log('dateString', dateString);
                setFieldValue(fieldNameDate, dateString);
              }}
              onBlur={() => handleBlur(fieldNameDate)}
              style={{ width: '100%' }}
            />

            <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
          </div>
        );
      }}
    </Field>
  );
};

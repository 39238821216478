import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { Application } from 'models/Application';
import { ReduxState } from 'redux/store';

const baseUrl = process.env.REACT_APP_FREDDY_CADABBY_BASE_URL || '';
const functionsKey = process.env.REACT_APP_API_HOST_KEY_FREDDY_CADABBY || '';

export const freddyCadabbyApi = createApi({
  reducerPath: 'freddyCadabbyApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as ReduxState).app.accessToken;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('x-functions-key', functionsKey);
        headers.set('Content-Type', 'application/json');
      }

      return headers;
    }
  }),
  tagTypes: ['Application', 'Applications'],
  endpoints: (builder) => ({
    getApplication: builder.query<Application, string>({
      query: (applicationId) => ({
        url: `/applications/${applicationId}`
      }),
      providesTags: ['Application']
    }),
    getVersion: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/version',
        responseHandler: (response): Promise<string> => response.text()
      })
    }),
    getApiName: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/apiName',
        responseHandler: (response): Promise<string> => response.text()
      })
    })
  })
});

export const { useGetApplicationQuery, useGetVersionQuery, useGetApiNameQuery } = freddyCadabbyApi;

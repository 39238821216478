import { Card, Typography } from 'antd';
import { FC } from 'react';
import { colors, toRgba } from 'styles/colors';

const { Title } = Typography;

type Props = {
  children: React.ReactNode;
};

export const EquipmentListCard: FC<Props> = ({ children }) => {
  return (
    <Card
      styles={{ header: { backgroundColor: toRgba(colors.romanSilver, 0.4) } }}
      title={
        <Title level={4} style={{ fontWeight: 'normal', margin: 0, padding: 0 }}>
          Equipment List
        </Title>
      }
      style={{ height: '100%' }}>
      {children}
    </Card>
  );
};

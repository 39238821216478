import { FilePdfOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { TrackingWaybillTable } from 'components/organisms/TrackingInfoTable';
import { EquipmentReturn } from 'models/EquipmentReturn';
import { ColorCard } from 'pages/Create/ColorCard';
import { FC, useState } from 'react';
import { colors, toRgba } from 'styles/colors';

export type Props = {
  packagesWithWaybill?: EquipmentReturn['packages'];
};

export const ViewWaybillsButton: FC<Props> = ({ packagesWithWaybill }) => {
  const [open, setIsOpen] = useState(false);

  return (
    <>
      <Button onClick={(): void => setIsOpen(true)} icon={<FilePdfOutlined />}>
        View Waybills
      </Button>
      <Modal
        okText={'Exit'}
        cancelButtonProps={{ style: { display: 'none' } }}
        open={open}
        closable={false}
        onCancel={(): void => setIsOpen(false)}
        onOk={(): void => setIsOpen(false)}
        width={700}
        closeIcon={undefined}>
        <ColorCard title={'Waybills'} color={toRgba(colors.illuminatingEmerald, 0.4)}>
          <TrackingWaybillTable packagesWithWaybill={packagesWithWaybill ?? []} />
        </ColorCard>
      </Modal>
    </>
  );
};

import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib';
import { Field, FieldProps, useFormikContext } from 'formik';
import { VendorQuote } from 'models/EquipmentReturn';
import { FC } from 'react';
import { FormLabel } from './FormLabel';

interface Props extends InputNumberProps {
  fieldName: string;
  placeholder?: string;
  label: string;
}

export const FieldInputCurrency: FC<Props> = ({ fieldName, placeholder, label, ...rest }) => {
  const { values } = useFormikContext<VendorQuote>();
  const handleKeyPress = (key: React.KeyboardEvent<HTMLInputElement>): void => {
    console.log(key.code);
    if (
      !key.code.includes('Digit') &&
      !key.code.includes('Numpad') &&
      key.code !== 'Backspace' &&
      key.code !== 'Tab' &&
      key.code !== 'ArrowLeft' &&
      key.code !== 'ArrowRight' &&
      key.code !== 'Period'
    ) {
      key.preventDefault();
    }
  };

  return (
    <Field name={fieldName} placeholder={placeholder}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched, handleChange, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        return (
          <div>
            <FormLabel label={label} />
            <InputNumber
              prefix="$"
              controls={false}
              onFocus={() => setFieldTouched(fieldName, false)}
              style={{ borderColor: meta.error && meta.touched ? '#dc3545' : undefined, width: '100%' }}
              onKeyDown={handleKeyPress}
              step={0.01}
              {...rest}
              {...field}
              onBlur={(e): void => {
                if (!e.target.value) {
                  setFieldValue(fieldName, 0);

                  return;
                }
                setFieldValue(fieldName, parseFloat(e.target.value).toFixed(2));
              }}
              onChange={(e) => {
                if (!e) setFieldValue(fieldName, 0);
                setFieldValue(fieldName, (e as number)?.toFixed(2));
                if (values.markupPercentage) {
                  setFieldValue('freightPrice', ((e as number) / (1 - values.markupPercentage / 100)).toFixed(2));
                }
              }}
            />
            <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
          </div>
        );
      }}
    </Field>
  );
};

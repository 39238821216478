import { FancyInput } from 'components/ui/Inputs';
import React from 'react';
import { setReferenceIdContains } from 'redux/services/barkley/barkleyParams';
import { useAppDispatch } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';

export const ReferenceIdFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(setReferenceIdContains(value || undefined));
  }, 500);

  return <FancyInput placeholder="Reference Name" onChange={handleChange} allowClear />;
};

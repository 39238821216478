import { Card, Col, Row, Tooltip, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { EquipmentReturnPayload, PickupAddress } from 'models/EquipmentReturn';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Address } from 'redux/services/chuckieSue/models/addresses';
import { setIsDrawerOpen } from 'redux/slices/formSlice';
import { colors, toRgba } from 'styles/colors';
import { ReactComponent as WarehouseIcon } from '../../../../../assets/warehouse.svg';

interface Props {
  address: Address;
}

const styles: InlineStylesModel = {
  cardBody: {
    borderRadius: 10,
    padding: 0
  },
  customerInfo: {
    padding: '10px 60px'
  },
  title: {
    fontSize: '.8rem',
    margin: 0,
    color: 'rgba(0, 0, 0, .4)'
  },
  titleWhite: {
    fontSize: '.8rem',
    margin: 0,
    color: 'rgba(255, 255, 255, .4)'
  },
  values: {
    fontSize: 16,
    fontWeight: 600
  },
  valuesWhite: {
    color: 'rgba(255, 255, 255, .9)',
    fontSize: 16,
    fontWeight: 600
  },
  minusButton: {
    color: '#F93943',
    borderColor: '#F93943'
  },
  selectedInstallStyles: {
    transition: '.25s all ease-out',
    background: toRgba(colors.royalBlueLight, 0.6),
    boxShadow: '0 0 6px rgba(0,0,0,.4)',
    borderRadius: 10
  },
  selectedShippingStyles: {
    transition: '.25s all ease-out',
    background: toRgba(colors.illuminatingEmerald, 0.6),
    boxShadow: '0 0 6px rgba(0,0,0,.4)',
    borderRadius: 10
  },
  removeButton: {
    margin: 'auto'
  }
};

export const LocationCard = ({ address }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { setFieldValue, values } = useFormikContext<EquipmentReturnPayload>();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const isManage = pathname.includes('manage');

  const isSelectedCard = values.pickupAddress?.id === address.id;

  /* ******************** Functions ******************** */
  const handleSetLocation = (): void => {
    const addressPayload: PickupAddress = {
      city: address.city,
      countryAlpha2Code: address?.country?.alpha2Code ?? ' ',
      subdivisionLocalCode: address?.subdivision?.localCode ?? '',
      code: address.code,
      country: address.country.shortName,
      id: address.id,
      latitude: address.latitude,
      locationId: address.business.erpId,
      longitude: address.longitude,
      name: address?.name ?? '',
      postalCode: address.postalCode,
      street1: address?.street1,
      street2: address?.street2 ?? '',
      subdivision: address?.subdivision?.name ?? '',
      isManualAddress: false
    };

    setFieldValue('pickupAddress', addressPayload);

    if (isManage) {
      dispatch(setIsDrawerOpen(false));
    }
  };

  const handleRemoveInstallLocation = (): void => {
    setFieldValue('pickupAddress', undefined);
  };

  // console.log('isSelectedCard', isSelectedCard);

  return (
    <Card bordered={false} style={styles.cardBody} bodyStyle={styles.cardBody} hoverable onClick={isSelectedCard ? handleRemoveInstallLocation : handleSetLocation}>
      <Row style={{ borderRadius: 10 }}>
        <Col span={10} style={{ background: isSelectedCard ? 'rgba(40,40,40,.6)' : 'rgba(0,0,0,.2)', padding: '0px 20px', borderRadius: '10px 0 0 10px', paddingTop: '5px' }}>
          <Row gutter={[5, 0]} style={{ paddingTop: '10px' }}>
            <Col>
              <Tooltip title="Warehouse" placement="top" autoAdjustOverflow={false} overlayInnerStyle={styles.tooltipStyle}>
                <WarehouseIcon width={20} />
              </Tooltip>
            </Col>
          </Row>
          <Typography.Paragraph style={isSelectedCard ? styles.titleWhite : styles.title}>
            <Tooltip title="Address Code" placement="left" autoAdjustOverflow={false} overlayInnerStyle={styles.tooltipStyle}>
              {address.code || <span>&nbsp;</span>}
            </Tooltip>
          </Typography.Paragraph>
          <Typography.Paragraph style={isSelectedCard ? styles.valuesWhite : styles.values}>
            {' '}
            <Tooltip title="Address Name" placement="left" autoAdjustOverflow={false} overlayInnerStyle={styles.tooltipStyle}>
              {address.name || <span>&nbsp;</span>}
            </Tooltip>
          </Typography.Paragraph>
        </Col>
        <Col span={14}>
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              width: '81.25px',
              height: '45.625px',
              borderLeft: isSelectedCard ? 'solid 40.625px rgba(40,40,40,.6)' : 'solid 40.625px rgba(0,0,0,.2)',
              borderBottom: 'solid 50.625px transparent',
              borderTop: 'solid 50.625px transparent'
            }}
          />
          <Col style={styles.customerInfo}>
            <Typography.Paragraph style={isSelectedCard ? styles.titleWhite : styles.title}>Address</Typography.Paragraph>
            <Typography.Paragraph style={isSelectedCard ? styles.valuesWhite : styles.values}>
              {address.street1} {address.city}, {address?.subdivision?.localCode ?? ''} {address?.country?.alpha2Code ?? ''}
            </Typography.Paragraph>
          </Col>
        </Col>
      </Row>
    </Card>
  );
};

import { Col, Radio, Row, Typography } from 'antd';
import { ReturnDetailsCard } from 'components/common/ReturnDetailsCard';
import { ReturnInfoForm } from 'components/molecules/ReturnInfoForm';
import { useFormikContext } from 'formik';
import { EquipmentReturnPayload } from 'models/EquipmentReturn';
import { colors, toRgba } from 'styles/colors';
import { AttachmentsCard } from './AttachmentsCard';
import { ColorCard } from './ColorCard';
import { EquipmentListCard } from './EquipmentListCard';
const { Title } = Typography;

export const RequestInfoPage = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<EquipmentReturnPayload>();

  const isReturnShipment = values.equipmentReturnTypeName === 'ReturnShipment';

  if (isReturnShipment) {
    return (
      <Row gutter={[12, 12]}>
        <Col span={16}>
          <AttachmentsCard />
        </Col>
        <Col span={8}>
          <ColorCard title={'Equipment Info'} color={toRgba(colors.romanSilver, 0.6)}>
            <Row align={'middle'} justify={'space-between'} style={{ height: '100%' }}>
              <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>
                Is the value of equipment greater than $100,000?
              </Typography.Title>
              <Radio.Group onChange={(event) => setFieldValue('isEquipmentValueOver100K', event.target.value)} value={values.isEquipmentValueOver100K}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Row>
          </ColorCard>
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={[12, 12]}>
      <Col span={7}>
        <ReturnDetailsCard />
      </Col>

      <Col span={17} style={{ minHeight: '100%' }}>
        <EquipmentListCard>
          <Row style={{ width: '100%' }} gutter={16}>
            <Col span={12}>
              <AttachmentsCard />
            </Col>
            <Col span={12}>
              <ReturnInfoForm />
            </Col>
          </Row>
        </EquipmentListCard>
      </Col>
    </Row>
  );
};

import { InboxOutlined } from '@ant-design/icons';
import { message, notification, Spin, UploadProps } from 'antd';
import { RcFile } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import { Loader } from 'components/common/Loader';
import { useFormikContext } from 'formik';
import { OpenEquipmentReturnPayload } from 'models/EquipmentReturn';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCreateAttachmentMutation, useCreateBOLAttachmentMutation, useGetEquipmentReturnQuery, useUpdateEquipmentReturnDispatcherMutation } from 'redux/services/barkley/barkleyApi';
import { addAttachment, removeAttachment } from 'redux/slices/attachmentsSlice';
import { useAppSelector } from 'redux/store';

type Props = {
  isBol?: boolean;
};

export const AttachmentUpload: FC<Props> = ({ isBol }) => {
  /* ******************** Hooks ******************** */
  const dispatch = useDispatch();
  const { id } = useParams();
  const [createAttachment, { isLoading }] = useCreateAttachmentMutation();
  const [createBOLAttachment, { isLoading: isBolLoading }] = useCreateBOLAttachmentMutation();
  const [updateReturn, { isLoading: isUpdating }] = useUpdateEquipmentReturnDispatcherMutation({ fixedCacheKey: 'waybill' });
  const { values: erVals } = useFormikContext<OpenEquipmentReturnPayload>();

  const { selectedDivisionId } = useAppSelector((state) => state.app);
  const { data: deploymentRequest } = useGetEquipmentReturnQuery({ divisionId: selectedDivisionId as string, equipmentReturnId: id as string }, { skip: !id || !selectedDivisionId });

  const { attachments } = useAppSelector((state) => state.attachments);

  /* ******************** Functions ******************** */

  const editedNames = deploymentRequest?.attachments.map((attachment) => attachment.name.slice(attachment.name.indexOf('-') + 1));

  const props: UploadProps = {
    multiple: true,
    customRequest: async ({ file }) => {
      if (id) {
        try {
          const formData = new FormData();
          const fileToUpload = file as RcFile;

          formData.append(`${fileToUpload.name}`, fileToUpload);
          if (isBol) {
            await createBOLAttachment({ file: formData, equipmentReturnId: id as string, divisionId: selectedDivisionId }).unwrap();
          } else await createAttachment({ file: formData, equipmentReturnId: id as string, divisionId: selectedDivisionId }).unwrap();
          await updateReturn({ divisionId: selectedDivisionId, equipmentReturnId: id, body: erVals }).unwrap();
          notification.success({
            message: 'Success',
            description: 'Attachment uploaded successfully.'
          });
        } catch (e) {
          const error = (e as { data: string }).data ?? 'There was an error uploading your attachment. Please try again.';

          notification.error({
            message: 'Error',
            description: error
          });
          console.log(error, 'error');
        }

        return;
      }
      const fileToUpload = file as RcFile;

      if (attachments.find((uploadedFile) => uploadedFile.name === fileToUpload.name)) message.error(`File ${fileToUpload.name} has already been added.`);
      else if (editedNames?.find((uploadedFile) => uploadedFile === fileToUpload.name)) message.error(`File ${fileToUpload.name} has already been uploaded.`);
      else dispatch(addAttachment(fileToUpload));
    },
    onRemove(file) {
      const fileToDelete = file as RcFile;

      dispatch(removeAttachment(fileToDelete));
    }
  };

  return (
    <Spin spinning={isLoading || isBolLoading} indicator={<Loader medium />}>
      <Dragger
        {...props}
        defaultFileList={[]}
        listType="picture"
        height={200}
        style={{ display: 'inline-block', width: '100%' }}
        fileList={!id ? attachments?.filter((file) => !editedNames?.includes(file.name)) : undefined}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined style={{ color: '#5867dd' }} />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
      </Dragger>
    </Spin>
  );
};

import { Button, Tooltip } from 'antd';
import { ButtonProps } from 'antd/lib';
import { useState } from 'react';
import Lottie from 'react-lottie-player';

type Props = ButtonProps & {
  title: string;
  lottieFile: object;
  backgroundColor: string;
};

export const LottieListButton: React.FC<Props> = ({ title, lottieFile, backgroundColor, ...rest }) => {
  const [iconHover, setIconHover] = useState(false);

  return (
    <Tooltip title={title}>
      <Button
        style={{ backgroundColor: iconHover ? backgroundColor : undefined }}
        onMouseLeave={(): void => setIconHover(false)}
        onMouseEnter={(): void => setIconHover(true)}
        icon={<Lottie loop goTo={0} play={iconHover} style={{ width: 22, height: 22 }} animationData={lottieFile} />}
        {...rest}
      />
    </Tooltip>
  );
};

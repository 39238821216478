import { PlusCircleOutlined } from '@ant-design/icons';
import { GetProp, Row, Space, Typography, UploadFile } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { UploadProps } from 'antd/lib';
import { useFormikContext } from 'formik';
import { VendorAttachment, VendorQuotePayload } from 'models/EquipmentReturn';
import { FC, useState } from 'react';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

type Props = {
  attachment?: VendorAttachment;
  isEdit?: boolean;
};

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const VendorAttachmentUpload: FC<Props> = ({ attachment, isEdit }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: '-1',
      name: attachment?.name ?? '',
      status: 'done',
      url: attachment?.url
    }
  ]);

  const { setFieldValue } = useFormikContext<VendorQuotePayload>();

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (isEdit) {
      setFieldValue('didAttachmentFileChange', true);
    }
  };

  const props: UploadProps = {
    name: 'file',
    beforeUpload: async (file: FileType) => {
      const base64 = await getBase64(file);
      const trimmedBase64 = base64.split(',')[1];

      if (isEdit) {
        setFieldValue('didAttachmentFileChange', true);
      }

      setFieldValue('fileBase64', trimmedBase64);
      setFieldValue('fileName', file.name);

      return false;
    },
    fileList: attachment ? fileList : undefined,
    onChange: handleChange
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Typography.Text>Attachment</Typography.Text>
      <Row style={{ marginBottom: 16 }}>
        <Dragger {...props} maxCount={1}>
          <p className="ant-upload-drag-icon">
            <PlusCircleOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Dragger>
      </Row>
    </Space>
  );
};

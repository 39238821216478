import { Col, Row, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { EquipmentReturnPayload } from 'models/EquipmentReturn';
import { ColorCard } from 'pages/Create/ColorCard';
import { useGetEquipmentReturnConfigurationTypesQuery } from 'redux/services/maurice/sparingRequestConfigTypes';
import { useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';

export const RequestInfoContent = (): JSX.Element => {
  const { values } = useFormikContext<EquipmentReturnPayload>();
  const { acuityContext } = useAppSelector((state) => state.app);
  const divisionId = acuityContext?.selectedCustomer.id;
  const { data } = useGetEquipmentReturnConfigurationTypesQuery({ divisionId: divisionId as string, params: {} }, { skip: !divisionId });

  const equipmentReturnType = data?.data.find((type) => values.equipmentReturnTypeId === type.id)?.name ?? '';

  return (
    <ColorCard color={toRgba(colors.romanSilver, 0.4)} title={'Return Identification'}>
      <Row gutter={16} style={{ height: '100%' }}>
        <Col span={8}>
          <Typography.Title level={5}>Requestor Name:</Typography.Title>
          <Typography.Paragraph>{values.requestorName}</Typography.Paragraph>
          <Typography.Title level={5}>Reference Number:</Typography.Title>
          <Typography.Paragraph>{values.referenceNumber}</Typography.Paragraph>
        </Col>
        <Col span={8}>
          <Typography.Title level={5}>Requestor Number:</Typography.Title>
          <Typography.Paragraph>{values.requestorPhone}</Typography.Paragraph>
          <Typography.Title level={5}>Equipment Return Type:</Typography.Title>
          <Typography.Paragraph>{equipmentReturnType}</Typography.Paragraph>
        </Col>
        <Col span={8}>
          <Typography.Title level={5}>Requestor Email:</Typography.Title>
          <Typography.Paragraph>{values.requestorEmail}</Typography.Paragraph>
        </Col>
      </Row>
    </ColorCard>
  );
};

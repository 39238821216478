import { Button, Col, Row, Typography } from 'antd';
import { ColoredCard } from 'components/common/ColoredCard';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { EquipmentReturnPayload } from 'models/EquipmentReturn';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useNavigate, useParams } from 'react-router-dom';
import { colors, toRgb, toRgba } from 'styles/colors';

const { Text, Title } = Typography;

const styles: InlineStylesModel = {
  header: {
    backgroundColor: toRgba(colors.illuminatingEmerald, 0.4)
  },
  selectAddressButton: {
    marginBottom: 10,
    width: '50%'
  },
  selectDeliverDateButton: {
    marginBottom: 10,
    width: '80%'
  }
};

export const PickupContent = (): JSX.Element => {
  const nav = useNavigate();
  const { id } = useParams();
  const { values } = useFormikContext<EquipmentReturnPayload>();
  const fullPickupAddress = values.pickupAddress;

  const isReturnShipment = values.equipmentReturnTypeName === 'ReturnShipment';

  return (
    <ColoredCard color={toRgba(colors.illuminatingEmerald, 0.4)} headStyle={styles.header} style={{ height: '100%' }} title={'Pickup Information'}>
      <Row gutter={[32, 0]}>
        <Col span={12}>
          <Typography.Title level={5} style={{ color: fullPickupAddress ? 'black' : toRgb(colors.redSalsa) }}>
            Pickup Address:
          </Typography.Title>
          {fullPickupAddress ? (
            <Typography.Paragraph style={styles.infoText}>
              {fullPickupAddress?.name}: {fullPickupAddress?.code} <br /> {fullPickupAddress?.street1}, {fullPickupAddress?.street2 ? fullPickupAddress.street2 + ',' : ''} {fullPickupAddress?.city}{' '}
              {fullPickupAddress?.subdivisionLocalCode ?? ''} {fullPickupAddress?.postalCode} {fullPickupAddress?.countryAlpha2Code}
            </Typography.Paragraph>
          ) : (
            <Button onClick={() => (id ? nav(`/edit/${id}/pickup-location`) : nav('/create/pickup-location'))} style={styles.selectAddressButton}>
              Select a Pickup Location
            </Button>
          )}
        </Col>
        {!isReturnShipment && (
          <Col span={12}>
            <Typography.Title level={5} style={{ color: values.requestedPickupDate ? 'black' : toRgb(colors.redSalsa) }}>
              Pickup Date/Time:
            </Typography.Title>
            {values.requestedPickupDate && values.requestedPickupTime && (
              <Typography.Paragraph style={styles.infoText}>
                {dayjs(values?.requestedPickupDate).format('MM-DD-YYYY')} {dayjs(values?.requestedPickupTime, 'HH:mm').format('HH:mm')}
              </Typography.Paragraph>
            )}
            {!values.requestedPickupDate && values.requestedPickupTime && (
              <Button onClick={() => (id ? nav(`/edit/${id}`) : nav('/create'))} style={styles.selectDeliverDateButton}>
                Select a Pickup Date/Time
              </Button>
            )}
          </Col>
        )}
      </Row>
    </ColoredCard>
  );
};

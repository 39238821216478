import { Col, Layout, Row } from 'antd';
import { InlineStylesModel } from 'models/InlineStylesModel';
import '../../styles/loaderStyle.less';

const { Content } = Layout;

const styles: InlineStylesModel = {
  content: {
    textAlign: 'center',
    paddingTop: '50px'
  },
  layout: {
    width: '100vw',
    height: 20
  },
  mainRow: {
    height: 10
  }
};

export const LoadingMore = (): JSX.Element => {
  return (
    <Layout style={styles.layout}>
      <Content style={styles.content}>
        <Row justify="center" align="middle" style={styles.mainRow}>
          <Col span={24}>
            <div className="loader loader--slideUp">
              <span className="loader-item">1</span>
              <span className="loader-item">2</span>
              <span className="loader-item">3</span>
              <span className="loader-item">4</span>
              <span className="loader-item">5</span>
              <span className="loader-item">6</span>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Row, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { AddVendorQuoteButton } from 'components/atoms/AddVendorQuoteButton';
import { DeleteVendorQuoteButton } from 'components/atoms/DeleteVendorQuoteButton';
import { EditVendorQuoteButton } from 'components/atoms/EditVendorQuoteButton';
import { OpenEquipmentReturn, VendorQuote } from 'models/EquipmentReturn';
import { useParams } from 'react-router-dom';
import { useGetEquipmentReturnQuery } from 'redux/services/barkley/barkleyApi';
import { useAppSelector } from 'redux/store';

export const VendorQuotesSection = (): JSX.Element => {
  const { selectedDivisionId } = useAppSelector((state) => state.app);
  const { id } = useParams();
  const { data, isFetching } = useGetEquipmentReturnQuery({ divisionId: selectedDivisionId as string, equipmentReturnId: id as string }, { skip: !id || !selectedDivisionId });

  const openData = data as OpenEquipmentReturn;

  const cols: ColumnsType<VendorQuote> = [
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor'
    },
    {
      title: 'Quote Date',
      dataIndex: 'quoteDate',
      key: 'quoteDate',
      align: 'center',
      render: (text) => new Date(text).toLocaleDateString()
    },
    {
      title: 'Quote Amount',
      dataIndex: 'quoteAmount',
      key: 'quoteAmount',
      align: 'right',
      render: (text) => `$${text.toFixed(2)}`
    },
    {
      title: 'Markup',
      dataIndex: 'markupPercentage',
      key: 'markupPercentage',
      align: 'right',
      render: (text) => `${text}%`
    },
    {
      title: 'Freight Price',
      align: 'right',
      render: (_, record): JSX.Element => {
        const price = record.quoteAmount / (1 - record.markupPercentage / 100);

        return <Typography.Text>{`$${price.toFixed(2)}`}</Typography.Text>;
      }
    },
    {
      title: 'Attachment',
      align: 'center',
      render: (_, record): JSX.Element => {
        if (!record.attachment) {
          return <></>;
        }

        return (
          <a href={record?.attachment?.url} target="_blank">
            {record.attachment.name}
          </a>
        );
      }
    },
    {
      title: 'Is Awarded',
      dataIndex: 'isAwarded',
      key: 'isAwarded',
      align: 'center',
      render: (text) => (text ? <CheckCircleOutlined style={{ color: 'green' }} /> : <CloseCircleOutlined style={{ color: 'red' }} />)
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (text, record, idx) => (
        <Space>
          <EditVendorQuoteButton index={idx} quoteId={record.id} attachment={record.attachment} />
          <DeleteVendorQuoteButton quoteId={record.id} />
        </Space>
      )
    }
  ];

  return (
    <>
      <Row justify={'end'} style={{ marginTop: 16 }}>
        <AddVendorQuoteButton />
      </Row>
      <Table size="small" pagination={false} style={{ marginTop: 16 }} dataSource={openData?.vendorQuotes} columns={cols} />
    </>
  );
};

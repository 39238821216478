import { EditOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Spin, Tooltip } from 'antd';
import { Loader } from 'components/common/Loader';
import { VendorQuoteForm } from 'components/molecules/VendorQuoteForm';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { OpenEquipmentReturnPayload, VendorAttachment, VendorQuote, VendorQuotePayload, vendorQuotePayloadSchema } from 'models/EquipmentReturn';
import { QueryErrorModel } from 'models/ErrorModel';
import { ColorCard } from 'pages/Create/ColorCard';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUpdateEquipmentReturnDispatcherMutation, useUpdateVendorQuoteMutation } from 'redux/services/barkley/barkleyApi';
import { useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';

type Props = {
  index: number;
  quoteId: string;
  attachment?: VendorAttachment;
};

export const EditVendorQuoteButton: FC<Props> = ({ index, quoteId, attachment }) => {
  const [open, setIsOpen] = useState(false);
  const { values: erVals } = useFormikContext<OpenEquipmentReturnPayload>();

  const { selectedDivisionId } = useAppSelector((state) => state.app);
  const { id } = useParams();

  const [{ value }] = useField<VendorQuote>(`vendorQuotes[${index}]`);

  const [updateVendorQuote, { isLoading }] = useUpdateVendorQuoteMutation();
  const [updateReturn, { isLoading: isUpdating }] = useUpdateEquipmentReturnDispatcherMutation({ fixedCacheKey: 'waybill' });

  const formik = useFormik<VendorQuotePayload>({
    enableReinitialize: true,
    validationSchema: vendorQuotePayloadSchema,
    initialValues: {
      fileBase64: '',
      fileName: value?.attachment?.name ?? '',
      vendor: value?.vendor ?? '',
      quoteDate: value?.quoteDate ?? '',
      quoteAmount: value?.quoteAmount ?? 0,
      markupPercentage: value?.markupPercentage ?? 40,
      freightPrice: value?.freightPrice ?? 0.0,
      isAwarded: value?.isAwarded ?? false,
      didAttachmentFileChange: false
    },
    onSubmit: async (values) => {
      if (!selectedDivisionId || !id || !quoteId) {
        notification.error({ message: 'Error', description: 'Division ID or Equipment Return ID is missing' });

        return;
      }
      try {
        await updateVendorQuote({ divisionId: selectedDivisionId, equipmentReturnId: id, quoteId, payload: values }).unwrap();
        await updateReturn({ divisionId: selectedDivisionId, equipmentReturnId: id, body: erVals }).unwrap();

        if (values.isAwarded) {
          const payload: OpenEquipmentReturnPayload = {
            ...erVals,
            freightCompany: values.vendor
          };

          await updateReturn({ divisionId: selectedDivisionId, equipmentReturnId: id, body: payload }).unwrap();
        }
        setIsOpen(false);
        formik.resetForm();
        notification.success({ message: 'Success', description: 'Vendor Quote updated successfully' });
      } catch (e) {
        console.error(e, 'error');

        const error = e as QueryErrorModel;

        const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occurred, the team has been notified.';

        notification.error({
          message: 'Error',
          description: errorMessage,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 10
        });
      }
    }
  });

  return (
    <FormikProvider value={formik}>
      <Tooltip title="Edit">
        <Button style={{ border: '1px solid orange' }} size="small" onClick={() => setIsOpen(true)} icon={<EditOutlined style={{ color: 'orange' }} />} />
      </Tooltip>
      <Modal
        destroyOnClose
        okText={'Submit'}
        open={open}
        closable={false}
        onCancel={(): void => {
          setIsOpen(false);
          formik.resetForm();
        }}
        onOk={(): void => {
          formik.submitForm();
        }}
        width={450}
        closeIcon={undefined}>
        <ColorCard color={toRgba(colors.metallicGold, 0.4)} title={'Edit Vendor Quote'}>
          <Spin spinning={isLoading} indicator={<Loader medium loadingMessage="Updating vendor quote..." />}>
            <VendorQuoteForm isEdit attachment={attachment} />
          </Spin>
        </ColorCard>
      </Modal>
    </FormikProvider>
  );
};

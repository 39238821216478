import { Button, Col, Row, Space, Tooltip, Typography } from 'antd';
import { AddDeliveryAddressModal } from 'components/createDeliveryAddress/AddDeliveryAddressModal';
import { useFormikContext } from 'formik';
import { EquipmentReturnPayload } from 'models/EquipmentReturn';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useState } from 'react';
import { useGetEquipmentReturnConfigurationTypesQuery } from 'redux/services/maurice/sparingRequestConfigTypes';
import { useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';
import { LocationsFilters } from '../common';
const { Text, Title } = Typography;

const styles: InlineStylesModel = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: toRgba(colors.royalBlueLight, 0.4),
    justifyContent: 'space-between',
    width: '100%',
    padding: 24,
    borderBottom: '2px solid rgba(0,0,0,.1)',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },
  title: {
    margin: 0,
    fontWeight: 'normal'
  },
  nextButton: {
    margin: 'auto',
    borderRadius: '5px'
  }
};

export const HeaderControls = (): JSX.Element => {
  const [showAddAddressModal, _setShowAddAddressModal] = useState(false);
  const { values } = useFormikContext<EquipmentReturnPayload>();

  const { acuityContext } = useAppSelector((state) => state.app);
  const divisionId = acuityContext?.selectedCustomer.id;

  const { data: typesData } = useGetEquipmentReturnConfigurationTypesQuery({ divisionId: divisionId as string, params: {} }, { skip: !divisionId });
  const selectedType = typesData?.data.find((type) => type.id === values.equipmentReturnTypeId);

  return (
    <Row align="middle" style={styles.container}>
      <Col span={6}>
        <Space style={{ flex: 1, justifyContent: 'center' }} size={0} direction="vertical">
          <Title level={4} style={styles.title}>
            Search for Pickup Address
          </Title>
        </Space>
      </Col>
      <Col span={18} style={{ textAlign: 'right' }}>
        <Space>
          <LocationsFilters />
          <Tooltip title={!selectedType?.allowManualAddressEntry ? 'Selected Type is not configured to allow adding manual addresses' : ''}>
            <Button type="primary" disabled={!selectedType?.allowManualAddressEntry} style={styles.addAddressButton} onClick={(): void => _setShowAddAddressModal(true)}>
              Add Manual Address
            </Button>
          </Tooltip>

          <AddDeliveryAddressModal
            addressTypes={['Install']}
            show={showAddAddressModal}
            key={1}
            onClose={(): void => {
              _setShowAddAddressModal(false);
            }}
          />
        </Space>
      </Col>
    </Row>
  );
};

import { Button, Card, Col, Row, Space, Spin, Typography } from 'antd';
import { Loader } from 'components/common/Loader';
import { FieldInputTextArea } from 'components/Create/FormItems/FieldInputTextArea';
import { SubscribeInput, SubscriberList } from 'components/Create/MoreDetails';
import { ContactInfoSwitch } from 'components/Create/ShippingDetails';
import { useFormikContext } from 'formik';
import { DeploymentRequestLabels } from 'models/DeploymentRequest';
import { EquipmentReturnPayload, PickupAddress } from 'models/EquipmentReturn';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetWarehouseRoutingQuery } from 'redux/services/barkley/barkleyApi';
import { useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';
import { FieldInput } from './FormItems/FieldInput';
const { Title } = Typography;

export const ContactInfoPage = (): JSX.Element => {
  const { values } = useFormikContext<EquipmentReturnPayload>();
  const { id } = useParams();
  const { selectedDivisionId, acuityContext, isInternalUser } = useAppSelector((state) => state.app);

  const nav = useNavigate();
  const { pathname } = useLocation();
  const isReturnShipment = values.equipmentReturnTypeName === 'ReturnShipment';

  const divisionId = isInternalUser ? selectedDivisionId : acuityContext?.selectedCustomer.id;
  const isCreate = pathname.includes('create');
  const isInternalCreate = isInternalUser && isCreate;
  const divIdToPass = isInternalCreate ? values.divisionId : divisionId;

  const { data, isLoading, isFetching, isError, error } = useGetWarehouseRoutingQuery(
    { pickupAddress: values.pickupAddress as PickupAddress, equipmentReturnTypeId: values.equipmentReturnTypeId, divisionId: divIdToPass as string, bypassAddress: false },
    { skip: !values.pickupAddress || values.pickupAddress === null }
  );

  const isEdit = pathname.includes('edit');

  return (
    <Row gutter={[12, 12]}>
      {!isReturnShipment && (
        <Col span={12}>
          <Card
            styles={{ header: { backgroundColor: toRgba(colors.robinEggBlue, 0.4) } }}
            title={
              <Row justify="space-between">
                <Col>
                  <Title level={4} style={{ fontWeight: 'normal', margin: 0, padding: 0 }}>
                    Site Contact Information
                  </Title>
                </Col>
                <Col>
                  <ContactInfoSwitch />
                </Col>
              </Row>
            }
            style={{ height: '100%' }}>
            <Row gutter={[0, 10]}>
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <FieldInput fieldName="siteContactName" style={{ marginBottom: 10 }} label={DeploymentRequestLabels.contactName} />
                    <FieldInput fieldName="siteContactPhone" style={{ marginBottom: 10 }} label={DeploymentRequestLabels.contactPhone} />
                    <FieldInput fieldName="siteContactEmail" style={{ marginBottom: 10 }} label={DeploymentRequestLabels.contactEmail} />
                  </Col>
                  <Col span={12}>
                    <FieldInputTextArea fieldName="notes" label="Site Specific Instructions" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      )}
      {isReturnShipment && (
        <Col span={12}>
          <Card
            styles={{ header: { backgroundColor: toRgba(colors.illuminatingEmerald, 0.4) } }}
            title={
              <Row justify="space-between">
                <Col>
                  <Title level={4} style={{ fontWeight: 'normal', margin: 0, padding: 0 }}>
                    Logistics Contact Information
                  </Title>
                </Col>
                <Col>
                  <ContactInfoSwitch isShipper />
                </Col>
              </Row>
            }
            style={{ height: '100%' }}>
            <Row gutter={[0, 10]}>
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <FieldInput fieldName="shipperBusinessName" style={{ marginBottom: 10 }} label={'Shipper Business Name'} />
                    <FieldInput fieldName="shipperContactName" style={{ marginBottom: 10 }} label={'Shipper Contact Name'} />
                    <FieldInput fieldName="shipperContactPhone" style={{ marginBottom: 10 }} label={'Shipper Contact Phone'} />
                  </Col>
                  <Col span={12}>
                    <FieldInputTextArea fieldName="notes" label="Notes" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      )}
      {isInternalUser && (
        <Col span={12}>
          <Card
            styles={{ header: { backgroundColor: toRgba(colors.romanSilver, 0.4) } }}
            title={
              <Row>
                <Col>
                  <Title level={4} style={{ fontWeight: 'normal', margin: 0, padding: 0 }}>
                    Warehouse Routing Information
                  </Title>
                </Col>
              </Row>
            }
            style={{ height: '100%' }}>
            <Spin spinning={isLoading || isFetching} indicator={<Loader medium />}>
              {values.pickupAddress && (
                <Row gutter={[0, 10]}>
                  <Col span={24}>
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <Space direction="vertical">
                          <Typography.Title style={{ margin: 0, padding: 0 }} level={5}>
                            Warehouse Name:
                          </Typography.Title>
                          <Typography.Text>{data?.warehouseFulfillment.warehouseId}</Typography.Text>
                        </Space>
                      </Col>
                      <Col span={8}>
                        <Space direction="vertical">
                          <Typography.Title style={{ margin: 0, padding: 0 }} level={5}>
                            Warehouse Address:
                          </Typography.Title>
                          <Typography.Text>
                            {data?.warehouseFulfillment?.street || 'na'} <br />
                            {data?.warehouseFulfillment?.city || 'na'}, {data?.warehouseFulfillment?.subdivisionCode || 'na'} {data?.warehouseFulfillment?.postalCode || 'na'} <br />
                            {data?.warehouseFulfillment?.countryCode || 'na'}
                          </Typography.Text>
                        </Space>
                      </Col>
                      <Col span={8}>
                        <Space direction="vertical">
                          <Typography.Title style={{ margin: 0, padding: 0 }} level={5}>
                            Warehouse Contact Info:
                          </Typography.Title>
                          <Typography.Text>
                            {values.warehouseContactName} <br />
                            {values.warehouseContactPhone} <br />
                          </Typography.Text>
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              {(!values.pickupAddress || values.pickupAddress === null) && (
                <Row style={{ height: '100%' }} justify={'center'} align={'middle'}>
                  <Button type="primary" onClick={(): void => nav(isEdit ? `/edit/${id}/pickup-location` : '/create/pickup-location')}>
                    Select Pickup Address
                  </Button>
                </Row>
              )}
            </Spin>
          </Card>
        </Col>
      )}

      <Col span={12}>
        <Card
          styles={{ header: { backgroundColor: toRgba(colors.orangeWeb, 0.4) } }}
          title={
            <Row justify="space-between">
              <Col>
                <Title level={4} style={{ fontWeight: 'normal', margin: 0, padding: 0 }}>
                  Email Notifications
                </Title>
              </Col>
            </Row>
          }
          style={{ height: '100%' }}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <SubscribeInput />
            </Col>
            <Col span={24}>
              <SubscriberList />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

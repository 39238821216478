/* eslint-disable jsx-a11y/label-has-associated-control */
import { FancySelect } from 'components/ui/Inputs';
import { useDispatch } from 'react-redux';
import { setEquipmentRuleType, setStatuses } from 'redux/services/barkley/barkleyParams';
import { useAppSelector } from 'redux/store';

export const EquipmentReturnTypeFilter = (): JSX.Element => {
  const dispatch = useDispatch();
  const { equipmentRuleType } = useAppSelector((state) => state.barkleyParams);

  const options = ['Return Shipment', 'Decommission', 'Equipment Pickup'].map((status) => ({ label: status, value: status.replace(/\s+/g, '') }));

  /* ****************** Functions ****************** */
  const handleStatusChange = (value: string): void => {
    dispatch(setEquipmentRuleType(value));
  };

  return <FancySelect value={equipmentRuleType} onChange={handleStatusChange} options={options} placeholder="Equipment Return Type" onClear={() => dispatch(setStatuses())} />;
};

/* eslint-disable jsx-a11y/tabindex-no-positive */
import { Col, Radio, Row, Typography } from 'antd';
import { FormikErrors, useFormikContext } from 'formik';
import { EquipmentReturnPayload } from 'models/EquipmentReturn';
import { FieldInputNumber } from 'pages/Create/FormItems/FieldInputNumber';

export const ReturnInfoFormOpen = (): JSX.Element => {
  const { setFieldValue, values } = useFormikContext<EquipmentReturnPayload>();

  return (
    <Row gutter={[0, 10]}>
      <Col span={24}>
        <Typography.Title style={{ margin: 0, padding: 0 }} level={5}>
          Equipment Details:
        </Typography.Title>
      </Col>
      <Col span={24}>
        <FieldInputNumber fieldName="numberOfPallets" label="Number of Pallets*" tabIndex={1} />
      </Col>
      <Col span={24}>
        <FieldInputNumber fieldName="totalWeight" label="Total Weight (lbs)*" tabIndex={2} />
      </Col>
      <Col span={24}>
        <FieldInputNumber fieldName="qtyOfEquipment" label="Qty of Equipment" tabIndex={3} />
      </Col>
      <Col span={24}>
        <FieldInputNumber fieldName="numberOfBoxes" label="Number of Boxes" tabIndex={4} />
      </Col>
      <Col span={24}>
        <Row align={'middle'} justify={'space-between'} style={{ height: '100%' }}>
          <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>
            Is the value of equipment greater than $100,000?
          </Typography.Title>
          <Radio.Group
            onChange={(event): Promise<void | FormikErrors<EquipmentReturnPayload>> => setFieldValue('isEquipmentValueOver100K', event.target.value)}
            value={values.isEquipmentValueOver100K}>
            <Radio tabIndex={5} value={true}>
              Yes
            </Radio>
            <Radio tabIndex={6} value={false}>
              No
            </Radio>
          </Radio.Group>
        </Row>
      </Col>
    </Row>
  );
};

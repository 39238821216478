import { HistoryOutlined } from '@ant-design/icons';
import { Button, Drawer, List } from 'antd';
import dayjs from 'dayjs';
import { FulfillmentStatusHistory } from 'models/EquipmentReturn';
import { FC, useState } from 'react';
import { colors, toRgba } from 'styles/colors';

type Props = {
  statusHistory: FulfillmentStatusHistory[];
};

export const StatusHistoryButton: FC<Props> = ({ statusHistory }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button icon={<HistoryOutlined />} onClick={(): void => setIsOpen(true)} style={{ border: `1px solid ${toRgba(colors.robinEggBlue, 1)}`, color: toRgba(colors.robinEggBlue, 1) }}>
        Status History
      </Button>
      <Drawer title="Fulfillment Status History" width={700} open={isOpen} onClose={(): void => setIsOpen(false)}>
        <List
          dataSource={statusHistory}
          bordered
          renderItem={(item): JSX.Element => (
            <List.Item>{`${item.createdByUserFullName} changed the status to ${item.status} on ${dayjs(item.createdDateTime).format('MM-DD-YYYY')} at ${dayjs(item.createdDateTime).format(
              'h:mm A'
            )}`}</List.Item>
          )}
        />
      </Drawer>
    </>
  );
};

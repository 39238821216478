import { Row, Space, Typography } from 'antd';
import { FC } from 'react';
import Lottie from 'react-lottie-player';

interface Props {
  message: string;
  animationData: object;
}

export const WaitToSearch: FC<Props> = ({ message, animationData }) => {
  return (
    <Row justify="center" align={'middle'}>
      <Space direction="vertical" align="center">
        <Lottie style={{ width: 275, height: 275 }} animationData={animationData} loop play />
        <Row justify="center">
          <Typography.Text style={{ fontSize: 20 }}>{message}</Typography.Text>
        </Row>
      </Space>
    </Row>
  );
};

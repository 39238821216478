import { Button } from 'antd';
import { useFormikContext } from 'formik';
import { SparingRequestPayload } from 'models/SparingRequests';
import { FC } from 'react';
import { useAppSelector } from 'redux/store';

type Props = {
  isRequestor?: boolean;
  isShipper?: boolean;
};

export const ContactInfoSwitch: FC<Props> = ({ isRequestor = false, isShipper = false }) => {
  const { user } = useAppSelector((state) => state.app);
  const { handleChange } = useFormikContext<SparingRequestPayload>();

  const handleSetMyContactInfo = (): void => {
    if (isShipper) {
      handleChange('shipperContactName')(user?.name || '');
      handleChange('shipperContactPhone')(user?.['https://acuity.mdsiinc.com/user/user_metadata'].phone_number);

      return;
    }
    if (isRequestor) {
      handleChange('requestorName')(user?.name || '');
      handleChange('requestorPhone')(user?.['https://acuity.mdsiinc.com/user/user_metadata'].phone_number);
      handleChange('requestorEmail')(user?.email || '');

      return;
    }
    handleChange('siteContactName')(user?.name || '');
    handleChange('siteContactPhone')(user?.['https://acuity.mdsiinc.com/user/user_metadata'].phone_number);
    handleChange('siteContactEmail')(user?.email || '');
  };

  return (
    <Button style={{ borderRadius: 5 }} onClick={(): void => handleSetMyContactInfo()}>
      Use My Contact Info
    </Button>
  );
};

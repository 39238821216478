import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, notification, Row, Space, Spin, Typography } from 'antd';
import { GenerateWaybillButtonCustomer } from 'components/atoms/GenerateWaybillButtonCustomer';
import { StatusHistoryButton } from 'components/atoms/StatusHistoryButton';
import { Loader } from 'components/common/Loader';
import WimsicalError from 'components/common/WimsicalError/WimsicalError';
import { AttachmentsContent } from 'components/Create/ReviewOrder/AttachmentsContent';
import dayjs from 'dayjs';
import { formatErrorMessage } from 'helpers/formatErrorMessage';
import { EquipmentReturnPayload, OpenEquipmentReturn } from 'models/EquipmentReturn';
import { QueryErrorModel } from 'models/ErrorModel';
import { ColorCard } from 'pages/Create/ColorCard';
import { PackageInformationCustomer } from 'pages/Defective/PackageInformationCustomer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetEquipmentReturnQuery, useUpdateEquipmentReturnMutation } from 'redux/services/barkley/barkleyApi';
import { useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';

const { Title } = Typography;

export const PostedPage = (): JSX.Element => {
  const { id } = useParams();
  const { selectedDivisionId } = useAppSelector((state) => state.app);

  const { data, isLoading, isFetching, isError, error } = useGetEquipmentReturnQuery(
    { divisionId: selectedDivisionId as string, equipmentReturnId: id as string },
    { skip: !id || !selectedDivisionId }
  );
  const openData = data as OpenEquipmentReturn;

  const isReturnShipment = openData?.equipmentReturnType?.name === 'Return Shipment';

  const { pathname } = useLocation();

  const [updateReturn, { isLoading: isUpdating }] = useUpdateEquipmentReturnMutation({ fixedCacheKey: 'waybill' });

  const nav = useNavigate();

  const isCancelled = openData?.returnStatus === 'Cancelled';

  const handleCancel = async (): Promise<void> => {
    const payload: EquipmentReturnPayload = {
      ...(openData as EquipmentReturnPayload),
      returnStatus: 'Cancelled',
      equipmentReturnTypeId: openData?.equipmentReturnType.id ?? ''
    };

    try {
      const response = await updateReturn({ divisionId: selectedDivisionId, equipmentReturnId: id as string, body: payload }).unwrap();

      notification.success({
        message: 'Success',
        description: `Equipment return: ${response.referenceNumber} has been cancelled successfully.`,
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 10
      });
      nav('/');
    } catch (e) {
      console.error(e, 'error');

      const error = e as QueryErrorModel;
      const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occurred, the team has been notified.';

      notification.error({
        message: 'Error',
        description: errorMessage,
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 5
      });
    }
  };

  if (isLoading) return <Loader loadingMessage="Loading equipment return..." />;

  if (isError) {
    const err = error as QueryErrorModel;

    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row justify={'space-between'} style={{ width: '100%' }}>
          <Col>
            <Typography.Title level={4} style={{ margin: 0, padding: 0 }}>
              Equipment Return Information
            </Typography.Title>
          </Col>
          <Col>
            <Space>
              <Button onClick={(): void => nav('/')} icon={<ArrowLeftOutlined />}>
                Back to Search
              </Button>
            </Space>
          </Col>
        </Row>
        <WimsicalError title={formatErrorMessage(typeof err.status === 'number' ? err.status : 400)} subTitle={err?.data?.errorMessage ? err.data.errorMessage : ''} statusCode={err.status} />
      </Space>
    );
  }

  return (
    <Spin indicator={<Loader loadingMessage={'Updating equipment return...'} />} spinning={isUpdating}>
      <Row style={{ width: '100%' }}>
        <Space direction="vertical" style={{ width: '100%' }} size={10}>
          <Row justify={'space-between'} style={{ width: '100%' }}>
            <Col>
              <Typography.Title level={4} style={{ margin: 0, padding: 0 }}>
                Equipment Return Information
              </Typography.Title>
            </Col>
            <Col>
              <Space>
                <Button onClick={(): void => nav('/')} icon={<ArrowLeftOutlined />}>
                  Back to Search
                </Button>
                <StatusHistoryButton statusHistory={data?.fulfillmentStatusHistory ?? []} />
                {isReturnShipment && !isCancelled && <GenerateWaybillButtonCustomer openData={openData} />}
                {!isCancelled && (
                  <Button onClick={handleCancel} danger>
                    Cancel
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <ColorCard title={'Reference Information'} color={toRgba(colors.royalBlueLight, 0.4)}>
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      Equipment Return Order Number
                    </Title>
                    <Typography.Text>{openData?.returnOrderNumber ?? 'RET0000N'}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      Equipment Return Name
                    </Title>
                    <Typography.Text>{openData?.equipmentReturnName}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      Reference Number
                    </Title>
                    <Typography.Text>{openData?.referenceNumber}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      Equipment Return Type
                    </Title>
                    <Typography.Text>{openData?.equipmentReturnType.name}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      Return Status
                    </Title>
                    <Typography.Text>{openData?.returnStatus}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      Requestor Name
                    </Title>
                    <Typography.Text>{openData?.requestorName}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      Requestor Email
                    </Title>
                    <Typography.Text>{openData?.requestorEmail}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      Requestor Phone Number
                    </Title>
                    <Typography.Text>{openData?.requestorPhone}</Typography.Text>
                  </Col>
                </Row>
              </ColorCard>
            </Col>
            <Col span={12}>
              <ColorCard title={'Logistics'} color={toRgba(colors.robinEggBlue, 0.4)}>
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      Pickup Address
                    </Title>
                    <Typography.Text>
                      {openData?.pickupAddress?.street1} {openData?.pickupAddress?.city} {openData?.pickupAddress?.subdivisionLocalCode} {openData?.pickupAddress?.countryAlpha2Code}
                    </Typography.Text>
                  </Col>
                  {!isReturnShipment && (
                    <>
                      {' '}
                      <Col span={8}>
                        <Title style={{ margin: 0, padding: 0 }} level={5}>
                          Pickup Date/Time
                        </Title>
                        <Typography.Text>
                          {dayjs(openData?.requestedPickupDate).format('MM-DD-YYYY')} {dayjs(openData?.requestedPickupTime, 'HH:mm').format('HH:mm')}
                        </Typography.Text>
                      </Col>
                      <Col span={8}>
                        <Title style={{ margin: 0, padding: 0 }} level={5}>
                          Scheduled Date/Time
                        </Title>
                        <Typography.Text>
                          {dayjs(openData?.scheduledPickupDate).format('MM-DD-YYYY')} {openData?.scheduledPickupTime ? dayjs(openData?.scheduledPickupTime, 'HH:mm').format('HH:mm') : 'N/A'}
                        </Typography.Text>
                      </Col>
                    </>
                  )}
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      Warehouse Routing Priority
                    </Title>
                    <Typography.Text>{openData?.warehouseRoutingFulfillment?.warehouseId}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      Warehouse Contact Name
                    </Title>
                    <Typography.Text>{openData?.warehouseContactName || 'TEST NAME'}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      Warehouse Contact Phone
                    </Title>
                    <Typography.Text>{openData?.warehouseContactPhone || '1234567'}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      Fulfillment Status
                    </Title>
                    <Typography.Text>{openData?.fulfillmentStatus || 'Processing'}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      Freight Company
                    </Title>
                    <Typography.Text>{openData?.freightCompany || 'FEDEX'}</Typography.Text>
                  </Col>
                </Row>
              </ColorCard>
            </Col>
            <Col span={12}>
              <ColorCard title={'General Information'} color={toRgba(colors.redSalsa, 0.4)}>
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      Created By
                    </Title>
                    <Typography.Text>{openData?.createdByFullName}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      Last Modified By
                    </Title>
                    <Typography.Text>{openData?.modifiedByFullName}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      Created Date
                    </Title>
                    <Typography.Text>{dayjs(openData?.createdDateTime).format('MM-DD-YYYY: HH:mm')}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      {isReturnShipment ? 'Shipper Contact Name' : 'Site Contact Name'}
                    </Title>
                    <Typography.Text>{isReturnShipment ? openData?.shipperContactName : openData?.siteContactName}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      {isReturnShipment ? 'Shipper Business Name' : 'Site Contact Email'}
                    </Title>
                    <Typography.Text>{isReturnShipment ? openData?.shipperBusinessName : openData?.siteContactEmail}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      {isReturnShipment ? 'Shipper Contact Phone' : 'Site Contact Phone'}
                    </Title>
                    <Typography.Text>{isReturnShipment ? openData?.shipperContactPhone : openData?.siteContactPhone}</Typography.Text>
                  </Col>

                  <Col span={8}>
                    <Title style={{ margin: 0, padding: 0 }} level={5}>
                      Notes
                    </Title>
                    <Typography.Text>{openData?.notes}</Typography.Text>
                  </Col>
                </Row>
              </ColorCard>
            </Col>
            {!!openData.packages.length && isReturnShipment && (
              <Col span={12}>
                <PackageInformationCustomer isPosted packages={openData.packages} />
              </Col>
            )}
            {!!openData?.attachments.length && (
              <Col span={12}>
                <AttachmentsContent />
              </Col>
            )}
          </Row>
        </Space>
      </Row>
    </Spin>
  );
};

export const formatErrorMessage = (status: number): string => {
  switch (status) {
    case 404:
      return 'Oops! Page not found';

    case 400:
      return 'Bad request. The team has been notified. Please refresh the page or try again later';

    case 403:
      return 'You are not authorized to access this page';

    case 409:
      return 'Conflict, the team has been notified. Please refresh the page or try again later';

    case 500:
      return 'Internal server error. The team has been notified. Please refresh the page or try again later';

    case 502:
      return 'Internal server error. The team has been notified. Please refresh the page or try again later';

    case 503:
      return 'Internal server error. The team has been notified. Please refresh the page or try again later';

    default:
      return 'Error, the team has been notified. Please refresh the page or try again later';
  }
};

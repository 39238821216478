import { About } from 'pages/About';
import { ContactInfoPage, CreateReturnLayout, PickupAddressPage, ReferenceInfo, RequestInfoPage, ReviewOrder } from 'pages/Create';
import { PackageInformationStep } from 'pages/Create/PackageInformationStep';
import { EditDeploymentRequestLayout } from 'pages/Edit';
import { DispatcherPage } from 'pages/Edit/DispatcherPage';
import { PostedPage } from 'pages/Edit/PostedPage';
import { EditProfilePage } from 'pages/EditProfile';
import { RecoverPage } from 'pages/Recover';
import { ReleaseNotesPage } from 'pages/releaseNotes/ReleaseNotesPage';
import { SearchPage } from 'pages/Search';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<SearchPage />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/inquiry" element={<SearchPage />} />
      <Route path="/dispatch" element={<SearchPage />} />
      <Route path="/about" element={<About />} />
      <Route path="/profile" element={<EditProfilePage />} />
      <Route path="/details/:id" element={<PostedPage />} />
      <Route path="/manage/:id" element={<DispatcherPage />} />
      <Route path="/release-notes/:version" element={<ReleaseNotesPage />} />
      <Route path="create" element={<CreateReturnLayout />}>
        <Route index element={<ReferenceInfo />} />
        <Route path="pickup-location" element={<PickupAddressPage />} />
        <Route path="request-info" element={<RequestInfoPage />} />
        <Route path="contact-info" element={<ContactInfoPage />} />
        <Route path="review-order" element={<ReviewOrder />} />
        <Route path="package-information" element={<PackageInformationStep />} />
      </Route>
      <Route path="edit/:id" element={<EditDeploymentRequestLayout />}>
        <Route index element={<ReferenceInfo />} />
        <Route path="pickup-location" element={<PickupAddressPage />} />
        <Route path="request-info" element={<RequestInfoPage />} />
        <Route path="contact-info" element={<ContactInfoPage />} />
        <Route path="review-order" element={<ReviewOrder />} />
        <Route path="package-information" element={<PackageInformationStep />} />
      </Route>
      <Route path="recover" element={<RecoverPage />} />
    </Routes>
  );
};

import { DeleteOutlined, MailOutlined } from '@ant-design/icons';
import { Button, List, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { EquipmentReturnPayload } from 'models/EquipmentReturn';
import { InlineStylesModel } from 'models/InlineStylesModel';

const styles: InlineStylesModel = {
  container: {
    display: 'flex',
    gap: 8,
    width: '100%',
    flexDirection: 'column'
  },
  emailRecord: {
    display: 'flex',
    alignItems: 'center',
    gap: 8
  }
};

export const SubscriberList = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<EquipmentReturnPayload>();

  const handleRemoveNotificationEmail = (value: string): void => {
    setFieldValue(
      'emailNotificationList' as keyof DeploymentRequestPayload,
      values.emailNotificationList.filter((email) => email !== value)
    );
  };

  return (
    <List
      bordered
      dataSource={values.emailNotificationList}
      renderItem={(email): JSX.Element => (
        <List.Item style={{ padding: 5 }} actions={[<Button key="delete-item" danger onClick={(): void => handleRemoveNotificationEmail(email)} icon={<DeleteOutlined />} />]}>
          <MailOutlined style={{ fontSize: 16, marginRight: 8 }} />
          <Typography.Text style={{ flex: 1 }}>{email}</Typography.Text>
        </List.Item>
      )}
    />
  );
};

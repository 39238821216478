import { Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React from 'react';

const styles: InlineStylesModel = {
  formLabel: {
    marginBottom: 0,
    paddingBottom: 0,
    color: 'rgba(0,0,0,0.85)'
  }
};

interface Props extends TitleProps {
  label: string;
}

export const FormLabel: React.FC<Props> = ({ label, ...rest }) => {
  return (
    <Typography.Text {...rest} style={styles.formLabel}>
      {label}
    </Typography.Text>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SparingRequestConfiguration } from 'models/SparingRequestConfig';
import { SparingAddress } from 'models/SparingRequests';
import { TimeZoneData } from 'models/Timezone';
import moment from 'moment-timezone';

interface InitialState {
  productsView: 'cart' | 'grid' | 'fav';
  nextBusinessDayValue: number | null;
  fullOutageAddress: SparingAddress | null;
  fullShippingAddress: SparingAddress | null;
  sparingConfig?: SparingRequestConfiguration;
  isExit: boolean;
  isPost: boolean;
  isTrackingOpen: boolean;
  dateFormat: { timeZone: string; estimatedDeliveryDate: string; sparingOrder: string; modifiedDateTime: string }[];
  isLocalTimeZone: boolean;
  deliveryAddressTimeZoneInfo?: TimeZoneData;
  isDrawerOpen: boolean;
}

export type DateToggleState = { timeZone: string; estimatedDeliveryDate: string; sparingOrder: string; modifiedDateTime: string };

const initialState: InitialState = {
  productsView: 'grid',
  nextBusinessDayValue: null,
  fullOutageAddress: null,
  fullShippingAddress: null,
  sparingConfig: undefined,
  isExit: false,
  isPost: false,
  isTrackingOpen: false,
  dateFormat: [],
  isLocalTimeZone: false,
  deliveryAddressTimeZoneInfo: undefined,
  isDrawerOpen: false
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setProductsView: (state, { payload }: PayloadAction<InitialState['productsView']>) => {
      state.productsView = payload;
    },
    setFullOutageAddress: (state, { payload }: PayloadAction<InitialState['fullOutageAddress']>) => {
      state.fullOutageAddress = payload;
    },
    setFullShippingAddress: (state, { payload }: PayloadAction<InitialState['fullShippingAddress']>) => {
      state.fullShippingAddress = payload;
    },
    setSparingConfig: (state, { payload }: PayloadAction<InitialState['sparingConfig']>) => {
      state.sparingConfig = payload;
    },
    setIsExit: (state, { payload }: PayloadAction<InitialState['isExit']>) => {
      state.isExit = payload;
    },
    setIsPost: (state, { payload }: PayloadAction<InitialState['isPost']>) => {
      state.isPost = payload;
    },
    setIsDrawerOpen: (state, { payload }: PayloadAction<InitialState['isDrawerOpen']>) => {
      state.isDrawerOpen = payload;
    },
    setIsTrackingOpen: (state, { payload }: PayloadAction<InitialState['isTrackingOpen']>) => {
      state.isTrackingOpen = payload;
    },
    setNextBusinessDayValue: (state, { payload }: PayloadAction<InitialState['nextBusinessDayValue']>) => {
      state.nextBusinessDayValue = payload;
    },
    seetDateFormat: (state, { payload }: PayloadAction<InitialState['dateFormat']>) => {
      state.dateFormat = payload;
    },
    setDeliveryAddressTimeZoneInfo: (state, { payload }: PayloadAction<InitialState['deliveryAddressTimeZoneInfo']>) => {
      state.deliveryAddressTimeZoneInfo = payload;
    },
    toggleDateFormat: (state, { payload }: { payload: { timeZone?: string; estimatedDeliveryDate: string; index: number; modifiedDateTime: string } }) => {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const localTimeZoneDeliveryDate = moment.tz(payload.estimatedDeliveryDate ?? '', timeZone).format('MM/DD/YYYY hh:mm A');
      const localTimeZoneModifiedDate = moment.tz(payload.modifiedDateTime ?? '', timeZone).format('MM/DD/YYYY hh:mm A');
      const timeZoneAbbreviation = moment.tz(timeZone).format('z');

      if (payload?.timeZone === 'UTC') {
        state.dateFormat[payload.index] = {
          ...state.dateFormat[payload.index],
          timeZone: 'UTC',
          estimatedDeliveryDate: moment(payload.estimatedDeliveryDate).utc().format('MM/DD/YYYY HH:mm'),
          modifiedDateTime: moment(payload.modifiedDateTime).utc().format('MM/DD/YYYY HH:mm')
        };

        return;
      }

      state.dateFormat[payload.index] = {
        ...state.dateFormat[payload.index],
        timeZone: timeZoneAbbreviation,
        estimatedDeliveryDate: localTimeZoneDeliveryDate,
        modifiedDateTime: localTimeZoneModifiedDate
      };
    }
  }
});

export const {
  setProductsView,
  setNextBusinessDayValue,
  setFullOutageAddress,
  setFullShippingAddress,
  setSparingConfig,
  setIsExit,
  setIsPost,
  setIsTrackingOpen,
  toggleDateFormat,
  seetDateFormat,
  setIsDrawerOpen,
  setDeliveryAddressTimeZoneInfo
} = formSlice.actions;

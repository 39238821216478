import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErParams } from 'models/EquipmentReturn';
const initialState: ErParams = {
  offset: 0,
  referenceIdContains: undefined,
  requesterNameContains: undefined,
  referenceNumberContains: undefined,
  salesOrderNumberContains: undefined,
  countryContains: undefined,
  cityContains: undefined,
  stateContains: undefined,
  endDate: undefined,
  startDate: undefined,
  subdivisionLocalCodeEquals: undefined,
  countryAlpha2CodeEquals: undefined,
  orderByDirection: 'descending',
  statuses: 'Open,Draft',
  divisionId: undefined,
  equipmentRuleType: undefined
};

export const barkleyParams = createSlice({
  name: 'barkleyParams',
  initialState,
  reducers: {
    setOffset: (state, { payload }: PayloadAction<ErParams['offset']>) => {
      state.offset = payload;
    },
    incrementOffset: (state) => {
      state.offset += 25;
    },
    setRequester: (state, { payload }: PayloadAction<ErParams['requesterNameContains']>) => {
      state.requesterNameContains = payload;
      state.offset = 0;
    },
    setReferenceNumberContains: (state, { payload }: PayloadAction<ErParams['referenceNumberContains']>) => {
      state.referenceNumberContains = payload;
      state.offset = 0;
    },
    setReferenceIdContains: (state, { payload }: PayloadAction<ErParams['referenceIdContains']>) => {
      state.referenceIdContains = payload;
      state.offset = 0;
    },
    setCountryContains: (state, { payload }: PayloadAction<ErParams['countryContains']>) => {
      state.countryContains = payload;
      state.offset = 0;
    },
    setOrderNumber: (state, { payload }: PayloadAction<ErParams['salesOrderNumberContains']>) => {
      state.salesOrderNumberContains = payload;
      state.offset = 0;
    },
    setCityContains: (state, { payload }: PayloadAction<ErParams['cityContains']>) => {
      state.cityContains = payload;
      state.offset = 0;
    },
    setSubdivisionLocalCodeEquals: (state, { payload }: PayloadAction<ErParams['subdivisionLocalCodeEquals']>) => {
      state.subdivisionLocalCodeEquals = payload;
      state.offset = 0;
    },
    setCountryAlpha2CodeEquals: (state, { payload }: PayloadAction<ErParams['countryAlpha2CodeEquals']>) => {
      state.countryAlpha2CodeEquals = payload;
      state.offset = 0;
    },
    setRangeValues: (state, action) => {
      state.startDate = action.payload ? action.payload[0] : undefined;
      state.endDate = action.payload ? action.payload[1] : undefined;
      state.offset = 0;
    },
    resetRangeValues: (state) => {
      state.startDate = undefined;
      state.endDate = undefined;
      state.offset = 0;
    },
    setStatuses: (state, { payload }: PayloadAction<ErParams['statuses']>) => {
      state.statuses = payload;
      state.offset = 0;
    },
    setEquipmentRuleType: (state, { payload }: PayloadAction<ErParams['equipmentRuleType']>) => {
      state.equipmentRuleType = payload;
      state.offset = 0;
    },
    setDivisionId: (state, { payload }: PayloadAction<ErParams['divisionId']>) => {
      state.divisionId = payload;
      state.offset = 0;
    },
    setStateContains: (state, { payload }: PayloadAction<ErParams['stateContains']>) => {
      state.stateContains = payload;
      state.offset = 0;
    },
    resetParams: (state) => {
      Object.assign(state, initialState);
    }
  }
});

export const {
  incrementOffset,
  setOrderNumber,
  setCityContains,
  setCountryContains,
  setReferenceNumberContains,
  setStateContains,
  setRequester,
  setStatuses,
  setOffset,
  setReferenceIdContains,
  resetParams,
  setSubdivisionLocalCodeEquals,
  setCountryAlpha2CodeEquals,
  setRangeValues,
  resetRangeValues,
  setDivisionId,
  setEquipmentRuleType
} = barkleyParams.actions;

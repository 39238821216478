import { PickupLocationsGrid } from 'components/Create/common';
import { HeaderControls } from 'components/Create/PickupLocation';
import { InlineStylesModel } from 'models/InlineStylesModel';

const styles: InlineStylesModel = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    // overflow: 'clip',
    height: '100%'
  }
};

export const PickupAddressPage = (): JSX.Element => {
  return (
    <div style={styles.container}>
      <HeaderControls />
      <PickupLocationsGrid />
    </div>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SubstitutionParams {
  itemIdContains?: string;
  substitutionTypes?: string;
  substituteItemIdContains?: string;
  returnIsSparingModelsOnly?: boolean;
  excludeIsSparesModel?: boolean;
  substitutionOrderTypes?: string[];
  offset?: number;
  take?: number;
  overrideSkipTake?: boolean;
}

const initialState: SubstitutionParams = {
  itemIdContains: undefined,
  substitutionTypes: undefined,
  substituteItemIdContains: undefined,
  returnIsSparingModelsOnly: undefined,
  excludeIsSparesModel: undefined,
  substitutionOrderTypes: undefined,
  take: undefined,
  offset: undefined,
  overrideSkipTake: undefined
};

export const hermanTheMopParams = createSlice({
  name: 'hermanTheMopParams',
  initialState,
  reducers: {
    setItemIdContains: (state, { payload }: PayloadAction<SubstitutionParams['itemIdContains']>) => {
      state.itemIdContains = payload;
    },
    setSubstitutionTypes: (state, { payload }: PayloadAction<SubstitutionParams['substitutionTypes']>) => {
      state.substitutionTypes = payload;
    },
    setSubstituteItemIdContains: (state, { payload }: PayloadAction<SubstitutionParams['substituteItemIdContains']>) => {
      state.substituteItemIdContains = payload;
    },
    setReturnIsSparingModelsOnly: (state, { payload }: PayloadAction<SubstitutionParams['returnIsSparingModelsOnly']>) => {
      state.returnIsSparingModelsOnly = payload;
    },
    setExcludeIsSparesModel: (state, { payload }: PayloadAction<SubstitutionParams['excludeIsSparesModel']>) => {
      state.excludeIsSparesModel = payload;
    },
    setSubstitutionOrderTypes: (state, { payload }: PayloadAction<SubstitutionParams['substitutionOrderTypes']>) => {
      state.substitutionOrderTypes = payload;
    },
    setOffset: (state, { payload }: PayloadAction<SubstitutionParams['offset']>) => {
      state.offset = payload;
    },
    setTake: (state, { payload }: PayloadAction<SubstitutionParams['take']>) => {
      state.take = payload;
    }
  }
});

export const { setItemIdContains, setOffset, setReturnIsSparingModelsOnly, setSubstituteItemIdContains, setSubstitutionOrderTypes, setSubstitutionTypes, setTake, setExcludeIsSparesModel } =
  hermanTheMopParams.actions;

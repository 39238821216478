import { Col, Row, Space, Spin, Typography } from 'antd';
import { FieldInputSwitchOpen } from 'components/atoms/FieldInputSwitchOpen';
import { Loader } from 'components/common/Loader';
import { FieldInputDateOnlyPicker } from 'components/Create/FormItems/FieldInputDateOnlyPicker';
import { useFormikContext } from 'formik';
import Cookies from 'js-cookie';
import { VendorAttachment, VendorQuotePayload } from 'models/EquipmentReturn';
import { FieldInputCurrency } from 'pages/Create/FormItems/FieldInputCurrency';
import { FieldInputNumber } from 'pages/Create/FormItems/FieldInputNumber';
import { FieldInputSelect } from 'pages/Create/FormItems/FieldInputSelect';
import { VendorAttachmentUpload } from 'pages/Create/FormItems/VendorAttachmentUpload';
import { FC } from 'react';
import { useGetLegalEntitiesQuery } from 'redux/services/maggieCadabby/maggieCadabbyApi';

type Props = {
  attachment?: VendorAttachment;
  isEdit?: boolean;
};

export const VendorQuoteForm: FC<Props> = ({ attachment, isEdit }) => {
  const { data, isLoading } = useGetLegalEntitiesQuery({});
  const legalEntity = Cookies.get(`legalEntity`);
  const vendors = data?.data.find((ent) => ent.dataAreaId === legalEntity || ent.dataAreaId === 'MDSI')?.freightVendors;
  const { values } = useFormikContext<VendorQuotePayload>();

  const price = (values?.quoteAmount ?? 0) / (1 - (values?.markupPercentage ?? 0) / 100);

  return (
    <Row gutter={[16, 16]} style={{ width: '100%' }}>
      <Col span={24}>
        <Spin spinning={isLoading} indicator={<Loader small />}>
          <FieldInputSelect options={vendors?.map((opt) => ({ label: opt.carrierId, value: opt.carrierId }))} fieldName="vendor" label="Vendor" />
        </Spin>
      </Col>
      <Col span={24}>
        <FieldInputDateOnlyPicker fieldNameDate="quoteDate" label="Quote Date" />
      </Col>
      <Col span={24}>
        <FieldInputCurrency fieldName="quoteAmount" label="Quote Amount" />
      </Col>
      <Col span={24}>
        <FieldInputNumber fieldName="markupPercentage" label="Markup (%)" />
      </Col>
      <Col span={24}>
        <Row justify={'space-between'} style={{ width: '100%' }}>
          <Col>
            <Space style={{ width: '100%' }} size={5} direction="vertical">
              <Typography.Text>Freight Price </Typography.Text>
              <Typography.Text>${price.toFixed(2)}</Typography.Text>
            </Space>
          </Col>
          <Col>
            <FieldInputSwitchOpen isAwarded fieldName="isAwarded" label="Is Awarded" />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <VendorAttachmentUpload isEdit={isEdit} attachment={attachment} />
      </Col>
    </Row>
  );
};

import { FancyInput } from 'components/ui/Inputs';
import React from 'react';
import { setReferenceNumberContains } from 'redux/services/barkley/barkleyParams';
import { useAppDispatch } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';

export const ReferenceNumberFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (!value) {
      dispatch(setReferenceNumberContains(undefined));

      return;
    }

    dispatch(setReferenceNumberContains(value || undefined));
  }, 500);

  return <FancyInput placeholder="Reference Number" onChange={handleChange} allowClear />;
};

import { Col, Row, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { SparingRequestPayload } from 'models/SparingRequests';
import { ColorCard } from 'pages/Create/ColorCard';
import { useParams } from 'react-router-dom';
import { useGetEquipmentReturnQuery } from 'redux/services/barkley/barkleyApi';
import { useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';
const { Text, Title } = Typography;

const styles: InlineStylesModel = {
  header: {
    backgroundColor: toRgba(colors.orangeWeb, 0.4)
  }
};

export const OrderInfoContent = (): JSX.Element => {
  const { values } = useFormikContext<SparingRequestPayload>();
  const { user } = useAppSelector((state) => state.app);
  const { selectedDivisionId } = useAppSelector((state) => state.app);
  const { id } = useParams();
  const { data } = useGetEquipmentReturnQuery({ divisionId: selectedDivisionId as string, equipmentReturnId: id as string }, { skip: !id || !selectedDivisionId });

  return (
    <ColorCard color={toRgba(colors.orangeWeb, 0.4)} style={{ height: '100%' }} title={'Order Details'}>
      <Row gutter={16}>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Created By:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{id ? data?.createdByFullName : user?.name}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Created On:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{id ? new Date(data?.createdDateTime || '').toLocaleDateString() : new Date().toLocaleDateString()}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Contact Number:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.contactPhoneNumber || 'n/a'}</Typography.Paragraph>
        </Col>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Modified By:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{id ? data?.modifiedByFullName : user?.name}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Modified On:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{id ? new Date(data?.modifiedDateTime || '').toLocaleDateString() : new Date().toLocaleDateString()}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Notes:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.notes || 'None'}</Typography.Paragraph>
        </Col>

        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Contact Name:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.contactName || 'n/a'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Contact Email:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.contactEmail || 'n/a'}</Typography.Paragraph>
        </Col>
      </Row>
    </ColorCard>
  );
};

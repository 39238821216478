import { List, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { EquipmentReturn } from 'models/EquipmentReturn';
import { FC } from 'react';

type Props = {
  packagesWithWaybill: EquipmentReturn['packages'];
};

export const TrackingWaybillTable: FC<Props> = ({ packagesWithWaybill }) => {
  const cols: ColumnsType<EquipmentReturn['packages'][0]> = [
    {
      title: 'Packages',
      key: 'numberOfPackages',
      dataIndex: 'numberOfPackages'
    },
    {
      title: 'Size',
      key: 'packageSize',
      dataIndex: 'packageSize'
    },
    {
      title: 'Weight (lbs)',
      key: 'packageWeight',
      dataIndex: 'packageWeight'
    },
    {
      title: 'Master Tracking Number',
      render: (_, record) => (
        <a href={`https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(record.waybill[0].url)}`} target="_blank">
          {record.waybill[0].masterTrackingNumber}
        </a>
      )
    }
  ];

  return (
    <Table
      size="small"
      pagination={false}
      columns={cols}
      dataSource={packagesWithWaybill ?? []}
      rowKey={(record) => packagesWithWaybill.indexOf(record).toString()}
      expandable={{
        expandedRowRender: (record) => (
          <Space direction="vertical">
            <Typography.Text strong>Tracking Numbers: </Typography.Text>
            <List
              dataSource={record.waybill}
              renderItem={(item): JSX.Element => (
                <List.Item>
                  <a href={`https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(item.url)}`} target="_blank">
                    {item.trackingNumber}
                  </a>
                </List.Item>
              )}
            />
          </Space>
        )
      }}
    />
  );
};

import { List, Row, Space } from 'antd';
import { AddPackageButton } from 'components/atoms/AddPackageButton';
import { EditPackageButton } from 'components/atoms/EditPackageButton';
import { ViewWaybillsButton } from 'components/atoms/ViewWaybillsButton';
import { LottieListButton } from 'components/common/LottieListButton';
import { WaitToSearch } from 'components/common/waitToSearch/WaitToSearch';
import { useFormikContext } from 'formik';
import { EquipmentReturn, EquipmentReturnPayload } from 'models/EquipmentReturn';
import { ColorCard } from 'pages/Create/ColorCard';
import { colors, toRgba } from 'styles/colors';
import packageLottie from '../../assets/package.json';
import deleteLottie from '../../assets/trash.json';

interface Props {
  packages?: EquipmentReturn['packages'];
}

export const PackageInformationTable: React.FC<Props> = () => {
  const { values, setFieldValue } = useFormikContext<EquipmentReturnPayload>();

  const packagesWithWaybill = values?.packages.filter((item) => !!item?.waybill?.length);

  const handleDelete = (id: number): void => {
    setFieldValue(
      'packages',
      values.packages.filter((item) => item.key !== id).map((item, index) => ({ ...item, key: index + 1 }))
    );
  };

  return (
    <ColorCard
      title="Packages"
      color={toRgba(colors.robinEggBlue, 0.4)}
      extra={
        <Space>
          <AddPackageButton />
          {!!packagesWithWaybill?.length && <ViewWaybillsButton packagesWithWaybill={packagesWithWaybill} />}
        </Space>
      }>
      <List
        size="small"
        grid={{ column: 2 }}
        dataSource={values.packages}
        locale={{ emptyText: <WaitToSearch animationData={packageLottie} message="Please add packages to continue" /> }}
        style={{ padding: 2 }}
        renderItem={(item): JSX.Element => (
          <List.Item key={item.packageWeight} style={{ borderRadius: 10, border: '1px solid lightgray', padding: 16, marginRight: 16 }}>
            <Row justify={'space-between'}>
              <List.Item.Meta
                avatar={
                  <Row align={'middle'} justify={'center'} style={{ borderRadius: 100, background: 'lightgray', width: 30, height: 30 }}>
                    {item.packageSize}
                  </Row>
                }
                title={`Packages: ${item.numberOfPackages}`}
                description={`${item.packageWeight.toFixed(2)} lbs`}
              />
              <Space>
                <EditPackageButton item={item} />
                <LottieListButton onClick={(): void => handleDelete(item.key)} backgroundColor="#FFE1E1" title="Delete" lottieFile={deleteLottie} />
                {/* <LottieListButton backgroundColor="#D5FFDF" title="Generate Waybill" lottieFile={generateLottie} /> */}
              </Space>
            </Row>
          </List.Item>
        )}
      />
    </ColorCard>
  );
};

import { Col, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useParams } from 'react-router-dom';
import { useGetEquipmentReturnQuery } from 'redux/services/barkley/barkleyApi';
import { useAppSelector } from 'redux/store';

const styles: InlineStylesModel = {
  headerTitle: {
    fontWeight: 'bold',
    color: '#2F5596'
  }
};

export const EquipmentReturnMetaInfoOpen = (): JSX.Element => {
  const { selectedDivisionId } = useAppSelector((state) => state.app);
  const { id } = useParams();
  const { data } = useGetEquipmentReturnQuery({ divisionId: selectedDivisionId as string, equipmentReturnId: id as string }, { skip: !id || !selectedDivisionId });

  return (
    <>
      <Row align="middle" gutter={[15, 15]} style={{ marginBottom: 3, width: '100%' }}>
        <Col>
          <Space>
            <Typography.Text style={styles.headerTitle}>Equipment Return Order Number: </Typography.Text>
            <Typography.Text>{data?.returnOrderNumber ?? 'RET0000N'}</Typography.Text>
          </Space>
        </Col>
        <Col>
          <Space>
            <Typography.Text style={styles.headerTitle}>Equipment Return Name: </Typography.Text>
            <Typography.Text>{data?.equipmentReturnName}</Typography.Text>
          </Space>
        </Col>
        <Col>
          <Space>
            <Typography.Text style={styles.headerTitle}>Equipment Return Type: </Typography.Text>
            <Typography.Text>{data?.equipmentReturnType.name}</Typography.Text>
          </Space>
        </Col>
        <Col>
          <Space>
            <Typography.Text style={styles.headerTitle}>Reference Number: </Typography.Text>
            <Typography.Text>{data?.referenceNumber}</Typography.Text>
          </Space>
        </Col>
        <Col>
          <Space>
            <Typography.Text style={styles.headerTitle}>Status: </Typography.Text>
            <Typography.Text>{data?.returnStatus}</Typography.Text>
          </Space>
        </Col>
        <Col>
          <Space>
            <Typography.Text style={styles.headerTitle}>Created Date/Time</Typography.Text>
            <Typography.Text>{dayjs(data?.createdDateTime).format('MM-DD-YYYY: HH:mm')}</Typography.Text>
          </Space>
        </Col>
      </Row>
      <Row align="middle" gutter={[15, 15]} style={{ marginBottom: 3, width: '100%' }}>
        <Col>
          <Space>
            <Typography.Text style={styles.headerTitle}>Requestor Name: </Typography.Text>
            <Typography.Text>{data?.requestorName}</Typography.Text>
          </Space>
        </Col>
        <Col>
          <Space>
            <Typography.Text style={styles.headerTitle}>Requestor Number: </Typography.Text>
            <Typography.Text>{data?.requestorPhone}</Typography.Text>
          </Space>
        </Col>
        <Col>
          <Space>
            <Typography.Text style={styles.headerTitle}>Requestor Email: </Typography.Text>
            <Typography.Text>{data?.requestorEmail}</Typography.Text>
          </Space>
        </Col>
      </Row>
    </>
  );
};

import { Card, Col, Radio, Row, Space, Switch, Typography } from 'antd';
import Title from 'antd/es/typography/Title';
import { FieldInputSwitch } from 'components/atoms/FieldInputSwitch';
import { FormikRadioGroup } from 'components/atoms/FormikRadioGroup';
import { EquipmentReturn } from 'models/EquipmentReturn';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useGetEquipmentReturnQuery } from 'redux/services/barkley/barkleyApi';
import { useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';
const siteInfo: { category: string; items: { label: string; fieldName: string }[] }[] = [
  {
    category: 'Parking and Loading Requirements',
    items: [
      {
        label: 'Parking Pass Required To park Carrier Truck',
        fieldName: 'isParkingPassRequiredToParkCarrierTruck'
      },
      {
        label: 'Special Permit To Access Loading Dock',
        fieldName: 'isSpecialPermitToAccessLoadingDockRequired'
      },
      {
        label: 'Freight Elevator Permit Required',
        fieldName: 'isFreightElevatorPermitRequired'
      },
      {
        label: 'Lift Gate Required',
        fieldName: 'isLiftGateRequired'
      }
    ]
  },
  {
    category: 'Shipping and Handling',
    items: [
      {
        label: 'Pallets Needed Prior To Shipping',
        fieldName: 'arePalletsNeededPriorToShipping'
      },
      {
        label: 'Disassembly Required For Cabinets',
        fieldName: 'isDisassemblyRequired'
      },
      {
        label: 'Staircase Needed To Transport Equipment',
        fieldName: 'isAStaircaseNeededToTransportEquipment'
      }
    ]
  },
  {
    category: 'Facilities and Equipment',
    items: [
      {
        label: 'Cabinet Can Fit Elevator',
        fieldName: 'canCabinetFitInElevator'
      },
      {
        label: 'Has Loading Dock',
        fieldName: 'isThereALoadingDock'
      },
      {
        label: 'Has ESD Safe Floor',
        fieldName: 'isFloorESDSafe'
      },
      {
        label: 'Has Fork Lift',
        fieldName: 'isThereAForkliftThere'
      },
      {
        label: 'Has Freight Elevator (If Needed)',
        fieldName: 'isThereAFreightElevator'
      },
      {
        label: 'Has Pallet Jacket Onsite',
        fieldName: 'isThereAPalletJacketOnsite'
      }
    ]
  }
];

type Props = {
  isOpen?: boolean;
  isInquiry?: boolean;
};

export const ReturnDetailsCard: FC<Props> = ({ isOpen = false, isInquiry = false }): JSX.Element => {
  const { selectedDivisionId } = useAppSelector((state) => state.app);
  const { id } = useParams();
  const { data } = useGetEquipmentReturnQuery({ divisionId: selectedDivisionId as string, equipmentReturnId: id as string }, { skip: !id || !selectedDivisionId });

  if (isInquiry) {
    return (
      <Card
        styles={{ header: { backgroundColor: toRgba(isOpen ? colors.illuminatingEmerald : colors.royalBlueLight, 0.4) } }}
        title={
          <Title level={4} style={{ fontWeight: 'normal', margin: 0, padding: 0 }}>
            Additional Details
          </Title>
        }
        style={{ height: '100%' }}>
        <Space direction="vertical">
          <Row gutter={[16, 16]}>
            {siteInfo.map((info) => (
              <Col span={isOpen ? 12 : 24}>
                <Typography.Title style={{ margin: 0, padding: 0 }} level={5}>
                  {info.category}:{' '}
                </Typography.Title>
                {info.items.map((item) => (
                  <Row align={'middle'}>
                    <Space>
                      <Switch disabled size="small" checked={(data?.[item.fieldName as keyof EquipmentReturn] as boolean) ?? false}></Switch>
                      <Typography.Text>{item.label}</Typography.Text>
                    </Space>
                  </Row>
                ))}
              </Col>
            ))}
            <Col span={12}>
              <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>
                Is the value of equipment greater than $100,000?
              </Typography.Title>
              <Radio.Group disabled value={data?.isEquipmentValueOver100K}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </Space>
      </Card>
    );
  }

  return (
    <Card
      styles={{ header: { backgroundColor: toRgba(isOpen ? colors.illuminatingEmerald : colors.royalBlueLight, 0.4) } }}
      title={
        <Title level={4} style={{ fontWeight: 'normal', margin: 0, padding: 0 }}>
          Additional Details
        </Title>
      }
      style={{ height: '100%' }}>
      <Space direction="vertical">
        <Row gutter={[16, 16]}>
          {siteInfo.map((info) => (
            <>
              <Col span={isOpen ? 12 : 24}>
                <Typography.Title style={{ margin: 0, padding: 0 }} level={5}>
                  {info.category}:{' '}
                </Typography.Title>
                {info.items.map((item) => (
                  <Row align={'middle'}>
                    <FieldInputSwitch size="small" fieldName={item.fieldName} label={item.label} />
                  </Row>
                ))}
              </Col>
            </>
          ))}
          {isOpen && isInquiry && (
            <Col span={12}>
              <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>
                Is the value of equipment greater than $100,000?
              </Typography.Title>
              <FormikRadioGroup />
            </Col>
          )}
        </Row>
      </Space>
    </Card>
  );
};

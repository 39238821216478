import { Row, Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { Field, FieldProps, useFormikContext } from 'formik';
import { EquipmentReturnPayload } from 'models/EquipmentReturn';
import { CSSProperties, FC } from 'react';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps {
  fieldName: string;
  label: string;
  labelStyle?: CSSProperties;
  helpIcon?: boolean;
}

export const FieldInputSelect: FC<Props> = ({ fieldName, label, labelStyle, ...rest }) => {
  const { values } = useFormikContext<EquipmentReturnPayload>();

  const handleFilterOption = (input: string, option: DefaultOptionType | undefined): boolean => {
    if (!!option && typeof option.label === 'string')
      return (
        !!option &&
        !!option.value &&
        typeof option.value === 'string' &&
        typeof option.label === 'string' &&
        (option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0)
      );

    return !!option && !!option.value && typeof option.value === 'string' && option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <Field name={fieldName}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        const handleChange = (val: string, option: DefaultOptionType): void => {
          if (fieldName === 'equipmentReturnTypeId') {
            setFieldValue('equipmentReturnTypeName', option?.label ? (option?.label as string).replace(/\s+/g, '') : '');
          }

          if (values.equipmentReturnTypeId && fieldName === 'divisionId') {
            setFieldValue('equipmentReturnTypeId', '');
            setFieldValue('equipmentReturnTypeName', '');
          }

          if (values.packages.length && (option?.label === 'Equipment Pickup' || option?.label) === 'Decommission') {
            setFieldValue('packages', []);
          }

          if ((values.requestedPickupDate || values.requestedPickupTime) && option?.label === 'Return Shipment') {
            setFieldValue('requestedPickupDate', null);
            setFieldValue('requestedPickupTime', null);
          }
          setFieldValue(fieldName, val);
        };

        return (
          <Row style={{ width: '100%' }}>
            <FormLabel style={labelStyle} label={label} />

            <Select
              {...field}
              showSearch
              allowClear
              onClear={(): void => {
                setFieldTouched(fieldName, true);
              }}
              filterOption={handleFilterOption}
              onChange={handleChange}
              status={meta.error && meta.touched ? 'error' : undefined}
              onFocus={(): void => {
                setFieldTouched(fieldName, false);
              }}
              onBlur={(): void => {
                setFieldTouched(fieldName, true);
              }}
              style={{ width: '100%' }}
              {...rest}
            />
            <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
          </Row>
        );
      }}
    </Field>
  );
};

import { Col, Row, Tooltip } from 'antd';
import { FancySelect } from 'components/ui/Inputs';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useEffect } from 'react';
import { resetParams } from 'redux/services/chuckieSue/addressesParams';
import { useAppDispatch } from 'redux/store';
import { CityFilter, LocationCodeFilter, StreetFilter, ZipCodeFilter } from './components';
import { CountryStateInputsLocations } from './components/CountryStateFilterLocations';
import { LocationNameFilter } from './components/LocationNameFilter';

const styles: InlineStylesModel = {
  container: {
    flex: 1
  }
};

export const LocationsFilters = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  /* ******************** Effects ******************** */
  useEffect(() => {
    return () => {
      dispatch(resetParams());
    };
  }, [dispatch]);

  return (
    <Row style={styles.container} gutter={[8, 20]}>
      <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
        <LocationNameFilter />
      </Col>
      <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
        <StreetFilter />
      </Col>
      <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
        <CityFilter />
      </Col>
      <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
        <LocationCodeFilter />
      </Col>
      <CountryStateInputsLocations />
      <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
        <ZipCodeFilter />
      </Col>
      <Tooltip title="Coming soon">
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <FancySelect disabled value={['Install', 'Delivery']} mode="multiple" options={['Install', 'Delivery'].map((option) => ({ label: option, value: option }))} />
        </Col>
      </Tooltip>
    </Row>
  );
};

import { RcFile } from 'antd/es/upload';
import * as yup from 'yup';
import { EquipmentReturnType } from './EquipmentReturnTypes';

export type EquipmentReturnPayload = {
  dockDoors: boolean;
  emailNotificationList: string[];
  equipmentReturnName: string;
  freightElevator: boolean;
  isEquipmentPackaged: boolean;
  isEquipmentValueOver100K: boolean;
  notes: string;
  packages: {
    key: number;
    numberOfPackages: number;
    packageSize: string;
    packageWeight: number;
    waybill: Waybill[];
  }[];
  pickupAddress?: PickupAddress;
  divisionId?: string;
  referenceNumber: string;
  requestedPickupDate: string | null;
  requestedPickupTime: string | null;
  requestorEmail: string;
  requestorName: string;
  requestorPhone: string;
  siteContactEmail: string;
  siteContactName: string;
  siteContactPhone: string;
  siteSpecificInstructions: string;
  waybillNumber: string;
  isParkingPassRequiredToParkCarrierTruck: boolean;
  isThereAPalletJacketOnsite: boolean;
  isThereAFreightElevator: boolean;
  isThereAForkliftThere: boolean;
  isFloorESDSafe: boolean;
  isThereALoadingDock: boolean;
  canCabinetFitInElevator: boolean;
  isAStaircaseNeededToTransportEquipment: boolean;
  isDisassemblyRequired: boolean;
  arePalletsNeededPriorToShipping: boolean;
  isLiftGateRequired: boolean;
  isFreightElevatorPermitRequired: boolean;
  isSpecialPermitToAccessLoadingDockRequired: boolean;
  equipmentReturnTypeId: string;
  equipmentReturnTypeName?: string;
  qtyOfEquipment: number;
  numberOfPallets: number;
  numberOfBoxes: number;
  totalWeight: number;
  returnStatus?: string;
  shipperContactName?: string;
  shipperContactPhone?: string;
  shipperBusinessName?: string;
  warehouseContactName?: string;
  warehouseContactPhone?: string;
};

export type EquipmentReturn = {
  attachments: RcFile[];
  returnOrderNumber: string;
  billOfLadings: RcFile[];
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  equipmentReturnTypeId: string;
  equipmentReturnType: EquipmentReturnType;
  divisionMetadata: {
    business: {
      dataAreaId: string;
      erpId: string;
      id: string;
      name: string;
    };
    erpId: string;
    id: string;
    name: string;
  };
  dockDoors: boolean;
  documentType: string;
  emailNotificationList: string[];
  equipmentReturnName: string;
  freightElevator: boolean;
  fulfillmentStatus: null | string;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  isEquipmentPackaged: boolean;
  isEquipmentValueOver100K: boolean;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  notes: string;
  packages: {
    key: number;
    numberOfPackages: number;
    packageSize: string;
    packageWeight: number;
    waybill: Waybill[];
  }[];
  partitionKey: string;
  partitionKeyDescription: string;
  pickupAddress: PickupAddress;
  referenceNumber: string;
  requestedPickupDate: string;
  requestedPickupTime: string;
  requestorEmail: string;
  requestorName: string;
  requestorPhone: string;
  siteContactEmail: string;
  siteContactName: string;
  siteContactPhone: string;
  siteSpecificInstructions: string;
  statusHistory: string[];
  returnStatus: string;
  waybillNumber: string;
  isParkingPassRequiredToParkCarrierTruck: boolean;
  isThereAPalletJacketOnsite: boolean;
  isThereAFreightElevator: boolean;
  isThereAForkliftThere: boolean;
  isFloorESDSafe: boolean;
  isThereALoadingDock: boolean;
  canCabinetFitInElevator: boolean;
  isAStaircaseNeededToTransportEquipment: boolean;
  isDisassemblyRequired: boolean;
  arePalletsNeededPriorToShipping: boolean;
  isLiftGateRequired: boolean;
  isFreightElevatorPermitRequired: boolean;
  isSpecialPermitToAccessLoadingDockRequired: boolean;
  qtyOfEquipment: number;
  numberOfPallets: number;
  numberOfBoxes: number;
  totalWeight: number;
  warehouseRoutingFulfillment: WarehouseRoutingFulfillment | null;
  shipperContactName: string;
  shipperContactPhone: string;
  shipperBusinessName: string;
  warehouseContactName: string;
  warehouseContactPhone: string;
  fulfillmentStatusHistory: FulfillmentStatusHistory[];
};

export type OpenEquipmentReturn = EquipmentReturn & {
  waybillNumber: string;
  mdSiProjectNumber: string;
  disposalOrderNumber: string;
  scheduledPickupDate: string;
  scheduledPickupTime: string;
  freightCompany: string;
  shipToWarehouse: string;
  fulfillmentStatus?: string;
  internalNotes: string;
  notificationNotes: string;
  requestSource: string;
  requestPriority: string;
  invoiceNumber: string;
  isInvoiced: boolean;
  vendorQuotes: VendorQuote[];
};

export type OpenEquipmentReturnPayload = EquipmentReturnPayload & {
  waybillNumber: string;
  mdSiProjectNumber: string;
  disposalOrderNumber: string;
  scheduledPickupDate: string;
  scheduledPickupTime: string;
  freightCompany: string;
  shipToWarehouse: string;
  fulfillmentStatus?: string;
  internalNotes?: string;
  notificationNotes?: string;
  requestSource?: string;
  requestPriority?: string;
  invoiceNumber?: string;
  isInvoiced?: boolean;
  vendorQuotes: VendorQuote[];
};

export type WarehouseRoutingFulfillment = {
  countryCode: string;
  postalCode: string;
  subdivisionCode: string;
  warehouseId: string;
};

export type PickupAddress = {
  city: string;
  code: string;
  country: string;
  countryAlpha2Code: string;
  id: string;
  latitude: number;
  locationId: string;
  longitude: number;
  name: string;
  postalCode: string;
  street1: string;
  street2: string;
  subdivision: string;
  subdivisionLocalCode: string;
  isManualAddress: boolean;
};
export interface ErParams {
  offset: number;
  requesterNameContains?: string;
  referenceIdContains?: string;
  referenceNumberContains?: string;
  salesOrderNumberContains?: string;
  countryContains?: string;
  stateContains?: string;
  cityContains?: string;
  statuses?: string;
  startDate?: string;
  endDate?: string;
  includeInactiveData?: boolean;
  includeDeletedData?: boolean;
  returnDeletedDataOnly?: boolean;
  subdivisionLocalCodeEquals?: string;
  countryAlpha2CodeEquals?: string;
  orderByDirection?: string;
  divisionId?: string;
  equipmentRuleType?: string;
}

export type FulfillmentStatusHistory = {
  createdByUserFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  status: string;
};

export type WarehouseRoutingFulfillmentResponse = {
  city: string;
  countryCode: string;
  postalCode: string;
  street: string;
  subdivisionCode: string;
  warehouseId: string;
};

export type VendorQuote = {
  attachment: VendorAttachment;
  vendor: string;
  quoteDate: string;
  quoteAmount: number;
  id: string;
  markupPercentage: number;
  freightPrice?: number;
  isAwarded: boolean;
};

export type VendorAttachment = {
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  dataAreaId: string;
  divisionId: string;
  equipmentReturnId: string;
  id: string;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  name: string;
  size: number;
  url: string;
};
export type VendorQuotePayload = {
  fileBase64: string;
  fileName: string;
  vendor: string;
  quoteDate?: string;
  quoteAmount?: number;
  markupPercentage?: number;
  freightPrice?: number;
  isAwarded?: boolean;
  didAttachmentFileChange?: boolean;
};

export type Waybill = {
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  dataAreaId: string;
  divisionId: string;
  equipmentReturnId: string;
  id: string;
  masterTrackingNumber: string;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  name: string;
  size: number;
  trackingNumber: string;
  url: string;
};

export const equipmentReturnPayloadSchema = yup.object().shape({
  dockDoors: yup.boolean(),
  emailNotificationList: yup.array().of(yup.string().email()),
  equipmentReturnId: yup.string(),
  freightElevator: yup.boolean(),
  isEquipmentPackaged: yup.boolean(),
  isEquipmentValueOver100K: yup.boolean(),
  notes: yup.string(),
  packages: yup.array().of(
    yup.object().shape({
      key: yup.number(),
      numberOfPackages: yup.number(),
      packageSize: yup.string(),
      packageWeight: yup.number()
    })
  ),
  pickupAddressId: yup.string(),
  referenceNumber: yup.string().label('Reference Number').required(),
  requestedPickupDate: yup
    .string()
    .label('Pickup Date')
    .when('equipmentReturnTypeName', {
      is: (equipmentReturnTypeName: string) => equipmentReturnTypeName !== 'ReturnShipment',
      then: yup.string().required().nullable(),
      otherwise: yup.string().nullable()
    }),
  requestedPickupTime: yup
    .string()
    .label('Pickup Time')
    .when('equipmentReturnTypeName', {
      is: (equipmentReturnTypeName: string) => equipmentReturnTypeName !== 'ReturnShipment',
      then: yup.string().required().nullable(),
      otherwise: yup.string().nullable()
    }),
  requestorEmail: yup.string().email(),
  requestorName: yup.string(),
  requestorPhone: yup.string(),
  siteContactEmail: yup.string().email(),
  siteContactName: yup.string(),
  siteContactPhone: yup.string(),
  siteSpecificInstructions: yup.string(),
  waybillNumber: yup.string(),
  isParkingPassRequiredToParkCarrierTruck: yup.boolean(),
  isThereAPalletJacketOnsite: yup.boolean(),
  isThereAFreightElevator: yup.boolean(),
  isThereAForkliftThere: yup.boolean(),
  isFloorESDSafe: yup.boolean(),
  isThereALoadingDock: yup.boolean(),
  canCabinetFitInElevator: yup.boolean(),
  isAStaircaseNeededToTransportEquipment: yup.boolean(),
  isDisassemblyRequired: yup.boolean(),
  arePalletsNeededPriorToShipping: yup.boolean(),
  isLiftGateRequired: yup.boolean(),
  isFreightElevatorPermitRequired: yup.boolean(),
  isSpecialPermitToAccessLoadingDockRequired: yup.boolean(),
  equipmentReturnTypeId: yup.string().label('Equipment Return Type').required(),
  equipmentReturnName: yup.string().label('Equipment Return Name').required()
});

export const vendorQuotePayloadSchema = yup.object().shape({
  fileBase64: yup.string().optional(),
  fileName: yup.string().optional(),
  vendor: yup.string().required(),
  quoteDate: yup.string().optional(),
  quoteAmount: yup.number().optional(),
  markup: yup.number().optional(),
  freightPrice: yup.number().optional(),
  isAwarded: yup.boolean().optional()
});

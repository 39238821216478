import { Button, Drawer, Row } from 'antd';
import { HeaderControls } from 'components/Create/PickupLocation';
import { ChangePickupLocationsGrid } from 'components/molecules/ChangePickupLocationsGrid';
import { useDispatch } from 'react-redux';
import { setIsDrawerOpen } from 'redux/slices/formSlice';
import { useAppSelector } from 'redux/store';

export const ChangeAddressDrawer = (): JSX.Element => {
  const { isDrawerOpen } = useAppSelector((state) => state.form);
  const dispatch = useDispatch();

  return (
    <>
      <Button
        style={{ marginLeft: 10 }}
        // icon={<SwapOutlined />}

        onClick={(): void => {
          dispatch(setIsDrawerOpen(true));
        }}
        type="link">
        Change
      </Button>

      <Drawer
        destroyOnClose
        footer={
          <Row justify={'end'}>
            <Button
              type="primary"
              onClick={(): void => {
                dispatch(setIsDrawerOpen(false));
              }}>
              Close
            </Button>
          </Row>
        }
        closeIcon={null}
        onClose={(): void => {
          dispatch(setIsDrawerOpen(false));
        }}
        // closable={true}
        placement="right"
        width={1500}
        height={'75%'}
        open={isDrawerOpen}>
        <HeaderControls />
        <ChangePickupLocationsGrid />
      </Drawer>
    </>
  );
};

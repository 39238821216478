// import { skipToken } from '@reduxjs/toolkit/dist/query';
import { message, notification, Space, Spin } from 'antd';
import { ActionControls, StepControls } from 'components/Create/common';
import { EquipmentReturnMetaInfo } from 'components/Create/common/EquipmentReturnMetaInfo';
import { Loader } from 'components/common/Loader';
import { FormikProvider, useFormik } from 'formik';
import { EquipmentReturnPayload, equipmentReturnPayloadSchema } from 'models/EquipmentReturn';
import { QueryErrorModel } from 'models/ErrorModel';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { useCreateAttachmentMutation, useCreateEquipmentReturnMutation } from 'redux/services/barkley/barkleyApi';
import { useGetEquipmentReturnConfigurationTypesQuery } from 'redux/services/maurice/sparingRequestConfigTypes';
import { resetAttachments } from 'redux/slices/attachmentsSlice';
import { setFullOutageAddress, setFullShippingAddress, setIsExit, setIsPost } from 'redux/slices/formSlice';
import { useAppSelector } from 'redux/store';

export const CreateReturnLayout = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { user, postRequestLoadingMessage, isInternalUser } = useAppSelector((state) => state.app);
  const { isExit, isPost } = useAppSelector((state) => state.form);
  const { attachments } = useAppSelector((state) => state.attachments);
  const nav = useNavigate();
  const { acuityContext } = useAppSelector((state) => state.app);

  const divisionId = acuityContext?.selectedCustomer.id;

  const [createAttachment] = useCreateAttachmentMutation();
  const [createReturn] = useCreateEquipmentReturnMutation();
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();

  const { data: typesData, isLoading: isTypesLoading } = useGetEquipmentReturnConfigurationTypesQuery({ divisionId: divisionId as string, params: {} }, { skip: !divisionId });

  const userNumber = user?.['https://acuity.mdsiinc.com/user/user_metadata']?.phone_number;

  const defaultType = typesData?.data.length === 1 ? typesData?.data[0].id : '';
  const defaultTypeName = typesData?.data.length === 1 ? typesData?.data[0].name : '';

  const formik = useFormik<EquipmentReturnPayload>({
    enableReinitialize: true,
    validationSchema: equipmentReturnPayloadSchema,
    initialValues: {
      referenceNumber: '',
      dockDoors: false,
      divisionId: '',
      emailNotificationList: user && user.email ? [user.email] : [],
      equipmentReturnName: '',
      freightElevator: false,
      packages: [],
      isEquipmentPackaged: false,
      isEquipmentValueOver100K: false,
      notes: '',
      pickupAddress: undefined,
      requestedPickupDate: null,
      requestedPickupTime: null,
      requestorName: '',
      requestorPhone: '',
      requestorEmail: '',
      siteContactEmail: (user && user.email) ?? '',
      siteContactName: (user && user.name) ?? '',
      siteContactPhone: userNumber ? userNumber : '',
      siteSpecificInstructions: '',
      waybillNumber: '',
      isParkingPassRequiredToParkCarrierTruck: false,
      isThereAPalletJacketOnsite: false,
      isThereAForkliftThere: false,
      isFloorESDSafe: false,
      isThereALoadingDock: false,
      canCabinetFitInElevator: false,
      isAStaircaseNeededToTransportEquipment: false,
      isDisassemblyRequired: false,
      arePalletsNeededPriorToShipping: false,
      isLiftGateRequired: false,
      isFreightElevatorPermitRequired: false,
      isSpecialPermitToAccessLoadingDockRequired: false,
      isThereAFreightElevator: false,
      equipmentReturnTypeId: isInternalUser ? '' : defaultType,
      equipmentReturnTypeName: isInternalUser ? '' : defaultTypeName ?? '',
      qtyOfEquipment: 0,
      numberOfPallets: 0,
      numberOfBoxes: 0,
      totalWeight: 0,
      shipperBusinessName: '',
      shipperContactName: (user && user.name) ?? '',
      shipperContactPhone: userNumber ? userNumber : '',
      warehouseContactName: 'John Doe',
      warehouseContactPhone: '5555555555'
    },
    onSubmit: async (values) => {
      setLoadingMessage('Creating equipment return...');

      const divIdToPass = isInternalUser ? values.divisionId : divisionId;

      const digits = values.shipperContactPhone?.replace(/\D/g, '');

      if (isPost && values.equipmentReturnTypeName === 'ReturnShipment' && digits?.length !== 10) {
        notification.error({
          message: 'Error',
          description: 'Shipper Contact Phone must be 10 digits',
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 5
        });

        return;
      }

      const payload: EquipmentReturnPayload = {
        ...values,
        returnStatus: isPost ? 'Open' : 'Draft'
      };

      let resp;

      if (!divIdToPass) {
        return message.error('Division ID is missing');
      }

      try {
        const response = await createReturn({ divisionId: divIdToPass, payload }).unwrap();

        resp = response;

        if (attachments.length) {
          setLoadingMessage('Creating attachments...');
          for await (const attachment of attachments) {
            const formData = new FormData();

            formData.append(`${attachment.name}`, attachment);
            await createAttachment({ file: formData, divisionId: divIdToPass, equipmentReturnId: response.id }).unwrap();
          }
          dispatch(resetAttachments());
        }

        notification.success({
          message: 'Success',
          description: `Equipment Return: ${response.equipmentReturnName} has been created successfully.`,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 5
        });

        if (isExit) {
          nav('/');
          dispatch(setIsExit(false));
        } else nav(`/edit/${response.id}`);
        if (isPost) {
          nav(isInternalUser ? `/manage/${response.id}` : `/details/${response.id}`);
          dispatch(setIsPost(false));
        }
      } catch (e) {
        setLoadingMessage(undefined);
        console.error(e, 'error');

        const error = e as QueryErrorModel;
        const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occured, the team has been notified.';

        dispatch(setIsPost(false));

        notification.error({
          message: 'Error',
          description: errorMessage,
          className: 'custom-class',
          style: {
            width: 600
          },
          duration: 5
        });
      }
    }
  });

  /* ******************** Effects ******************** */
  useEffect(() => {
    return () => {
      dispatch(setFullOutageAddress(null));
      dispatch(setFullShippingAddress(null));
      dispatch(resetAttachments());
    };
  }, []);

  return (
    <FormikProvider value={formik}>
      <Spin indicator={<Loader loadingMessage={postRequestLoadingMessage ?? loadingMessage} />} spinning={formik.isSubmitting}>
        <Space direction="vertical" size={10} style={{ width: '100%' }}>
          <ActionControls />
          <EquipmentReturnMetaInfo />
        </Space>
        <StepControls />
        <Outlet />
      </Spin>
    </FormikProvider>
  );
};

import { Col, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { EquipmentReturnPayload } from 'models/EquipmentReturn';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { FC } from 'react';

import { useGetEquipmentReturnConfigurationTypesQuery } from 'redux/services/maurice/sparingRequestConfigTypes';
import { useAppSelector } from 'redux/store';

const styles: InlineStylesModel = {
  headerTitle: {
    fontWeight: 'bold',
    color: '#2F5596'
  }
};

type Props = {
  returnOrderNumber?: string;
};

export const EquipmentReturnMetaInfo: FC<Props> = ({ returnOrderNumber = '' }) => {
  const { values } = useFormikContext<EquipmentReturnPayload>();
  const { pickupAddress: pickupAddressForm } = values;
  const { acuityContext } = useAppSelector((state) => state.app);
  const divisionId = acuityContext?.selectedCustomer.id;
  const { data } = useGetEquipmentReturnConfigurationTypesQuery({ divisionId: divisionId as string, params: {} }, { skip: !divisionId });

  const foundType = data?.data.find((type) => type.id === values.equipmentReturnTypeId);

  const pickupAddress = pickupAddressForm
    ? `${pickupAddressForm.name}: ${pickupAddressForm.code}: ${pickupAddressForm.street1}, ${pickupAddressForm.city}, ${pickupAddressForm?.subdivisionLocalCode || ''}, ${
        pickupAddressForm.countryAlpha2Code || ''
      } ${pickupAddressForm.postalCode}`
    : '';

  return (
    <>
      <Row align="middle" gutter={[15, 5]} style={{ marginBottom: 3, width: '100%' }}>
        {returnOrderNumber && (
          <Col>
            <Space>
              <Typography.Text style={styles.headerTitle}>Equipment Return Order Number: </Typography.Text>
              <Typography.Text>{returnOrderNumber}</Typography.Text>
            </Space>
          </Col>
        )}
        {values.equipmentReturnName && (
          <Col>
            <Space>
              <Typography.Text style={styles.headerTitle}>Equipment Return Name: </Typography.Text>
              <Typography.Text>{values.equipmentReturnName}</Typography.Text>
            </Space>
          </Col>
        )}
        {values.referenceNumber && (
          <Col>
            <Space>
              <Typography.Text style={styles.headerTitle}>Reference Number: </Typography.Text>
              <Typography.Text>{values.referenceNumber}</Typography.Text>
            </Space>
          </Col>
        )}
        {values.equipmentReturnTypeId && foundType?.name && (
          <Col>
            <Space>
              <Typography.Text style={styles.headerTitle}>Equipment Return Type: </Typography.Text>
              <Typography.Text>{foundType.name}</Typography.Text>
            </Space>
          </Col>
        )}
        {values.requestedPickupDate && (
          <Col>
            <Space>
              <Typography.Text style={styles.headerTitle}>Pickup Date: </Typography.Text>
              <Typography.Text>{dayjs(values?.requestedPickupDate).format('MM-DD-YYYY')}</Typography.Text>
            </Space>
          </Col>
        )}
        {values.requestedPickupTime && (
          <Col>
            <Space>
              <Typography.Text style={styles.headerTitle}>Pickup Time: </Typography.Text>
              <Typography.Text>{dayjs(values?.requestedPickupTime, 'HH:mm').format('HH:mm')}</Typography.Text>
            </Space>
          </Col>
        )}
        {values.requestorName && (
          <Col>
            <Space>
              <Typography.Text style={styles.headerTitle}>Requestor Name: </Typography.Text>
              <Typography.Text>{values.requestorName}</Typography.Text>
            </Space>
          </Col>
        )}
      </Row>
      <Row align="middle" gutter={[0, 0]} style={{ marginBottom: 8 }}>
        {values.pickupAddress && (
          <Col span={24} xxl={24}>
            <Space>
              <Typography.Text style={styles.headerTitle}>Pickup Address: </Typography.Text>
              <Typography.Text ellipsis={{ tooltip: '' }} style={{ textTransform: 'capitalize' }}>
                {pickupAddress}
              </Typography.Text>
            </Space>
          </Col>
        )}
      </Row>
    </>
  );
};

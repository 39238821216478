import { ArrowLeftOutlined } from '@ant-design/icons';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Button, Dropdown, MenuProps, Modal, Row, Space, Typography, notification } from 'antd';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { EquipmentReturnPayload } from 'models/EquipmentReturn';
import { QueryErrorModel } from 'models/ErrorModel';
import { useDeleteEquipmentReturnMutation } from 'redux/services/barkley/barkleyApi';
import { setIsExit, setIsPost } from 'redux/slices/formSlice';
import { useAppSelector } from 'redux/store';
import '../../../styles/checkmarkStyle.less';
import '../../../styles/waveAnimation.css';

export enum CreateRequestMenu {
  RequestDetails = 'request-details',
  InstallLocation = 'install-location',
  Products = 'products',
  ShippingLocation = 'shipping-location',
  ShippingDetails = 'shipping-details',
  NotesSection = 'more-details',
  ReviewOrder = 'review-order'
}

export interface LineItemErrorObject {
  key: string;
  lineProductName: string;
  lineStatus?: boolean;
  lineMessage?: string;
  error?: FetchBaseQueryError;
}

export const ActionControls = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const nav = useNavigate();
  const { selectedDivisionId } = useAppSelector((state) => state.app);
  const { id } = useParams();
  const { isSubmitting, submitForm, values, errors } = useFormikContext<EquipmentReturnPayload>();
  const [deleteReturn] = useDeleteEquipmentReturnMutation();

  const dispatch = useDispatch();

  const isReturnShipment = values.equipmentReturnTypeName === 'ReturnShipment';

  const isPostInValid = Boolean(
    Object.entries(values).filter(([key, val]) => {
      if (isReturnShipment) {
        if (Array.isArray(val) && !val.length) {
          return true;
        }
        if (
          typeof val === 'string' &&
          !val &&
          key !== 'notes' &&
          key !== 'siteSpecificInstructions' &&
          key !== 'waybillNumber' &&
          key !== 'requestedPickupDate' &&
          key !== 'requestedPickupTime' &&
          key !== 'warehouseContactName' &&
          key !== 'warehouseContactPhone' &&
          key !== 'siteContactEmail' &&
          key !== 'siteContactName' &&
          key !== 'siteContactPhone' &&
          key !== 'divisionId'
        ) {
          return true;
        }
        if (key === 'pickupAddress' && !val) {
          return true;
        }

        return;
      }

      if (
        typeof val === 'string' &&
        !val &&
        key !== 'notes' &&
        key !== 'siteSpecificInstructions' &&
        key !== 'waybillNumber' &&
        key !== 'shipperBusinessName' &&
        key !== 'shipperContactName' &&
        key !== 'shipperContactPhone' &&
        key !== 'warehouseContactName' &&
        key !== 'warehouseContactPhone' &&
        key !== 'divisionId'
      ) {
        return true;
      }
      if (key === 'pickupAddress' && !val) {
        return true;
      }
      if (typeof val === 'number' && !val && key !== 'numberOfBoxes' && key !== 'qtyOfEquipment') {
        return true;
      }
      if (key === 'numberOfPallets' || key === 'totalWeight') {
        if (val === null) {
          return true;
        }
      }
    }).length
  );

  console.log(
    Object.entries(values).filter(([key, val]) => {
      if (isReturnShipment) {
        if (Array.isArray(val) && !val.length) {
          return true;
        }
        if (
          typeof val === 'string' &&
          !val &&
          key !== 'notes' &&
          key !== 'siteSpecificInstructions' &&
          key !== 'waybillNumber' &&
          key !== 'requestedPickupDate' &&
          key !== 'requestedPickupTime' &&
          key !== 'warehouseContactName' &&
          key !== 'warehouseContactPhone' &&
          key !== 'siteContactEmail' &&
          key !== 'siteContactName' &&
          key !== 'siteContactPhone'
        ) {
          return true;
        }
        if (key === 'pickupAddress' && !val) {
          return true;
        }

        return;
      }

      if (
        typeof val === 'string' &&
        !val &&
        key !== 'notes' &&
        key !== 'siteSpecificInstructions' &&
        key !== 'waybillNumber' &&
        key !== 'shipperBusinessName' &&
        key !== 'shipperContactName' &&
        key !== 'shipperContactPhone' &&
        key !== 'warehouseContactName' &&
        key !== 'warehouseContactPhone'
      ) {
        return true;
      }
      if (key === 'pickupAddress' && !val) {
        return true;
      }
      if (typeof val === 'number' && !val && key !== 'numberOfBoxes' && key !== 'qtyOfEquipment') {
        return true;
      }
      if (key === 'numberOfPallets' || key === 'totalWeight') {
        if (val === null) {
          return true;
        }
      }
    })
  );
  /* ******************** Functions ******************** */

  const handleGoBack = (): void => {
    nav('/');
  };

  const handleSaveAndExit = (): void => {
    dispatch(setIsExit(true));
    submitForm();
  };

  const handleDelete: React.MouseEventHandler<HTMLElement> = async (event): Promise<void> => {
    if (id) {
      Modal.error({
        width: 600,
        okText: 'Delete',
        okType: 'danger',
        onOk: async () => {
          try {
            event.stopPropagation();
            await deleteReturn({ divisionId: selectedDivisionId as string, equipmentReturnId: id, params: { wouldYouLikeToPlayAGame: false } });
            notification.success({ message: 'Success', description: 'Equipment Return deleted successfully.' });
            nav('/');
          } catch (e) {
            console.error(e, 'error');

            const error = e as QueryErrorModel;
            const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occured, the team has been notified.';

            notification.error({
              message: 'Error',
              description: errorMessage,
              className: 'custom-class',
              style: {
                width: 600
              },
              duration: 10
            });
          }
        },
        cancelText: 'Cancel',
        onCancel: () => {
          console.log('canceled popup');
        },
        closable: true,
        title: (
          <Typography.Title style={{ padding: 0, margin: 0 }} level={5}>
            Delete Request?
          </Typography.Title>
        ),
        content: <Typography.Text>Are you sure you want to delete this Equipment Return? It can still be recovered from the Recycle Bin.</Typography.Text>
      });
    }
  };

  const items: MenuProps['items'] = [
    {
      label: 'Save',
      key: '1',
      onClick: submitForm
    }
  ];

  /* ******************** Render ******************** */
  return (
    <Row align={'middle'} justify={'space-between'}>
      <Typography.Title style={{ margin: 0, padding: 0 }} level={4}>
        {id ? 'Edit Equipment Return' : 'Create Equipment Return'}
      </Typography.Title>
      <Space size="small">
        <Button onClick={handleGoBack} icon={<ArrowLeftOutlined />}>
          Back to Search
        </Button>
        {id && (
          <Button onClick={handleDelete} danger>
            Delete
          </Button>
        )}
        <Dropdown.Button type="primary" onClick={handleSaveAndExit} loading={isSubmitting} menu={{ items }}>
          Save &amp; Close
        </Dropdown.Button>
        <Button
          style={isPostInValid ? undefined : { background: 'rgb(78, 147, 122)', color: 'white' }}
          onClick={(): void => {
            dispatch(setIsPost(true));
            submitForm();
          }}
          disabled={isPostInValid}
          loading={isSubmitting}>
          Submit
        </Button>
      </Space>
    </Row>
  );
};

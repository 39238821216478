import { Radio } from 'antd';
import { useFormikContext } from 'formik';
import { EquipmentReturnPayload } from 'models/EquipmentReturn';

export const FormikRadioGroup = () => {
  const { setFieldValue, values } = useFormikContext<EquipmentReturnPayload>();

  return (
    <Radio.Group onChange={(event) => setFieldValue('isEquipmentValueOver100K', event.target.value)} value={values.isEquipmentValueOver100K}>
      <Radio value={true}>Yes</Radio>
      <Radio value={false}>No</Radio>
    </Radio.Group>
  );
};

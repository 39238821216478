import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker/generatePicker/interface';
import { Locale } from 'antd/es/locale';
import dayjs from 'dayjs';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { FC, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { resetRangeValues, setRangeValues } from 'redux/services/barkley/barkleyParams';

const styles: InlineStylesModel = {
  form: {
    position: 'relative'
  },
  borderAnimation: {
    transition: 'all 0.3s ease'
  },
  input: {
    width: '100%',
    minWidth: 125
  },
  labelUnfocused: {
    position: 'absolute',
    color: 'rgba(0,0,0,0.50)',
    top: 5,
    left: 10,
    zIndex: 9999,
    transition: 'all 0.3s ease',
    visibility: 'hidden'
  },
  labelFocused: {
    backgroundColor: 'white',
    position: 'absolute',
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: 9999,
    top: -15,
    left: 8,
    transition: 'all 0.3s ease'
  }
};

const { RangePicker } = DatePicker;

interface Props extends RangePickerProps<Locale> {
  width?: string | number;
}

export type RangeValue = [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;

export const FancyDateRangePicker: FC<Props> = ({ width }) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const inputWidth = width ? { ...styles.input, ...{ width } } : styles.input;

  const handleChange = (e: RangeValue): void => {
    if (e && e[0] && e[1]) {
      dispatch(setRangeValues(e));

      return;
    }
    dispatch(resetRangeValues());
  };

  return (
    <div style={{ ...styles.form, ...styles.borderAnimation }}>
      <RangePicker ref={inputRef} showTime={false} style={inputWidth} allowClear onChange={handleChange} placeholder={['Created Date', 'Created Date']} />
    </div>
  );
};

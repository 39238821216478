/* eslint-disable jsx-a11y/label-has-associated-control */
import { FancySelect } from 'components/ui/Inputs';
import { useDispatch } from 'react-redux';
import { setStatuses } from 'redux/services/barkley/barkleyParams';
import { useAppSelector } from 'redux/store';

export const StatusFilter = (): JSX.Element => {
  const dispatch = useDispatch();
  const { statuses } = useAppSelector((state) => state.barkleyParams);

  const options = ['Draft', 'Open', 'Closed', 'Cancelled'].map((status) => ({ label: status, value: status }));

  /* ****************** Functions ****************** */
  const handleStatusChange = (value: string[]): void => {
    dispatch(setStatuses(value.join(',')));
  };

  return (
    <FancySelect value={statuses ? statuses.split(',') : []} mode="multiple" onChange={handleStatusChange} options={options} placeholder="Return Status" onClear={() => dispatch(setStatuses())} />
  );
};

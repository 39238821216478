import { Col, Collapse, Row, Spin, Typography } from 'antd';
import { CollapseProps } from 'antd/lib';
import { ChangeAddressDrawer } from 'components/atoms/ChangeAddressDrawer';
import { WarehouseSelect } from 'components/atoms/WarehouseSelect';
import { Loader } from 'components/common/Loader';
import { ReturnDetailsCardOpen } from 'components/common/ReturnDetailsCardOpen';
import { FieldInputDatePicker } from 'components/Create/FormItems/FieldInputDatePicker.tsx';
import { FieldInputSelect } from 'components/Create/FormItems/FieldInputSelect';
import { FieldInputTextArea } from 'components/Create/FormItems/FieldInputTextArea';
import { SubscribeInput, SubscriberList } from 'components/Create/MoreDetails';
import { ReturnInfoFormOpen } from 'components/molecules/ReturnInfoFormOpen';
import { ReturnsAdminForm } from 'components/molecules/ReturnsAdminForm';
import { useFormikContext } from 'formik';
import Cookies from 'js-cookie';
import { OpenEquipmentReturnPayload } from 'models/EquipmentReturn';
import { AttachmentsCard } from 'pages/Create/AttachmentsCard';
import { BOLAttachmentsCard } from 'pages/Create/BOLAttachmentsCard';
import { FieldInput } from 'pages/Create/FormItems/FieldInput';
import { PackageInformationTable } from 'pages/Defective/PackageInformationTable';
import { useParams } from 'react-router-dom';
import { useGetEquipmentReturnQuery } from 'redux/services/barkley/barkleyApi';
import { useGetLegalEntitiesQuery } from 'redux/services/maggieCadabby/maggieCadabbyApi';
import { useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';
import { VendorQuotesSection } from './VendorQuotesSection';
const { Text } = Typography;

export const DispatcherDashboard = (): JSX.Element => {
  const { selectedDivisionId } = useAppSelector((state) => state.app);
  const { id } = useParams();
  const { data } = useGetEquipmentReturnQuery({ divisionId: selectedDivisionId as string, equipmentReturnId: id as string }, { skip: !id || !selectedDivisionId });
  const { values, setFieldValue } = useFormikContext<OpenEquipmentReturnPayload>();

  const { data: legalEntityData, isLoading } = useGetLegalEntitiesQuery({});
  const legalEntity = Cookies.get(`legalEntity`);
  const vendors = legalEntityData?.data.find((ent) => ent.dataAreaId === legalEntity || ent.dataAreaId === 'MDSI')?.freightVendors;

  const isReturnShipment = values.equipmentReturnTypeName === 'ReturnShipment';

  const statuses = ['New', 'Scheduling', 'Scheduled', 'BOL Sent', 'In Transit', 'Arrived at Dock', 'Equipment Received', 'Cancelled'];

  const items: CollapseProps['items'] = [
    {
      key: '1',
      style: { background: toRgba(colors.coral, 0.4), marginBottom: 16, borderRadius: 8 },
      label: (
        <Row>
          <Typography.Title level={4} style={{ margin: 0 }}>
            General Information
          </Typography.Title>
        </Row>
      ),
      children: (
        <Col span={24} style={{ marginTop: 16 }}>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <FieldInputSelect label="Fulfillment Status" fieldName="fulfillmentStatus" options={statuses.map((opt) => ({ label: opt, value: opt }))} />
            </Col>

            {!isReturnShipment && (
              <>
                <Col span={6}>
                  <FieldInput fieldName="siteContactName" label={'Site Contact Name'} />
                </Col>
                <Col span={6}>
                  <FieldInput fieldName="siteContactPhone" label={'Site Contact Phone'} />
                </Col>
                <Col span={6}>
                  <FieldInput fieldName="siteContactEmail" label={'Site Contact Email'} />
                </Col>
              </>
            )}
            {isReturnShipment && (
              <>
                <Col span={6}>
                  <FieldInput fieldName="shipperBusinessName" label={'Shipper Business Name'} />
                </Col>
                <Col span={6}>
                  <FieldInput fieldName="shipperContactName" label={'Shipper Contact Name'} />
                </Col>
                <Col span={6}>
                  <FieldInput fieldName="shipperContactPhone" label={'Shipper Contact Phone'} />
                </Col>
              </>
            )}
            {!isReturnShipment && (
              <>
                <Col span={6}>
                  <FieldInputDatePicker timeLabel="Requested Pickup Time" label="Requested Pickup Date" fieldNameDate="requestedPickupDate" fieldNameTime="requestedPickupTime" />
                </Col>
                <Col span={6}>
                  <FieldInputDatePicker timeLabel="Scheduled Pickup Time" label="Scheduled Pickup Date" fieldNameDate="scheduledPickupDate" fieldNameTime="scheduledPickupTime" />
                </Col>
              </>
            )}

            <Col span={6}>
              <WarehouseSelect isReturnShipment={isReturnShipment} />
            </Col>
            <Col span={6}>
              <Spin spinning={isLoading} indicator={<Loader small />}>
                <FieldInputSelect label="Freight Company" fieldName="freightCompany" options={vendors?.map((opt) => ({ label: opt.carrierId, value: opt.carrierId }))} />
              </Spin>
            </Col>
            {isReturnShipment && (
              <>
                <Col span={6}>
                  <FieldInput fieldName="warehouseContactName" label={'Warehouse Contact Name'} />
                </Col>
                <Col span={6}>
                  <FieldInput fieldName="warehouseContactPhone" label={'Warehouse Contact Phone'} />
                </Col>
              </>
            )}
            <Col span={6}>
              <FieldInput fieldName="mdSiProjectNumber" label="MDSi Project Number" />
            </Col>
            <Col span={6}>
              <FieldInput fieldName="disposalOrderNumber" label="Disposal Order Number" />
            </Col>
            {!isReturnShipment && (
              <Col span={6}>
                <FieldInput fieldName="waybillNumber" label="Waybill Number" />
              </Col>
            )}
            <Col span={isReturnShipment ? 12 : 6}>
              <Row gutter={[0, 10]}>
                <Col span={24}>
                  <SubscribeInput />
                </Col>
                <Col span={24}>
                  <SubscriberList />
                </Col>
              </Row>
            </Col>
          </Row>
          {/* </ColorCard> */}
        </Col>
      )
    },
    {
      key: '2',
      label: (
        <Typography.Title style={{ margin: 0 }} level={4}>
          {isReturnShipment ? 'Equipment Information' : 'Site Information'}
        </Typography.Title>
      ),
      style: { background: toRgba(colors.illuminatingEmerald, 0.5), marginBottom: 16, borderRadius: 8 },
      children: (
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col span={isReturnShipment ? 12 : 8}>
            <Row align={'middle'} justify={'space-between'}>
              <Typography.Title level={5} style={{ margin: 0 }}>
                Pickup Address:
              </Typography.Title>
              <ChangeAddressDrawer />
            </Row>
            <Typography.Text>
              {values?.pickupAddress?.street1} {<br />}
              {values?.pickupAddress?.city} {values?.pickupAddress?.subdivisionLocalCode}, {values?.pickupAddress?.countryAlpha2Code} {values?.pickupAddress?.postalCode}
            </Typography.Text>
            {!isReturnShipment && (
              <Row style={{ marginTop: 45 }}>
                <Col span={24} style={{ marginBottom: 5 }}>
                  <Typography.Title level={5} style={{ margin: 0 }}>
                    Returns Administration:
                  </Typography.Title>
                </Col>
                <ReturnsAdminForm equipmentReturnTypeId={values.equipmentReturnTypeId} />
              </Row>
            )}
          </Col>
          {!isReturnShipment ? (
            <>
              <Col span={8}>
                <ReturnDetailsCardOpen isOpen />
              </Col>
              <Col span={8}>
                <ReturnInfoFormOpen />
              </Col>
            </>
          ) : (
            <>
              {/* <Col span={6}>
                <ReturnsAdminForm />
              </Col> */}
              <Col span={12}>
                <PackageInformationTable />
              </Col>
            </>
          )}
        </Row>
      )
    },
    {
      key: '3',
      style: { background: toRgba(colors.metallicGold, 0.4), marginBottom: 16, borderRadius: 8 },
      label: (
        <Row>
          <Typography.Title level={4} style={{ margin: 0 }}>
            Attachments
          </Typography.Title>
        </Row>
      ),
      children: (
        <Row gutter={[16, 0]} style={{ marginTop: 16 }}>
          <Col span={12}>
            <AttachmentsCard />
          </Col>
          <Col span={12}>
            <BOLAttachmentsCard />
          </Col>
        </Row>
      )
    },
    {
      key: '4',
      style: { background: toRgba(colors.robinEggBlue, 0.4), marginBottom: 16, borderRadius: 8 },
      label: (
        <Row>
          <Typography.Title level={4} style={{ margin: 0 }}>
            Notes
          </Typography.Title>
        </Row>
      ),
      children: (
        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col span={8}>
            <FieldInputTextArea fieldName="notes" label="Requestor Notes" />
          </Col>
          <Col span={8}>
            <FieldInputTextArea fieldName="internalNotes" label="Internal Notes" />
          </Col>
          <Col span={8}>
            <FieldInputTextArea fieldName="notificationNotes" label="Notification Notes" />
          </Col>
        </Row>
      )
    },
    {
      key: '5',
      style: { background: toRgba(colors.electricPurple, 0.4), marginBottom: 16, borderRadius: 8 },
      label: (
        <Row>
          <Typography.Title level={4} style={{ margin: 0 }}>
            Vendor Quotes
          </Typography.Title>
        </Row>
      ),
      children: <VendorQuotesSection />
    }
  ];

  const filteredItems = items.filter((item) => {
    if (data?.equipmentReturnType?.name === 'Return Shipment') {
      return item.key !== '5';
    }

    return true;
  });

  return <Collapse defaultActiveKey={['1']} items={filteredItems} bordered={false} />;
};

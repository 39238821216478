import { Card, Col, Row, Spin, Typography } from 'antd';
import { FieldInput } from 'components/Create/FormItems/FieldInput';
import { FieldInputSelect } from 'components/Create/FormItems/FieldInputSelect';
import { Loader } from 'components/common/Loader';

import { FieldInputDatePicker } from 'components/Create/FormItems/FieldInputDatePicker.tsx';
import { ContactInfoSwitch } from 'components/Create/ShippingDetails';
import { useFormikContext } from 'formik';
import { EquipmentReturnPayload } from 'models/EquipmentReturn';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useGetDivisionsQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { useGetEquipmentReturnConfigurationTypesQuery } from 'redux/services/maurice/sparingRequestConfigTypes';
import { useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';
const { Title } = Typography;

export const ReferenceInfo: React.FC = () => {
  const { acuityContext, isInternalUser, selectedDivisionId } = useAppSelector((state) => state.app);
  const { values } = useFormikContext<EquipmentReturnPayload>();

  const { pathname } = useLocation();

  const isCreate = pathname.includes('create');

  const divisionId = isInternalUser ? selectedDivisionId : acuityContext?.selectedCustomer.id;

  const isInternalCreate = isInternalUser && isCreate;

  const divId = isInternalCreate ? values.divisionId : divisionId;

  const { data: typesData, isLoading: isTypesLoading, isFetching: isTypesFetching } = useGetEquipmentReturnConfigurationTypesQuery({ divisionId: divId as string, params: {} }, { skip: !divId });
  const { data, isLoading } = useGetDivisionsQuery();

  return (
    <Row gutter={[12, 12]} style={{ height: '100%' }}>
      <Col style={{ minHeight: '100%' }} span={12}>
        <Card
          styles={{ header: { backgroundColor: toRgba(colors.royalBlueLight, 0.4) } }}
          title={
            <Title level={4} style={{ fontWeight: 'normal', padding: 0, margin: 0 }}>
              Reference Information
            </Title>
          }
          style={{ height: '100%', minHeight: '100%' }}>
          <Row gutter={[10, 10]}>
            <Col span={12}>
              <FieldInput fieldName="equipmentReturnName" label="Equipment Return Name" />
            </Col>
            <Col span={12}>
              <FieldInput fieldName="referenceNumber" label="Reference Number" />
            </Col>

            <Col span={12}>
              <Row gutter={[8, 0]}>
                {isInternalCreate && (
                  <Col span={12}>
                    <Spin spinning={isLoading} indicator={<Loader small />}>
                      <FieldInputSelect label="Customer" fieldName="divisionId" options={data?.data.map((type) => ({ label: `${type.erpId} - ${type.name}`, value: type.id }))} />
                    </Spin>
                  </Col>
                )}
                <Col span={isInternalCreate ? 12 : 24}>
                  <Spin spinning={isTypesLoading || isTypesFetching} indicator={<Loader small />}>
                    <FieldInputSelect
                      disabled={isInternalCreate && !values.divisionId}
                      label="Return Type"
                      fieldName="equipmentReturnTypeId"
                      options={typesData?.data.map((type) => ({ label: type.name, value: type.id }))}
                    />
                  </Spin>
                </Col>
              </Row>
            </Col>
            {values.equipmentReturnTypeName !== 'ReturnShipment' && (
              <Col span={12}>
                <FieldInputDatePicker timeLabel="Requested Pickup Time" label="Requested Pickup Date" fieldNameDate="requestedPickupDate" fieldNameTime="requestedPickupTime" />
              </Col>
            )}
          </Row>
        </Card>
      </Col>
      <Col style={{ minHeight: '100%' }} span={12}>
        <Card
          extra={<ContactInfoSwitch isRequestor />}
          styles={{ header: { backgroundColor: toRgba(colors.illuminatingEmerald, 0.4) }, body: { minHeight: '100%' } }}
          title={
            <Title level={4} style={{ fontWeight: 'normal', padding: 0, margin: 0 }}>
              Requestor Information
            </Title>
          }
          style={{ height: '100%', minHeight: '100%' }}>
          <Spin style={{ width: '100%', height: '100%' }} indicator={<Loader medium />} spinning={isTypesLoading}>
            <Row gutter={[10, 10]}>
              <Col span={12}>
                <FieldInput label="Requestor Name" fieldName="requestorName" />
              </Col>
              <Col span={12}>
                <FieldInput label="Requestor Phone" fieldName="requestorPhone" />
              </Col>
              <Col span={24}>
                <FieldInput label="Requestor Email" fieldName="requestorEmail" />
              </Col>
            </Row>
          </Spin>
        </Card>
      </Col>
    </Row>
  );
};

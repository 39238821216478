import { Col, Row, Spin } from 'antd';
import { FieldInputSwitchOpen } from 'components/atoms/FieldInputSwitchOpen';
import { FieldInput } from 'pages/Create/FormItems/FieldInput';
import { FieldInputSelect } from 'pages/Create/FormItems/FieldInputSelect';
import { FC } from 'react';
import { useGetEquipmentReturnConfigurationTypeQuery } from 'redux/services/maurice/sparingRequestConfigTypes';
import { useAppSelector } from 'redux/store';

type Props = {
  equipmentReturnTypeId: string;
};

export const ReturnsAdminForm: FC<Props> = ({ equipmentReturnTypeId }) => {
  const { selectedDivisionId } = useAppSelector((state) => state.app);

  const { data, isLoading } = useGetEquipmentReturnConfigurationTypeQuery(
    { divisionId: selectedDivisionId as string, params: {}, typeId: equipmentReturnTypeId },
    { skip: !selectedDivisionId || !equipmentReturnTypeId }
  );
  const options = data?.requestSource?.map((opt) => ({ label: opt, value: opt }));

  return (
    <Row gutter={[16, 16]} style={{ width: '100%' }}>
      <Col span={12}>
        <FieldInputSelect options={['Critical', 'High', 'Medium', 'Low'].map((opt) => ({ label: opt, value: opt }))} fieldName="requestPriority" label="Priority" />
      </Col>
      <Col span={12}>
        <Spin spinning={isLoading}>
          <FieldInputSelect options={options} fieldName="requestSource" label="Request Source" />
        </Spin>
      </Col>
      <Col span={12}>
        <FieldInput fieldName="invoiceNumber" label="Invoice Number" />
      </Col>
      <Col span={12}>
        <Row>
          <FieldInputSwitchOpen fieldName="isInvoiced" label="Is Invoiced" />
        </Row>
      </Col>
    </Row>
  );
};

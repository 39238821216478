import { Space, Switch } from 'antd';
import { SwitchProps } from 'antd/lib';
import { FormLabel } from 'components/Create/FormItems/FormLabel';
import { Field, FieldProps } from 'formik';
import { rest } from 'lodash';
import { FC } from 'react';

interface Props extends SwitchProps {
  fieldName: string;
  placeholder?: string;
  type?: string;
  label: string;
  isAwarded?: boolean;
}

export const FieldInputSwitchOpen: FC<Props> = ({ fieldName, placeholder, label, isAwarded }) => {
  return (
    <Field name={fieldName} placeholder={placeholder}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => {
        return (
          <Space direction="vertical" align={isAwarded ? 'end' : 'start'}>
            <FormLabel label={label} />
            <Switch style={{ borderColor: meta.error && meta.touched ? '#dc3545' : undefined }} {...field} onChange={(e) => setFieldValue(fieldName, e)} {...rest} />
            <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
          </Space>
        );
      }}
    </Field>
  );
};

import { List, Row, Space } from 'antd';
import { ViewWaybillsButton } from 'components/atoms/ViewWaybillsButton';
import { WaitToSearch } from 'components/common/waitToSearch/WaitToSearch';
import { EquipmentReturn } from 'models/EquipmentReturn';
import { ColorCard } from 'pages/Create/ColorCard';
import { colors, toRgba } from 'styles/colors';
import packageLottie from '../../assets/package.json';

interface Props {
  packages?: EquipmentReturn['packages'];
  isPosted?: boolean;
}

export const PackageInformationCustomer: React.FC<Props> = ({ packages, isPosted = false }) => {
  const packagesWithWaybill = packages?.filter((item) => !!item?.waybill?.length);

  return (
    <ColorCard
      title="Packages"
      color={isPosted ? toRgba(colors.orangeWeb, 0.4) : toRgba(colors.robinEggBlue, 0.4)}
      extra={<Space>{!!packagesWithWaybill?.length && <ViewWaybillsButton packagesWithWaybill={packagesWithWaybill} />}</Space>}>
      <List
        size="small"
        grid={{ column: 2 }}
        dataSource={packages}
        locale={{ emptyText: <WaitToSearch animationData={packageLottie} message="Please add packages to continue" /> }}
        style={{ padding: 2 }}
        renderItem={(item): JSX.Element => (
          <List.Item key={item.packageWeight} style={{ borderRadius: 10, border: '1px solid lightgray', padding: 16, marginRight: 16 }}>
            <Row justify={'space-between'}>
              <List.Item.Meta
                avatar={
                  <Row align={'middle'} justify={'center'} style={{ borderRadius: 100, background: 'lightgray', width: 30, height: 30 }}>
                    {item.packageSize}
                  </Row>
                }
                title={`Packages: ${item.numberOfPackages}`}
                description={`${item.packageWeight.toFixed(2)} lbs`}
              />
              <Space>
                {/* <EditPackageButton item={item} /> */}
                {/* <LottieListButton onClick={(): void => handleDelete(item.key)} backgroundColor="#FFE1E1" title="Delete" lottieFile={deleteLottie} /> */}
                {/* <LottieListButton backgroundColor="#D5FFDF" title="Generate Waybill" lottieFile={generateLottie} /> */}
              </Space>
            </Row>
          </List.Item>
        )}
      />
    </ColorCard>
  );
};

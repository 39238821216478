import { PrinterOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { OpenEquipmentReturn } from 'models/EquipmentReturn';
import { QueryErrorModel } from 'models/ErrorModel';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useGenerateWaybillMutation } from 'redux/services/barkley/barkleyApi';
import { useAppSelector } from 'redux/store';
import { colors } from 'styles/colors';

type Props = {
  openData: OpenEquipmentReturn;
};

export const GenerateWaybillButtonCustomer: FC<Props> = ({ openData }) => {
  const { selectedDivisionId } = useAppSelector((state) => state.app);
  const { id } = useParams();

  const [createWaybill] = useGenerateWaybillMutation({ fixedCacheKey: 'waybill' });

  const handleGenerateWaybill = async (): Promise<void> => {
    const digits = openData.shipperContactPhone?.replace(/\D/g, '');

    if (digits?.length !== 10) {
      notification.error({
        message: 'Error',
        description: 'Shipper Contact Phone must be 10 digits',
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 5
      });

      return;
    }

    if (!selectedDivisionId || !id) {
      notification.error({
        message: 'Error',
        description: 'An error occurred. Please try again later.',
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 5
      });

      return;
    }

    try {
      await createWaybill({ divisionId: selectedDivisionId, equipmentReturnId: id as string, payload: {} }).unwrap();
      notification.success({
        message: 'Success',
        description: 'Waybills have been generated successfully',
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 5
      });
    } catch (e) {
      const error = e as QueryErrorModel;

      console.error(error);
      const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occurred, the team has been notified.';

      notification.error({
        message: 'Error',
        description: errorMessage,
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 5
      });
    }

    // await submitForm();
  };

  return (
    <>
      <Button style={{ border: `1px solid ${colors.success[600]}`, color: colors.success[600] }} onClick={handleGenerateWaybill} icon={<PrinterOutlined />}>
        Generate Waybills
      </Button>
    </>
  );
};

import { Col, Row, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useFormikContext } from 'formik';
import { EquipmentReturnPayload } from 'models/EquipmentReturn';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { SparingRequestPayload } from 'models/SparingRequests';
import { ColorCard } from 'pages/Create/ColorCard';
import { useNavigate, useParams } from 'react-router-dom';
import { colors, toRgba } from 'styles/colors';
const { Title } = Typography;

const styles: InlineStylesModel = {
  header: {
    backgroundColor: toRgba(colors.royalBlueLight, 0.4)
  },
  table: {
    width: '100%'
  }
};

export const ProductsContent = (): JSX.Element => {
  const { values } = useFormikContext<EquipmentReturnPayload>();
  const { id } = useParams();
  const nav = useNavigate();

  const columns: ColumnsType<SparingRequestPayload['packageLineItems'][number]> = [
    {
      title: 'Package Size',
      dataIndex: 'packageSize',
      key: 'packageSize'
    },
    {
      title: 'packageWeight',
      dataIndex: 'packageWeight',
      key: 'packageWeight'
    },
    {
      title: '# of Packages',
      dataIndex: 'numberOfPackages',
      key: 'numberOfPackages'
    }
  ];

  return (
    <ColorCard color={toRgba(colors.royalBlueLight, 0.4)} title={'Package Details'}>
      <Row>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Quantity of Equipment
          </Typography.Title>
          <Typography.Paragraph>{values.qtyOfEquipment ?? 0}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Total Weight (lbs)
          </Typography.Title>
          <Typography.Paragraph>{values.totalWeight ?? 0}</Typography.Paragraph>
        </Col>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Number of Pallets
          </Typography.Title>
          <Typography.Paragraph>{values.numberOfPallets ?? 0}</Typography.Paragraph>
        </Col>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Number of Boxes
          </Typography.Title>
          <Typography.Paragraph>{values.numberOfBoxes ?? 0}</Typography.Paragraph>
        </Col>
      </Row>
    </ColorCard>
  );
};

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Tooltip } from 'antd';
import { QueryErrorModel } from 'models/ErrorModel';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useDeleteVendorQuoteMutation } from 'redux/services/barkley/barkleyApi';
import { useAppSelector } from 'redux/store';

type Props = {
  quoteId: string;
};

export const DeleteVendorQuoteButton: FC<Props> = ({ quoteId }) => {
  const [deleteVendorQuote] = useDeleteVendorQuoteMutation();
  const { selectedDivisionId } = useAppSelector((state) => state.app);
  const { id } = useParams();

  const handleDelete = (): void => {
    Modal.confirm({
      title: 'Are you sure you want to delete this quote?',
      content: 'This action cannot be undone.',
      onOk: async () => {
        if (!selectedDivisionId || !id || !quoteId) {
          notification.error({ message: 'Error', description: 'Division ID or Equipment Return ID is missing' });

          return;
        }
        try {
          await deleteVendorQuote({ divisionId: selectedDivisionId, equipmentReturnId: id, quoteId: quoteId }).unwrap();
          notification.success({ message: 'Success', description: 'Vendor Quote deleted successfully' });
        } catch (e) {
          console.error(e, 'error');

          const error = e as QueryErrorModel;

          const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occurred, the team has been notified.';

          notification.error({
            message: 'Error',
            description: errorMessage,
            className: 'custom-class',
            style: {
              width: 600
            },
            duration: 10
          });
        }
      }
    });
  };

  return (
    <Tooltip title="Delete">
      <Button style={{ border: '1px solid red' }} size="small" icon={<DeleteOutlined style={{ color: 'red' }} />} onClick={handleDelete} />
    </Tooltip>
  );
};

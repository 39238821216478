import { Col, DatePicker, InputProps, Row, TimePicker } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Field, FieldProps } from 'formik';
import { FC } from 'react';
import { FormLabel } from './FormLabel';

dayjs.extend(utc);

interface Props extends InputProps {
  fieldNameDate: string;
  fieldNameTime: string;
  placeholder?: string;
  type?: string;
  label: string;
  timeLabel: string;
}

export const FieldInputDatePicker: FC<Props> = ({ fieldNameDate, fieldNameTime, placeholder, label, timeLabel }) => {
  return (
    <Row gutter={[5, 5]}>
      <Col span={16}>
        <Field name={fieldNameDate} placeholder={placeholder}>
          {({
            field, // { name, value, onChange, onBlur }
            form: { handleBlur, setFieldValue, handleChange }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta
          }: FieldProps): JSX.Element => {
            return (
              <div>
                <FormLabel label={label} />
                <DatePicker
                  format={'MM/DD/YYYY'}
                  value={field.value ? dayjs(field.value) : undefined}
                  onChange={(date, dateString) => {
                    console.log('dateString', dateString);
                    setFieldValue(fieldNameDate, dateString);
                  }}
                  onBlur={() => handleBlur(fieldNameDate)}
                  style={{ width: '100%' }}
                />

                <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
              </div>
            );
          }}
        </Field>
      </Col>
      <Col span={8}>
        <Field name={fieldNameTime} placeholder={placeholder}>
          {({
            field, // { name, value, onChange, onBlur }
            form: { handleBlur, setFieldValue, handleChange }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta
          }: FieldProps): JSX.Element => {
            return (
              <div>
                <FormLabel label={'Time'} />
                <TimePicker
                  format={'HH:mm'}
                  minuteStep={15}
                  // needConfirm={false}
                  value={field.value ? dayjs(field.value, 'HH:mm') : undefined}
                  onChange={(date, dateString) => {
                    setFieldValue(fieldNameTime, dateString);
                  }}
                  onBlur={() => handleBlur(fieldNameTime)}
                  style={{ width: '100%' }}
                />

                <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
              </div>
            );
          }}
        </Field>
      </Col>
    </Row>
  );
};

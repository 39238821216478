import { SendOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { QueryErrorModel } from 'models/ErrorModel';
import { useParams } from 'react-router-dom';
import { useResendNotificationsMutation } from 'redux/services/barkley/barkleyApi';
import { useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';

export const ResendNotificationsButton = (): JSX.Element => {
  const { selectedDivisionId } = useAppSelector((state) => state.app);
  const { id } = useParams();
  const [sendNotifications, { isLoading }] = useResendNotificationsMutation();

  const handleClick = async (): Promise<void> => {
    if (!selectedDivisionId || !id) {
      notification.error({ message: 'Error', description: 'Division ID or Equipment Return ID is missing' });

      return;
    }

    try {
      await sendNotifications({ divisionId: selectedDivisionId, equipmentReturnId: id }).unwrap();
      notification.success({ message: 'Success', description: 'Notifications sent successfully' });
    } catch (e) {
      console.error(e, 'error');

      const error = e as QueryErrorModel;

      const errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error.data ? (error?.data as string) : 'An error occurred, the team has been notified.';

      notification.error({
        message: 'Error',
        description: errorMessage,
        className: 'custom-class',
        style: {
          width: 600
        },
        duration: 10
      });
    }
  };

  return (
    <Button style={{ border: `1px solid ${toRgba(colors.raisinBlackDark, 1)}`, color: toRgba(colors.raisinBlackDark, 1) }} loading={isLoading} icon={<SendOutlined />} onClick={handleClick}>
      Resend Notifications
    </Button>
  );
};
